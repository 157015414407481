import { API_BASE_PATH_PORTAL } from '../../../api/auth'
import { axiosRequest } from '../../../utils/axiosRequest'

function getPartnerPortalAppBasePath() {
  return window.location.origin
}

export async function getYotpoAuthorizeUrl() {
  const partnerPortalAppBasePath = getPartnerPortalAppBasePath()
  const response = await axiosRequest('post', `${API_BASE_PATH_PORTAL}/yotpo/get-authorize-url`, {
    redirectUrl: `${partnerPortalAppBasePath}/n/yotpo/authorize-callback`,
  })
  return response.data
}
