import {
  ChevronLeftRounded as PreviousPage,
  ChevronRightRounded as NextPage,
  ExpandMoreRounded as Arrow,
  FirstPageRounded as FirstPage,
  LastPageRounded as LastPage,
  SwapVertRounded as Sort,
} from '@material-ui/icons'
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  HeaderGroup,
  Row,
  RowSelectionState,
  Table,
  TableMeta,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table'
import cx from 'classnames'
import { debounce, sum, take } from 'lodash'
import { Fragment, useCallback, useEffect, useLayoutEffect, useRef } from 'react'

import { BeamButton } from '../BeamButton'
import { BeamCheckbox } from '../BeamCheckbox/BeamCheckbox'
import { BeamDropdown } from '../BeamDropdown'
import { BeamDropdownOnChangeEvent } from '../BeamDropdown/BeamDropdown.types'
import { BeamDSProps } from '../interface'
import $$ from './beam-table.module.css'

const DEFAULT_HEADER_HEIGHT = 50 // px, row padding + line height (see CSS)

const beamTablePageSizeDropdown = () => {
  return [5, 10, 20, 30, 40, 50].map(entry => {
    return { label: `Show ${entry.toString()}`, value: entry.toString() }
  })
}

const BeamTablePagination = ({ table }: { table: Table<any> }) => {
  return (
    <div className={$$.pagination}>
      <BeamButton
        className={$$.paginationButton}
        label={<FirstPage fontSize={'small'} />}
        variant="input"
        onClick={() => table.setPageIndex(0)}
        disabled={!table.getCanPreviousPage()}
      />
      <BeamButton
        className={$$.paginationButton}
        label={<PreviousPage fontSize={'small'} />}
        variant="input"
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      />
      <span className={cx($$.paginationPageIndicator)}>
        Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
      </span>
      <BeamButton
        className={$$.paginationButton}
        label={<NextPage fontSize={'small'} />}
        variant="input"
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      />
      <BeamButton
        className={$$.paginationButton}
        label={<LastPage fontSize={'small'} />}
        variant="input"
        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
        disabled={!table.getCanNextPage()}
      />

      <BeamDropdown
        options={(() => beamTablePageSizeDropdown())()}
        value={table.getState().pagination.pageSize.toString()}
        onChange={(e: BeamDropdownOnChangeEvent) => {
          table.setPageSize(Number(e.target.value))
        }}
      />
    </div>
  )
}

interface BeamTableProps<TData> extends BeamDSProps {
  /**
   * Column Header Names
   */
  columns: TableOptions<TData>['columns']
  /**
   * Array of data that goes into the body of the table
   */
  data: TData[]
  /**
   * A custom message for when there's no data in the table
   */
  noDataMessage?: string
  /**
   * Type of table you want to present
   */
  variant?: 'Basic' | 'Barebones'
  /**
   * Enables sorting on columns that are sortable
   */
  enableSorting?: boolean
  /**
   * Enable Pagination Widget below table
   */
  enablePagination?: boolean
  /**
   * Number of rows to show in the table at a time
   */
  numberOfRowsToDisplay?: number
  /**
   * Component for expandable rows.  Using this props implies that the row can be expanded.
   */
  expandedRowComponent?: (props: { row: Row<TData> }) => React.ReactNode
  /** Enable fixed (sticky) header row (requires numberOfRowsToDisplay) */
  fixedFirstRow?: boolean
  /** Enable fixed (sticky) first column */
  fixedFirstCol?: boolean
  /**
   * Optional table meta for additional functionality. For example: an update function for editing cells.
   * https://tanstack.com/table/v8/docs/api/core/table#meta
   */
  meta?: TableMeta<any>
  /**
   * Forces all headers to be left-aligned instead of having the last column be right-aligned
   */
  persistHeaderAlignment?: boolean
  /**
   * Optional classnames for more granular control over table styles.
   * Use these if you're trying to use css modules from a wrapper component.
   */
  customClassnames?: {
    thead?: string
  }
  /**
   * Display the table with shorter rows
   */
  condensed?: boolean
  /**
   * Properties necessary to enable table row selection
   */
  rowSelectionOptions?: {
    /**
     * Allows row selection on the table.
     */
    enableRowSelection: boolean | ((row: Row<any>) => boolean)
    /**
     * Callback triggered when the table selected rows change.
     */
    onRowSelectionChange: (newRowSelectionState: RowSelectionState) => void
    /**
     * Map of currently selected rows, where the keys are the index and the value is boolean. De-selected rows are removed from the map.
     */
    selectedRows: RowSelectionState
  }
}

/**
 * Table component
 */
export const BeamTable = ({
  columns,
  data,
  noDataMessage = 'No data available',
  variant = 'Basic',
  enableSorting = false,
  enablePagination = false,
  numberOfRowsToDisplay = 10,
  expandedRowComponent,
  fixedFirstRow = false,
  fixedFirstCol = false,
  meta,
  persistHeaderAlignment = false,
  condensed = false,
  rowSelectionOptions,
  ...props
}: BeamTableProps<any>) => {
  if (expandedRowComponent) {
    columns = [
      ...columns,
      {
        id: 'expander',
        header: () => 'Details',
        cell: ({ row }: { row: any }) => {
          return row.getCanExpand() ? (
            <button
              {...{
                onClick: row.getToggleExpandedHandler(),
                style: { cursor: 'pointer' },
              }}
              className={row.getIsExpanded() ? $$.expanded : $$.collapsed}>
              {row.getIsExpanded() ? <Arrow fontSize={'small'} /> : <Arrow fontSize={'small'} />}
            </button>
          ) : (
            ''
          )
        },
      },
    ]
  }

  const tableOptions: TableOptions<any> = {
    data,
    columns,
    getRowCanExpand: () => !!expandedRowComponent,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: enablePagination ? getPaginationRowModel() : undefined,
    meta,
  }

  if (rowSelectionOptions) {
    columns.splice(0, 0, {
      id: 'select',
      header: ({ table }) => {
        return (
          <BeamCheckbox
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        )
      },
      cell: ({ row }) => {
        return (
          <BeamCheckbox
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
          />
        )
      },
    })

    const { enableRowSelection, onRowSelectionChange, selectedRows } = rowSelectionOptions
    tableOptions.enableRowSelection = enableRowSelection
    tableOptions.state = {
      rowSelection: selectedRows,
    }

    tableOptions.onRowSelectionChange = updaterOrValue => {
      // explicit type check to prevent TS error
      if (typeof updaterOrValue === 'function') {
        onRowSelectionChange(updaterOrValue(selectedRows as RowSelectionState))
      } else {
        onRowSelectionChange(updaterOrValue)
      }
    }
  }

  const table = useReactTable(tableOptions)

  useEffect(() => {
    table.setPageSize(numberOfRowsToDisplay)
  }, [numberOfRowsToDisplay, table])

  const rows = table.getRowModel().rows

  const footerRows = table.getFooterGroups()

  const tableHeaderRef = useRef<HTMLTableSectionElement>(null)
  const tableBodyRef = useRef<HTMLTableSectionElement>(null)
  const tableFooterRef = useRef<HTMLTableSectionElement>(null)
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const headerOverlayRef = useRef<HTMLDivElement>(null)

  const setTableContainerHeightIfNeeded = useCallback(
    function setTableContainerHeightIfNeeded() {
      if (!fixedFirstRow) return
      const tableHeaderEl = tableHeaderRef.current
      const tableBodyEl = tableBodyRef.current
      const tableFooterEl = tableFooterRef.current
      const tableContainerEl = tableContainerRef.current
      const headerOverlayEl = headerOverlayRef.current
      if (!tableContainerEl || !tableBodyEl) return

      const numRows = Math.min(numberOfRowsToDisplay, rows.length)
      const initialShownRowEls = take([...tableBodyEl.querySelectorAll('tr')], numRows)

      const heights = {
        tableHeader: tableHeaderEl?.clientHeight ?? DEFAULT_HEADER_HEIGHT,
        tableBody: sum(initialShownRowEls.map(rowEl => rowEl.clientHeight)),
        tableFooter: tableFooterEl?.clientHeight ?? 0,
        scrollBar: tableContainerEl.offsetHeight - tableContainerEl.clientHeight,
      }
      const widths = {
        scrollbar: tableContainerEl.offsetWidth - tableContainerEl.clientWidth,
      }
      tableContainerEl.style.height =
        numRows > 0
          ? heights.tableBody + heights.tableHeader + heights.tableFooter + heights.scrollBar + 'px'
          : 'auto'
      if (headerOverlayEl) {
        headerOverlayEl.style.right = widths.scrollbar + 'px'
        headerOverlayEl.style.height = heights.tableHeader + 'px'
      }
    },
    [numberOfRowsToDisplay, rows.length, fixedFirstRow]
  )

  useLayoutEffect(setTableContainerHeightIfNeeded)

  useEffect(() => {
    const resizeHandler = debounce(setTableContainerHeightIfNeeded, 32)
    window.addEventListener('resize', resizeHandler)
    return () => window.removeEventListener('resize', resizeHandler)
  }, [setTableContainerHeightIfNeeded])

  return (
    <div
      {...props}
      className={cx(props.className, $$[`tableContainer${variant}`], {
        [$$.persistHeaderAlignment]: persistHeaderAlignment,
      })}>
      {fixedFirstCol && <div className={cx($$.headerOverflowOverlay)} ref={headerOverlayRef} />}
      <div
        className={cx({ [$$.fixedFirstRow]: fixedFirstRow, [$$.fixedFirstCol]: fixedFirstCol })}
        ref={tableContainerRef}>
        <table className={$$.table}>
          <thead
            className={cx($$.thead, 'beam--table--thead', {
              [props.customClassnames?.thead || '']: !!props.customClassnames?.thead,
            })}
            ref={tableHeaderRef}>
            {table.getHeaderGroups().map((headerGroup: HeaderGroup<any>) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  // individual columns can have `enableSorting` properties. Prevents non-sortable columns from having the sort button.
                  const canSort = enableSorting && header.column.getCanSort()

                  return (
                    <th
                      key={header.id}
                      className={cx({ [$$.sortingHeader]: canSort }, 'beam--table--thead--th')}
                      onClick={canSort ? header.column.getToggleSortingHandler() : () => null}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                      {canSort && (
                        <div className={$$.sort}>
                          {{
                            asc: <Sort fontSize={'small'} />,
                            desc: <Sort fontSize={'small'} />,
                          }[header.column.getIsSorted() as string] ?? (
                            <Sort fontSize={'small'} className={$$.sortIconDeselected} />
                          )}
                        </div>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody className={cx($$.tbody, 'beam--table--tbody')} ref={tableBodyRef}>
            {rows.length === 0 && (
              <tr>
                <td className={$$.noData} colSpan={table.getHeaderGroups()[0]['headers'].length}>
                  {noDataMessage}
                </td>
              </tr>
            )}
            {rows.length > 0 &&
              rows.map((row: any) => (
                <Fragment key={row.id}>
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell: any) => (
                      <td
                        key={cell.id}
                        className={cx('beam--table--tbody--td', condensed ? $$.condensed : '')}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                  {row.getIsExpanded() && !!expandedRowComponent && (
                    <tr className="expandedRow">
                      <td colSpan={row.getVisibleCells().length}>
                        {expandedRowComponent({ row })}
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
          </tbody>
          {footerRows.length > 0 && (
            <tfoot className={cx($$.tfoot)} ref={tableFooterRef}>
              {footerRows.map(footerGroup => (
                <tr key={footerGroup.id}>
                  {footerGroup.headers.some(header => header.column.columnDef.footer) &&
                    footerGroup.headers.map(header => (
                      <th key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.footer, header.getContext())}
                      </th>
                    ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </div>
      {enablePagination && <BeamTablePagination table={table} />}
    </div>
  )
}
