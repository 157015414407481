import { useEffect } from 'react'

import { getYotpoAuthorizeUrl } from './YotpoOauthLandingPage.api'

export const YotpoOauthLandingPage = () => {
  // on mount, call api handler
  // when response received, redirect to provided URL
  const getUrlAndRedirect = async () => {
    const data = await getYotpoAuthorizeUrl()
    // window.location supports redirects to external urls unlike react-router
    window.location.replace(data.url)
  }

  useEffect(() => {
    getUrlAndRedirect()
  }, [])

  return (
    <div className="pt-6">
      <h2>Redirecting you to Yotpo...</h2>
    </div>
  )
}
