import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react'

export type TimeframeViewType = 'oneMonth' | 'sixMonth'

interface TimeframeToggleType {
  timeframeView: TimeframeViewType
  setTimeframeView: Dispatch<SetStateAction<TimeframeViewType>>
}

export const TimeframeToggleContext = createContext<TimeframeToggleType>({
  timeframeView: 'oneMonth',
  setTimeframeView: () => null,
})

export function TimeframeToggleProvider({ children }: { children: ReactNode }) {
  const [timeframeView, setTimeframeView] = useState<TimeframeViewType>('oneMonth')
  return (
    <TimeframeToggleContext.Provider
      value={{
        timeframeView,
        setTimeframeView,
      }}>
      {children}
    </TimeframeToggleContext.Provider>
  )
}
