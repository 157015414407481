import { useContext } from 'react'

import { BeamButton } from '../../../stories/BeamButton'
import { BeamModal } from '../../../stories/BeamModal'
import { ReactComponent as InitAutopayLaunchSvg } from './assets/initAutopayLaunch.svg'
import { initAutopayLaunchLocalStorageKey } from './PaymentsModalUtils'
import { PaymentsSetupModalsContext, PaymentsSetupModalsEnums } from './PaymentsSetupModals'

interface PaymentsLandingModalProps {
  open: boolean
  afterCloseHandler: (closedModal: PaymentsSetupModalsEnums) => void
}

const AutopayModalHeader = ({
  hasSeenAutopayLaunchModal,
}: {
  hasSeenAutopayLaunchModal: boolean
}) => {
  const labelText = !hasSeenAutopayLaunchModal
    ? 'Autopay Beam Fees is here!'
    : 'Set Up Autopay for Beam Fees'
  return (
    <div className={`grid`} slot={'label'}>
      {!hasSeenAutopayLaunchModal && <InitAutopayLaunchSvg className={`justify-self-center`} />}
      <h2 className={`text-center text-charcoal-800`}>{labelText}</h2>
    </div>
  )
}

/**
 * Modal displayed before the payment details modal with a summary of how auto pay works.
 */
export const PaymentsLandingModal = ({ open, afterCloseHandler }: PaymentsLandingModalProps) => {
  const context = useContext(PaymentsSetupModalsContext)
  const hasSeenAutopayLaunchModal = !!localStorage.getItem(initAutopayLaunchLocalStorageKey)

  let subHeader =
    'Sign up for Autopay to automatically process your platform subscription fees each month'

  function handleClick() {
    context.changeActiveModal(PaymentsSetupModalsEnums.paymentDetails)
  }

  if (!hasSeenAutopayLaunchModal) {
    subHeader = 'You can now pay your Beam fees each month without having to do anything!'
  }

  return (
    <BeamModal
      label={''}
      htmlLabel={<AutopayModalHeader hasSeenAutopayLaunchModal={hasSeenAutopayLaunchModal} />}
      className="beam--modal beam--empty-header-title"
      open={open}
      onCloseCallback={() =>
        afterCloseHandler(PaymentsSetupModalsEnums.setupBeamFeesAutoPayLanding)
      }
      subheading={subHeader}
      body={
        <>
          <div>
            <div>
              <p className="py-4 text-base font-secondary">
                We’ve partnered with trusted payments platform, Stripe, to make paying your Beam
                fees with Autopay easy:
              </p>
              <ol className="px-6 mb-8 space-y-4 list-decimal list font-secondary">
                <li>
                  Enter the card or bank details you want to use for monthly payments via card or
                  ACH direct debit
                </li>
                <li>
                  On the 7th of each month (or the next working day) your Beam invoice will be
                  emailed to you as normal
                </li>
                <li>Payment will be processed 2 working days after you receive your invoice</li>
              </ol>
            </div>
          </div>
        </>
      }
      footer={
        <div slot={'footer'}>
          <BeamButton
            label="Let’s Set You Up"
            variant="elevated"
            onClick={handleClick}
            className="mb-6"
          />

          <p className="font-secondary text-[12px] leading-4 text-center">
            You can update or remove your payment details from the Accounts page at any time. If you
            have any issues, please reach out via the Contact Us page.
          </p>
        </div>
      }
    />
  )
}
