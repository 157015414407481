import { REDESIGN_ROOT_PATH } from '../../../helpers'
import { useBeamSelector } from '../../../hooks'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { BeamHorizontalNavigation } from '../../../stories/BeamHorizontalNavigation'
import { getCurrentPageTitle } from '../../../utils/getCurrentPageTitle'
import { CampaignPageBody, TSite, TUser } from '../../../utils/types'
import { gtag } from '../../root/BeamGoogleAnalytics/googleAnalyticsHelpers'

export const BeamNonprofitPartnersPageNavigation = ({
  promos = null,
  site,
}: {
  promos: CampaignPageBody | null
  site: TSite | undefined
}) => {
  const featureFlags = useFeatureFlags()
  const user: TUser | undefined = useBeamSelector(({ user }) => user)
  const gtagMetadata = {
    chainId: user?.chainId || '',
    userId: user?.id || '',
    pageWhereEventTriggered: getCurrentPageTitle(),
  }

  if (featureFlags['can-see-campaign-management-feature'] === false) {
    return null
  }

  const nonprofitPartnersPageLinks = [
    {
      label: 'Nonprofit Partners',
      link: `${REDESIGN_ROOT_PATH}/nonprofits`,
      onClick: () => {
        gtag(`Nonprofit Partners tab clicked`, gtagMetadata)
      },
    },
    {
      label: 'Boosted Campaigns',
      link: `${REDESIGN_ROOT_PATH}/nonprofits/campaigns`,
      onClick: () => {
        gtag(`Boosted Campaigns tab clicked`, gtagMetadata)
      },
    },
  ]

  return <BeamHorizontalNavigation links={nonprofitPartnersPageLinks} />
}
