import { useEffect, useState } from 'react'

import { API_BASE_PATH_PORTAL } from '../../../api/auth'
import { useBeamSelector } from '../../../hooks'
import { axiosRequest } from '../../../utils/axiosRequest'
import { EUserType } from '../../../utils/types'
import { PageNotFound } from '../../root/PageNotFound'

export const PaypalGivingFundPageApproval = () => {
  const [gettingBillingAgreement, setGettingBillingAgreement] = useState(false)
  const [billingAgreementError, setBillingAgreementError] = useState('')
  const [success, setSuccess] = useState(false)

  const user = useBeamSelector(({ user }) => user)

  // Get billing agreement
  useEffect(() => {
    // if (!gettingBillingAgreement && billingAgreementError) {
    setGettingBillingAgreement(true)
    axiosRequest('post', `${API_BASE_PATH_PORTAL}/v2/invoices/createBillingAgreement`)
      .then(() => {
        setGettingBillingAgreement(false)
        setSuccess(true)
      })
      .catch((error: any) => {
        setBillingAgreementError(JSON.stringify(error))
        setSuccess(false)
      })
    // }
  }, [])

  if (user?.type !== EUserType.Executive) return <PageNotFound />

  return (
    <>
      <h1 className="beam--heading--1">
        {user.firstName} {user.lastName}
      </h1>
      {gettingBillingAgreement && (
        <div>
          <div>Finishing up your billing agreement now. Hang on...</div>
        </div>
      )}
      {success && (
        <div>You’ve successfully authorized donation fulfillment via PayPal Giving Fund! 🎉</div>
      )}
      <div>{billingAgreementError}</div>
    </>
  )
}
