import { compact } from 'lodash'

export const isValidEmail = (email: string) => {
  return /.+@[^@]+\.[^@]{2,}$/.test(email)
}

export function validateOneEmail(email: string): string | null {
  if (email.length <= 0) {
    return 'Email cannot be empty.'
  }

  if (!isValidEmail(email)) {
    return 'Invalid Email. Please enter a valid email address.'
  }

  return null
}

export function validateManyEmails(emails: string[]) {
  const errors = emails.map((email, index) => {
    if (email.length <= 0) {
      return {
        index,
        error: 'Email cannot be empty.',
      }
    }

    if (!isValidEmail(email)) {
      return {
        index,
        error: 'Invalid Email. Please enter a valid email address.',
      }
    }
  })
  const filteredErrors = compact(errors)

  return filteredErrors
}
