import { Grid } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useBeamSelector } from '../../hooks'
import { setLoadingNonprofit, setLoadingNonprofits } from '../../redux/actions'
import { createNonprofit, fetchCauses, updateNonprofit } from '../../redux/thunks/adminThunks'
import formStyles from '../../style/root/beam-form-fields.module.css'
import modalStyles from '../../style/root/beam-modal.module.css'
import { processApiError } from '../../utils/root'
import { BeamDropdownOption, TCause, TCauseNonprofit, TNonprofit } from '../../utils/types'
import BeamButton from '../root/BeamButton'
import BeamDropdown from '../root/BeamDropdown'
import BeamForm from '../root/BeamForm'
import { BeamModal } from '../root/BeamModal'
import { BeamProgressBar } from '../root/BeamProgressBar'

export const NonprofitEditModal = ({
  nonprofit = undefined,
  isOpen,
  onClose,
}: {
  nonprofit?: TNonprofit
  onClose: any
  isOpen?: boolean
}) => {
  const nonprofitCauses: BeamDropdownOption[] = useMemo(() => {
    if (!nonprofit?.causeNonprofits) return []

    return nonprofit.causeNonprofits.reduce(
      (result: BeamDropdownOption[], causeNonprofit: TCauseNonprofit) => {
        if (causeNonprofit?.cause?.id && causeNonprofit?.cause?.name) {
          result.push({ value: causeNonprofit.cause.id, display: causeNonprofit.cause.name })
        }
        return result
      },
      []
    )
  }, [nonprofit?.causeNonprofits])

  const nonprofitEditData = {
    name: nonprofit?.name || '',
    personalPhoto: nonprofit?.personalPhoto,
    communityPhoto: nonprofit?.communityPhoto,
    description: nonprofit?.description || '',
    howToHelpBeyondBeam: nonprofit?.howToHelpBeyondBeam || '',
    donationLink: nonprofit?.donationLink || '',
    actionLink: nonprofit?.actionLink || '',
    causes: nonprofitCauses,
    ppgfLink: nonprofit?.ppgfLink || '',
    ppgfId: nonprofit?.ppgfId || '',
    pointOfContactName: nonprofit?.pointOfContactName || '',
    pointOfContactEmail: nonprofit?.pointOfContactEmail || '',
    pointOfContactPhone: nonprofit?.pointOfContactPhone || '',
    pointOfContactTitle: nonprofit?.pointOfContactTitle || '',
    website: nonprofit?.website || '',
    scopeOfWork: nonprofit?.regions || '',
  }

  // FIXME: Use correct types when we have them.
  const dispatch = useDispatch()
  const [causeArray, setCauseArray] = useState<any>([])
  const [updatedNonprofitData, setNonprofitData] = useState<any>(nonprofitEditData)
  const causes = useBeamSelector(({ causes }) => causes) as TCause[]
  const loadingStates = useBeamSelector(({ loadingStates }) => {
    const _loadingStates = loadingStates as any
    return nonprofit ? _loadingStates.nonprofit : _loadingStates.nonprofits
  }) as any
  const [submissionStatus, setSubmissionStatus] = useState(false)

  useEffect(() => {
    if (!causes.length) {
      dispatch(fetchCauses())
    }
  }, [causes, dispatch])

  useEffect(() => {
    if (causes.length > 0 && causeArray.length < 1) {
      const nonprofitCauseIds = new Set()
      nonprofitCauses.forEach((nonprofitCause: any) => {
        nonprofitCauseIds.add(nonprofitCause.value)
      })

      const mappedCauses: BeamDropdownOption[] = causes
        ? causes.reduce((result: BeamDropdownOption[], cause: TCause) => {
            if (!nonprofitCauseIds.has(cause.id)) {
              result.push({ value: cause.id, display: cause.name })
            }
            return result
          }, [])
        : []

      mappedCauses.sort((a: any, b: any) =>
        a.display > b.display ? 1 : b.display > a.display ? -1 : 0
      )

      setCauseArray(mappedCauses)
    }
  }, [causeArray, causes, nonprofitCauses])

  const handleInput = (name: string, value: any) => {
    setNonprofitData({ ...updatedNonprofitData, [name]: value })
  }

  const handleCauseAdd = (value: number) => {
    const updatedCauseArray = causeArray.slice()
    const mappedCause = updatedCauseArray.findIndex((cause: any) => {
      return cause.value === +value
    })
    const cause = updatedCauseArray.splice(mappedCause, 1)
    setCauseArray(updatedCauseArray)
    setNonprofitData({
      ...updatedNonprofitData,
      causes: [...updatedNonprofitData.causes, ...cause],
    })
  }

  const handleCauseRemove = (value: number) => {
    const updatedNonprofitCauseArray = updatedNonprofitData.causes.slice()
    const mappedCause = updatedNonprofitCauseArray.findIndex((cause: any) => {
      return cause.value === +value
    })
    const cause = updatedNonprofitCauseArray.splice(mappedCause, 1)
    setNonprofitData({ ...updatedNonprofitData, causes: updatedNonprofitCauseArray })
    const updatedCauseArray = [...causeArray, ...cause]
    updatedCauseArray.sort((a: any, b: any) =>
      a.display > b.display ? 1 : b.display > a.display ? -1 : 0
    )
    setCauseArray(updatedCauseArray)
  }

  const submitCreateNonprofit = () => {
    const causeReturnArray: number[] = updatedNonprofitData.causes.map((cause: any) => {
      return cause.value
    })
    const returnUpdatedNonprofitData = {
      ...updatedNonprofitData,
      causes: causeReturnArray.toString(),
    }
    dispatch(createNonprofit(returnUpdatedNonprofitData))
    setSubmissionStatus(true)
  }

  const submitUpdateNonprofit = () => {
    const causeReturnArray: number[] = updatedNonprofitData.causes.map((cause: any) => {
      return cause.value
    })

    const returnUpdatedNonprofitData = {
      ...updatedNonprofitData,
      causes: causeReturnArray.toString(),
      id: nonprofit?.id,
    }

    if (
      nonprofitEditData?.personalPhoto &&
      returnUpdatedNonprofitData.personalPhoto.toString() ===
        nonprofitEditData.personalPhoto.toString()
    ) {
      returnUpdatedNonprofitData.personalPhoto = null
    }

    if (
      nonprofitEditData?.communityPhoto &&
      returnUpdatedNonprofitData.communityPhoto.toString() ===
        nonprofitEditData.communityPhoto.toString()
    ) {
      returnUpdatedNonprofitData.communityPhoto = null
    }

    dispatch(updateNonprofit(returnUpdatedNonprofitData))
    setSubmissionStatus(true)
  }

  const close = () => {
    dispatch(
      nonprofit
        ? setLoadingNonprofit({ loading: false, error: null, success: null })
        : setLoadingNonprofits({ loading: false, error: null, success: null })
    )
    setNonprofitData(nonprofitEditData)
    setSubmissionStatus(false)
    onClose()
  }

  const formFields = [
    { name: 'name', label: 'Name*', type: 'text', value: updatedNonprofitData.name },
    {
      name: 'description',
      label: 'Description',
      type: 'textarea',
      value: updatedNonprofitData.description,
    },
    {
      name: 'howToHelpBeyondBeam',
      label: 'How to Help Beyond Beam',
      type: 'textarea',
      value: updatedNonprofitData.howToHelpBeyondBeam,
    },
    { name: 'ppgfLink', label: 'PayPal Link', type: 'text', value: updatedNonprofitData.ppgfLink },
    { name: 'ppgfId', label: 'PayPal ID', type: 'text', value: updatedNonprofitData.ppgfId },
    {
      name: 'pointOfContactName',
      label: 'Point of Contact Name',
      type: 'text',
      value: updatedNonprofitData.pointOfContactName,
    },
    {
      name: 'pointOfContactEmail',
      label: 'Point of Contact Email*',
      type: 'text',
      value: updatedNonprofitData.pointOfContactEmail,
    },
    {
      name: 'pointOfContactPhone',
      label: 'Point of Contact Phone',
      type: 'text',
      value: updatedNonprofitData.pointOfContactPhone,
    },
    {
      name: 'pointOfContactTitle',
      label: 'Point of Contact Title',
      type: 'text',
      value: updatedNonprofitData.pointOfContactTitle,
    },
    { name: 'website', label: 'Website', type: 'text', value: updatedNonprofitData.website },
    {
      name: 'actionLink',
      label: 'Action Link',
      type: 'text',
      value: updatedNonprofitData.actionLink,
    },
    {
      name: 'donationLink',
      label: 'Donation Link*',
      type: 'text',
      value: updatedNonprofitData.donationLink,
    },
    {
      name: 'scopeOfWork',
      label: 'Scope of Work',
      type: 'text',
      value: updatedNonprofitData.scopeOfWork,
      detailText: '* Comma separated list',
    },
    {
      name: 'personalPhoto',
      label: 'Personal Photo*',
      type: 'file',
      file:
        typeof updatedNonprofitData.personalPhoto === 'string'
          ? updatedNonprofitData.personalPhoto
          : updatedNonprofitData.personalPhoto?.name || '',
      value: updatedNonprofitData.personalPhoto,
    },
    {
      name: 'communityPhoto',
      label: 'Community Photo*',
      type: 'file',
      file:
        typeof updatedNonprofitData.communityPhoto === 'string'
          ? updatedNonprofitData.communityPhoto
          : updatedNonprofitData.communityPhoto?.name || '',
      value: updatedNonprofitData.communityPhoto,
    },
  ]

  return (
    <BeamModal
      title={nonprofit ? 'Editing Nonprofit Info' : 'Create Nonprofit'}
      open={!!isOpen}
      handleClose={close}
      isLarge={true}>
      <div className={modalStyles['modal-container']}>
        {submissionStatus && (
          <BeamModal
            title={
              loadingStates?.loading ? 'Processing...' : loadingStates?.error ? 'Error' : 'Success!'
            }
            titleColor={loadingStates?.loading || loadingStates?.error ? '#000' : '#4caf50'}
            open={submissionStatus}
            handleClose={() => (loadingStates?.error ? setSubmissionStatus(false) : close())}>
            <div>
              {loadingStates?.error ? (
                processApiError(loadingStates?.error)
              ) : loadingStates?.loading ? (
                <BeamProgressBar />
              ) : (
                <span>&#127881;</span>
              )}
            </div>
          </BeamModal>
        )}

        <BeamForm fields={formFields} globalChangeHandler={handleInput} />
        <div className={formStyles['beam-form-field-container']}>
          <Grid
            container
            spacing={1}
            style={{ alignItems: 'center', justifyContent: 'space-between' }}>
            <Grid item xs={5} sm={3}>
              <div>Causes*:</div>
            </Grid>
            <Grid item xs={7} sm={9}>
              <BeamDropdown
                useMultipleSelection={true}
                multipleSelectedValues={updatedNonprofitData.causes}
                options={causeArray}
                placeholderDisplay={'Causes'}
                hideClearSelection={true}
                changeHandler={(_, value) => handleCauseAdd(+value)}
                handleMultiClose={(_, value) => handleCauseRemove(+value)}
              />
            </Grid>
          </Grid>
        </div>
        <BeamButton
          text="SUBMIT"
          handler={nonprofit ? submitUpdateNonprofit : submitCreateNonprofit}
        />
      </div>
    </BeamModal>
  )
}
