import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { REDESIGN_ROOT_PATH } from '../../../helpers'
import { useBeamSelector } from '../../../hooks'
import { BeamButton } from '../../../stories/BeamButton'
import { BeamLoadingIndicator } from '../../../stories/BeamLoadingIndicator'
import { BeamModal } from '../../../stories/BeamModal/BeamModal'
import { BeamToast } from '../../../stories/BeamToast'
import { PageNotFound } from '../../root/PageNotFound'
import $$ from './yotpo-integration-settings.module.css'
import {
  disableYotpoIntegration,
  fetchYotpoIntegrationsByChainId,
  YotpoIntegration,
} from './yotpoService'

export const YotpoIntegrationSettingsPage = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const user = useBeamSelector(({ user }) => user)
  const [integrations, setIntegrations] = useState<YotpoIntegration[]>([])
  const [error, setError] = useState<string | null>(null)
  // State to manage the visibility of the toast notification
  const [openToast, setOpenToast] = useState<boolean>(false)
  // State to manage the visibility of the disable integration modal
  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedIntegration, setSelectedIntegration] = useState<YotpoIntegration | null>(null)
  const [isDisabling, setIsDisabling] = useState<boolean>(false)
  const FETCH_INTEGRATIONS_ERROR_MESSAGE = 'Failed to load integrations.'
  useEffect(() => {
    if (!user?.chainId) return

    const fetchIntegrations = async () => {
      try {
        const data = await fetchYotpoIntegrationsByChainId(user.chainId)
        setIntegrations(data)
      } catch (err) {
        setError(FETCH_INTEGRATIONS_ERROR_MESSAGE)
      } finally {
        setLoading(false)
      }
    }

    fetchIntegrations()
  }, [user?.chainId])

  if (!user?.chainId) return <PageNotFound />

  const handleDisableClick = (integration: YotpoIntegration) => {
    setSelectedIntegration(integration)
    setShowModal(true)
  }

  const confirmDisable = async () => {
    if (!selectedIntegration) return

    setIsDisabling(true)
    try {
      await disableYotpoIntegration(selectedIntegration.id)
      setIntegrations(integrations.filter(integration => integration.id !== selectedIntegration.id))
      setOpenToast(true)
      setShowModal(false)
    } catch (err) {
      setError('Failed to disable Yotpo Store ID')
      setOpenToast(true)
    } finally {
      setIsDisabling(false)
    }
  }

  return (
    <>
      <h1 className="beam--heading--1">Yotpo Integrations</h1>
      <p>
        For questions about your Yotpo integration,{' '}
        <Link to={`${REDESIGN_ROOT_PATH}/contact-support`}>contact support</Link>
      </p>
      {loading ? (
        <BeamLoadingIndicator />
      ) : (
        <>
          {integrations.length > 0 && (
            <>
              <h2 className="beam--heading--2">Your Yotpo Integration(s):</h2>
              {integrations.map(integration => (
                <div key={integration.id} className={$$.yotpoIntegrationItemContainer}>
                  <div className={$$.yotpoIntegrationItemDetails}>
                    <p className={$$.yotpoItemStoreIdHeader}>Yotpo Store ID:</p>
                    <p className={$$.yotpoItemIntegrationId}>
                      {integration.yotpoStoreId.substring(0, 5)}...
                    </p>
                  </div>
                  <BeamButton
                    label="Disable"
                    variant={'flat_white'}
                    onClick={() => handleDisableClick(integration)}
                  />
                </div>
              ))}
            </>
          )}
        </>
      )}
      <BeamToast
        onClose={() => setOpenToast(false)}
        open={openToast}
        closable={true}
        text={error ? error : 'Yotpo integration disabled successfully!'}
        variant={error ? 'error' : 'success'}
        duration={5000}
      />
      {showModal && (
        <BeamModal
          open={showModal}
          label={isDisabling ? 'Disabling Integration...' : 'Disable Integration'}
          onCloseCallback={() => setShowModal(false)}
          disableClickOutside={false}
          body={
            isDisabling ? (
              <BeamLoadingIndicator />
            ) : (
              <>
                <p className={$$.modalBodyDescription}>Do you want to disable Yotpo Store ID:</p>
                <p className={$$.modalBodyDescription}>
                  {selectedIntegration?.yotpoStoreId.substring(0, 5)}...
                </p>
                <div className={$$.modalButtons}>
                  <BeamButton
                    label="Cancel"
                    variant={'flat_white'}
                    onClick={() => setShowModal(false)}
                  />
                  <BeamButton variant={'basic'} label="Confirm" onClick={confirmDisable} />
                </div>
              </>
            )
          }
        />
      )}
    </>
  )
}

export default YotpoIntegrationSettingsPage
