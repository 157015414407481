import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import { ComponentType, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import { inDevEnvironment } from '../../api/auth'
import { REDESIGN_ROOT_PATH } from '../../helpers'
import { useBeamSelector } from '../../hooks'
import { useRefreshToken } from '../../hooks/useRefreshToken'
import { fetchUser as fetchUserThunk } from '../../redux/thunks/authThunks'
import {
  fetchInvoices as fetchInvoicesThunk,
  fetchPartnerInvoices as fetchPartnerInvoicesThunk,
} from '../../redux/thunks/invoiceThunks'
import { isAdminOrSuper } from '../../utils/helpers/isAdminOrSuper'
import { TUser } from '../../utils/types'
import { AdminDiscountsPage } from '../admin/AdminDiscountsPage/AdminDiscountsPage'
import { AdminInvoices } from '../admin/adminInvoicesPage/AdminInvoices'
import { AdminPartnerUploadsPage } from '../admin/AdminPartnerUploads/AdminPartnerUploadsPage'
import { CentralBackendPartnerReport } from '../admin/CentralBackendPartnerReport'
import { CreatePartnerTabs } from '../admin/CreatePartnerTabs'
import { CreateUser } from '../admin/CreateUser'
import { KlaviyoPage } from '../admin/klaviyo/KlaviyoPage'
import Nonprofit from '../admin/Nonprofit'
import { Nonprofits } from '../admin/Nonprofits'
import Partner from '../admin/Partner'
import { Partners } from '../admin/Partners'
import { SalesChannelsPage } from '../admin/salesChannels/SalesChannelsPage'
import { WidgetConfigPage } from '../admin/widget-config/WidgetConfigPage'
import { ContactSupportPage } from '../partner/ContactSupportPage'
import { DataWikiPage } from '../partner/DataWiki/DataWikiPage'
import { InvoicePage } from '../partner/InvoicePage'
import { NonprofitPartnersPage } from '../partner/NonprofitPartnersPage'
import { CampaignsPage } from '../partner/NonprofitPartnersPage/CampaignsPage'
import { OverviewPage } from '../partner/OverviewPage'
import { OverviewPageClone } from '../partner/OverviewPage/OverviewPageClone'
import { PartnerInvoices } from '../partner/PartnerInvoices'
import { PartnerReportPage } from '../partner/PartnerReportPage'
import { PartnerUploadsPage } from '../partner/PartnerUploads/PartnerUploadsPage'
import { SharedPartnerUploadPage } from '../partner/PartnerUploads/SharedPartnerUploadPage'
import { PayPalApproval } from '../partner/PayPalApproval'
import { PaypalGivingFundPage } from '../partner/PaypalGivingFundPage'
import { PaypalGivingFundPageApproval } from '../partner/PaypalGivingFundPage/PaypalGivingFundPageApproval'
import { PayPalOnboarding } from '../partner/PayPalOnboarding'
import { ProfilePage } from '../partner/ProfilePage'
import { ReportsPageAsync as ReportsPageCSUploads } from '../partner/ReportsPageBeta/async.csuploads'
import { SalesforceOauthCallbackPage } from '../partner/SalesforceOauthCallbackPage/SalesforceOathCallbackPage'
import { SalesforceOathErrorPage } from '../partner/SalesforceOauthErrorPage/SalesforceOathErrorPage'
import { SalesforceOauthLandingPage } from '../partner/SalesforceOauthLandingPage/SalesforceOauthLandingPage'
import { YotpoIntegrationSettingsPage } from '../partner/YotpoIntegrationSettingsPage'
import { YotpoOauthCallbackPage } from '../partner/YotpoOauthCallbackPage/YotpoOathCallbackPage'
import { YotpoOathErrorPage } from '../partner/YotpoOauthErrorPage/YotpoOathErrorPage'
import { YotpoOauthLandingPage } from '../partner/YotpoOauthLandingPage/YotpoOauthLandingPage'
import { AccountPage } from './AccountPage'
import ApiKeys from './ApiKeys'
import { ForgotPasswordPage } from './ForgotPasswordPage'
import Home from './Home'
import { LoginPage } from './LoginPage'
import { LogoutPage } from './LogoutPage'
import { PageNotFound } from './PageNotFound'
import { PasswordResetPage } from './PasswordResetPage'
import { PrivateRoute } from './PrivateRoute'
import { SideNavigation } from './SideNavigation'
import { TopNavigation } from './TopNavigation'
import { TwoFactorLoginPage } from './TwoFactorLoginPage'

interface RouteInfo {
  path: string
  component: ComponentType<any>
  /**
   * https://v5.reactrouter.com/web/api/Route/exact-bool
   */
  exact?: boolean
}

interface RoutesObject {
  LEGACY_PRIVATE_ROUTES: RouteInfo[]
  ADMIN_ROUTES: RouteInfo[]
  PRIVATE_ROUTES: RouteInfo[]
  PUBLIC_ROUTES: RouteInfo[]
}

// Temporary route while CS Uploads feature is under development
export const partnerUploadsUrl = `${REDESIGN_ROOT_PATH}/reports/partner-uploads`
export const dataWikiUrl = `${REDESIGN_ROOT_PATH}/data-wiki`
export const overviewWithSubscriptionsUrl = `${REDESIGN_ROOT_PATH}/__feature/subscription-reporting`

const appRoutes: RoutesObject = {
  // legacy/deprecated routes from Partner Portal v1
  LEGACY_PRIVATE_ROUTES: [
    { path: '/', component: Home, exact: true },
    { path: '/profile', component: AccountPage, exact: true },
    { path: '/logout', component: LogoutPage, exact: true },
    { path: '/partner/invoices', component: PartnerInvoices, exact: true },
    { path: '/partner/apikeys', component: ApiKeys, exact: true },
    { path: '/partner/paypal/onboarding', component: PayPalOnboarding, exact: true },
    { path: '/partner/paypal/onboarding/approval', component: PayPalApproval, exact: true },
  ],

  // Admin-facing routes
  ADMIN_ROUTES: [
    { path: '/admin/partners/all', component: Partners, exact: true },
    { path: '/admin/nonprofits/all', component: Nonprofits, exact: true },
    { path: '/admin/partners/partner-portal/v2/:id', component: Partner },
    { path: '/admin/partners/partner-portal/:id', component: Partner },
    { path: '/admin/partners/central-backend/:id', component: CentralBackendPartnerReport },
    { path: '/admin/nonprofits/:id', component: Nonprofit },
    { path: '/admin/createuser', component: CreateUser, exact: true },
    { path: '/admin/createpartner', component: CreatePartnerTabs, exact: true },
    { path: '/admin/invoices', component: AdminInvoices, exact: true },
    { path: '/partner/reports', component: PartnerReportPage, exact: true },
    { path: '/admin/apikeys', component: ApiKeys, exact: true },
    { path: '/admin/partners/:id/widget-config', component: WidgetConfigPage, exact: true },
    { path: '/admin/partners/:id/klaviyo', component: KlaviyoPage, exact: true },
    { path: '/admin/partners/:chainId/salesChannels', component: SalesChannelsPage, exact: true },
    { path: '/admin/partners/:chainId/uploads', component: AdminPartnerUploadsPage, exact: true },
    { path: '/admin/partners/:chainId/discounts', component: AdminDiscountsPage, exact: true },
  ],

  // Most partner-facing routes go here. These routes are wrapped by global page styles.
  PRIVATE_ROUTES: [
    {
      path: `${REDESIGN_ROOT_PATH}/logout`,
      component: LogoutPage,
      exact: true,
    },
    {
      path: `${REDESIGN_ROOT_PATH}/contact-support`,
      component: ContactSupportPage,
      exact: true,
    },
    {
      path: `${REDESIGN_ROOT_PATH}/yotpo/settings`,
      component: YotpoIntegrationSettingsPage,
      exact: true,
    },
    {
      path: `${REDESIGN_ROOT_PATH}/reports`,
      component: ReportsPageCSUploads,
      exact: true,
    },
    {
      path: partnerUploadsUrl,
      component: PartnerUploadsPage,
      exact: true,
    },
    {
      path: `${partnerUploadsUrl}/:partnerUploadId`,
      component: SharedPartnerUploadPage,
      exact: true,
    },
    {
      path: `${REDESIGN_ROOT_PATH}/`,
      component: OverviewPage,
      exact: true,
    },
    {
      path: `${REDESIGN_ROOT_PATH}/overview`,
      component: OverviewPage,
      exact: true,
    },
    {
      path: overviewWithSubscriptionsUrl,
      component: OverviewPageClone,
      exact: true,
    },
    {
      path: dataWikiUrl,
      component: DataWikiPage,
      exact: true,
    },
    {
      path: `${REDESIGN_ROOT_PATH}/nonprofits`,
      component: NonprofitPartnersPage,
      exact: true,
    },
    {
      path: `${REDESIGN_ROOT_PATH}/nonprofits/campaigns`,
      component: CampaignsPage,
      exact: true,
    },
    {
      path: `${REDESIGN_ROOT_PATH}/invoices`,
      component: InvoicePage,
      exact: true,
    },
    {
      path: `${REDESIGN_ROOT_PATH}/profile`,
      component: ProfilePage,
      exact: true,
    },
    {
      path: `${REDESIGN_ROOT_PATH}/paypal-giving-fund`,
      component: PaypalGivingFundPage,
      exact: true,
    },
    {
      path: `${REDESIGN_ROOT_PATH}/paypal-giving-fund/approved`,
      component: PaypalGivingFundPageApproval,
      exact: true,
    },
    // START Salesforce OAUTH Routes
    {
      path: `${REDESIGN_ROOT_PATH}/salesforce/authorize`,
      component: SalesforceOauthLandingPage,
      exact: true,
    },
    {
      path: `${REDESIGN_ROOT_PATH}/salesforce/authorize-callback`,
      component: SalesforceOauthCallbackPage,
      exact: true,
    },
    {
      path: `${REDESIGN_ROOT_PATH}/salesforce/error`,
      component: SalesforceOathErrorPage,
      exact: true,
    },
    // END Salesforce OAUTH Routes
    // START Yotpo OAUTH Routes
    {
      path: `${REDESIGN_ROOT_PATH}/yotpo/authorize`,
      component: YotpoOauthLandingPage,
      exact: true,
    },
    {
      path: `${REDESIGN_ROOT_PATH}/yotpo/authorize-callback`,
      component: YotpoOauthCallbackPage,
      exact: true,
    },
    {
      path: `${REDESIGN_ROOT_PATH}/yotpo/error`,
      component: YotpoOathErrorPage,
      exact: true,
    },
    // END Yotpo OAUTH Routes
  ],

  // Public routes (aka routes that don't require auth to view)
  PUBLIC_ROUTES: [
    { path: `${REDESIGN_ROOT_PATH}/login`, component: LoginPage, exact: true },
    { path: `${REDESIGN_ROOT_PATH}/forgot-password`, component: ForgotPasswordPage, exact: true },
    { path: `${REDESIGN_ROOT_PATH}/password-reset/:userId/:token`, component: PasswordResetPage },
    {
      path: `${REDESIGN_ROOT_PATH}/login/two-factor/:twoFactorToken/:id`,
      component: TwoFactorLoginPage,
    },
    { path: '/404', component: PageNotFound },
  ],
}

const Root = () => {
  const [fetchedInvoices, setFetchedInvoices] = useState(false)

  // FIXME: Remove `any` when have have typedefs
  const user = useBeamSelector(({ user }) => user) as TUser
  const loadingStates = useBeamSelector(({ loadingStates }) => loadingStates) as any
  const invoices = useBeamSelector(({ invoices }) => invoices)
  const dispatch = useDispatch()
  const refresh = useRefreshToken()

  // Fetch token and session user on first load
  useEffect(() => {
    if (loadingStates?.user?.loading || loadingStates?.user?.error) return
    if (user?.type) return

    refresh()
      .then(() => {
        dispatch(fetchUserThunk())
      })
      .catch(error => {
        if (inDevEnvironment) {
          console.trace(error)
        } else {
          console.log(error)
        }
      })
  }, [dispatch, loadingStates?.user?.error, loadingStates?.user?.loading, refresh, user?.type])

  // Fetch invoices if they haven't been fetched
  useEffect(() => {
    if (user?.type && !loadingStates.invoices?.loading && !invoices.length && !fetchedInvoices) {
      if (isAdminOrSuper(user.type)) {
        dispatch(fetchInvoicesThunk())
      } else if (user.type === 'Executive') {
        dispatch(fetchPartnerInvoicesThunk(user.partnerId))
      }
      setFetchedInvoices(true)
    }
  }, [
    dispatch,
    fetchedInvoices,
    invoices?.length,
    loadingStates.invoices?.loading,
    user?.partnerId,
    user?.type,
  ])

  return (
    <Container maxWidth="xl">
      <Grid container spacing={4}>
        <Grid item md={2} lg={2} style={{ backgroundColor: '#FAFAFA' }}>
          <Hidden smDown>
            <SideNavigation showLogo={true} />
          </Hidden>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={10}
          lg={10}
          style={{
            minHeight: '100vh',
            // These prevent `position: sticky` from working anyone on the site
            // overflowX: 'hidden',
            // maxWidth: '100vw',
          }}>
          <Hidden mdUp>
            <TopNavigation />
          </Hidden>
          <Switch>
            {[appRoutes.ADMIN_ROUTES, appRoutes.LEGACY_PRIVATE_ROUTES].map(routes => {
              return routes.map(routeInfo => {
                return (
                  <PrivateRoute
                    key={routeInfo.path}
                    exact={routeInfo.exact}
                    path={routeInfo.path}
                    component={routeInfo.component}
                  />
                )
              })
            })}

            {appRoutes.PUBLIC_ROUTES.map(routeInfo => {
              return (
                <Route
                  key={routeInfo.path}
                  exact={routeInfo.exact}
                  path={routeInfo.path}
                  component={routeInfo.component}
                />
              )
            })}

            <div className="beam--page-content">
              {appRoutes.PRIVATE_ROUTES.map(routeInfo => {
                return (
                  <PrivateRoute
                    key={routeInfo.path}
                    exact={routeInfo.exact}
                    path={routeInfo.path}
                    component={routeInfo.component}
                  />
                )
              })}
            </div>
          </Switch>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Root
