/**
 * Relevant API docs: https://stripe.com/docs/payments/save-and-reuse?platform=web&client=react
 */

import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useEffect, useState } from 'react'

import { useBeamSelector } from '../../../hooks'
import { BeamButton } from '../../../stories/BeamButton'
import { BeamLoadingIndicator } from '../../../stories/BeamLoadingIndicator'
import { BeamModal } from '../../../stories/BeamModal'
import { BeamTextfield } from '../../../stories/BeamTextfield'
import { BeamToast } from '../../../stories/BeamToast'
import { TUser } from '../../../utils/types'
import { StripeProvider } from '../../root/StripeProvider'
import $$ from './payments-modals.module.css'
import { CustomerPaymentDataFromStripe, fetchPaymentInfo } from './PaymentsModal.api'
import { PaymentsSetupModalsEnums } from './PaymentsSetupModals'

const ReadOnlyPaymentInfo = () => {
  const [paymentInfo, setPaymentInfo] = useState<CustomerPaymentDataFromStripe | null>(null)

  useEffect(() => {
    if (!paymentInfo?.paymentMethod?.last4) {
      fetchPaymentInfo()
        .then(data => setPaymentInfo(data))
        .catch((error: any) => {
          console.log('FetchPaymentInfoFailed', error)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!paymentInfo) return null

  const displayValues = {
    cc: `xxxx xxxx xxxx ${paymentInfo?.paymentMethod.last4}`,
    cvc: 'xxx',
    exp: 'xx/xx',
    zip: paymentInfo.paymentMethod.postalCode,
  }

  return (
    <div className="space-y-[9px]">
      <BeamTextfield
        label="Card number"
        name="card"
        value={displayValues.cc}
        className={$$.roundedTextfield}
        disabled
      />

      <div className="flex justify-between space-x-[9px]">
        <BeamTextfield
          label="Expiration"
          name="expiration"
          value={displayValues.exp}
          className={$$.roundedTextfield}
          disabled
        />
        <BeamTextfield
          label="CVC"
          name="cvc"
          value={displayValues.cvc}
          className={$$.roundedTextfield}
          disabled
        />
      </div>

      <div>
        <BeamTextfield
          label="ZIP"
          name="zip"
          value={displayValues.zip || ''}
          className={$$.roundedTextfield}
          disabled
        />
      </div>
    </div>
  )
}

interface PaymentBodyProps {
  isProcessing: boolean
  setIsProcessing: (x: boolean) => void
  setErrorMessage: (msg: string | null) => void
}

const PaymentBody = ({ isProcessing, setIsProcessing, setErrorMessage }: PaymentBodyProps) => {
  const stripe = useStripe()
  const elements = useElements()
  const user = useBeamSelector(({ user }) => user) as TUser

  useEffect(() => {
    if (isProcessing) {
      handleSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProcessing])

  async function handleSubmit() {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded. Will show error before getting here.
      return
    }

    if (!user?.partner?.invoiceEmail) {
      setErrorMessage(
        'There was problem saving your payment method. Please ensure billing details configured before retrying.'
      )
      return
    }

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location}`,
        payment_method_data: {
          billing_details: {
            email: user?.partner?.invoiceEmail,
          },
        },
      },
    })

    if (error) {
      // This point will only be reached if there is an immediate error when confirming the payment. Show error to your customer (for example, payment details incomplete)
      console.log({ error })

      setErrorMessage(error.message as string)
      setIsProcessing(false)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      setIsProcessing(false)
    }
  }

  if (!stripe || !elements) return null

  return (
    <PaymentElement
      onLoadError={error => console.error('PaymentElementError ->', error)}
      options={{
        // hide email field, use partner's invoiceEmail in the background
        fields: { billingDetails: { email: 'never' } },
        paymentMethodOrder: ['us_bank_account', 'card'],
      }}
    />
  )
}

interface PaymentsModalProps {
  open: boolean
  afterCloseHandler: (closedModal: PaymentsSetupModalsEnums) => void
  readOnlyMode?: boolean
}

export const PaymentDetailsModal = ({
  open,
  afterCloseHandler,
  readOnlyMode = true,
}: PaymentsModalProps) => {
  const [isProcessing, setIsProcessing] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [currentMode, setCurrentMode] = useState<'readOnly' | 'edit'>('edit')

  useEffect(() => setCurrentMode(readOnlyMode ? 'readOnly' : 'edit'), [readOnlyMode])

  function handleClickSubmit() {
    if (currentMode === 'readOnly') {
      setCurrentMode('edit')
      return
    }

    // `isProcessing` serves as an event for PaymentBody to trigger submit logic
    setIsProcessing(true)
  }

  const buttonLabel = currentMode === 'readOnly' ? 'Change Your Card Details' : 'Submit'

  return (
    <>
      <BeamToast
        variant="error"
        open={!!errorMessage}
        text={errorMessage ?? 'This is a test error message'}
        onClose={() => setErrorMessage(null)}
        closable
      />

      <BeamModal
        label="Enter Payment Details"
        data-testid="payments-modal"
        open={open}
        onCloseCallback={() =>
          afterCloseHandler(
            readOnlyMode
              ? PaymentsSetupModalsEnums.viewPaymentDetails
              : PaymentsSetupModalsEnums.paymentDetails
          )
        }
        subheading={
          <p className={`pb-5 text-base text-center font-secondary text-light-grey-1`}>
            {currentMode === 'readOnly' ? (
              <>
                Your Beam fees will be automatically deducted two working days after you receive
                your invoice
              </>
            ) : (
              <>
                <>Enter your details to set up Autopay and automate paying your monthly Beam fees</>
                <br />
                <span className="text-base font-bold text-charcoal-800 font-secondary">
                  No processing fee charged when you sign up for Autopay by ACH
                </span>
              </>
            )}
          </p>
        }
        body={
          <div className="relative">
            {isProcessing && !errorMessage && <BeamLoadingIndicator withBackdrop />}

            {currentMode === 'readOnly' ? (
              <ReadOnlyPaymentInfo />
            ) : open ? (
              <StripeProvider>
                <PaymentBody
                  isProcessing={isProcessing}
                  setIsProcessing={setIsProcessing}
                  setErrorMessage={setErrorMessage}
                />
              </StripeProvider>
            ) : null}
          </div>
        }
        footer={
          <div className="flex flex-col items-center pt-6" slot={'footer'}>
            <BeamButton
              label={buttonLabel}
              type="button"
              variant="elevated"
              slot={'footer'}
              disabled={isProcessing}
              onClick={handleClickSubmit}
              className="w-full"
            />
          </div>
        }
        disableClickOutside
      />
    </>
  )
}
