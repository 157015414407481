import cx from 'classnames'

import { Maybe } from '../../utils/types'
import { BeamCard } from '../BeamCard'
import { BeamLoadingIndicator } from '../BeamLoadingIndicator'
import { BeamDSProps } from '../interface'
import $$ from './beam-data-point-block.module.css'

interface BeamDataPointBlockProps extends BeamDSProps {
  /**
   * Title of the card, name of the data point being displayed
   */
  title: string | React.ReactNode
  /**
   * The data point
   */
  datum: Maybe<string | number | React.ReactNode>
  /**
   * Description of data point
   */
  description?: string | React.ReactNode
  /**
   * Icon that displays at the upper right corner of card
   */
  icon?: React.ReactNode
  /**
   * Content on the bottom right of box.  Usually a link to something
   */
  footer?: React.ReactNode
  /**
   * Background color of the card. Potentials are based on colors defined in the branding guidelines (in other words, no custom hex values or RGBs)
   */
  backgroundColor?: string
  /**
   * Background pattern for the card. If set to 'striped', it uses the given "backgroundColor" for the color of the stripes.
   * @default solid
   */
  backgroundPattern?: 'solid' | 'striped'
  /**
   * Font size of the datum displayed in the block
   */
  datumSize?: 'small' | 'large'
  /**
   * Style of description
   */
  descriptionStyle?: 'no-data'
  /**
   * Loading state of the data
   */
  loading?: boolean
}

/**
 * Data Point Block component
 */
export const BeamDataPointBlock = ({
  title,
  datum,
  description,
  icon,
  footer,
  backgroundColor,
  backgroundPattern,
  datumSize = 'small',
  descriptionStyle,
  loading = false,
  ...props
}: BeamDataPointBlockProps) => {
  return (
    <BeamCard
      {...props}
      className={cx(props.className)}
      backgroundColor={backgroundColor}
      backgroundPattern={backgroundPattern}
      excludeBorder={true}>
      <div className={`${$$.beamDataPointBlock}`}>
        <div className={`${$$.icon}`}>{icon}</div>
        <span className={`${$$.title}`}>{title}</span>
        {loading ? (
          <div className="flex items-center justify-center w-full h-full">
            <BeamLoadingIndicator />
          </div>
        ) : (
          <>
            <span className={cx($$.datum, $$[`datum--${datumSize}`])}>{datum}</span>
            <span className={cx($$.description, $$[`description--${descriptionStyle}`])}>
              {description}
            </span>
          </>
        )}
        <span className={`${$$.footer}`}>{footer}</span>
      </div>
    </BeamCard>
  )
}
