import { ReactNode } from 'react'

import { BeamTooltip } from '../../stories/BeamTooltip'

export const TitleWithTooltip = ({
  title,
  tooltipContent,
  className,
}: {
  title: string
  tooltipContent: string | ReactNode
  className?: string
}) => {
  return (
    <BeamTooltip content={tooltipContent} className={className}>
      <span slot="anchor" />
      <span className={'border-b border-charcoal-200 border-dashed pb-1.5'}>{title}</span>
    </BeamTooltip>
  )
}
