import cx from 'classnames'
import { createContext, useEffect, useState } from 'react'

import { useBeamSelector } from '../../../../hooks'
import { BeamButton } from '../../../../stories/BeamButton'
import { BeamCTA } from '../../../../stories/BeamCTA'
import { BeamToast } from '../../../../stories/BeamToast'
import { CampaignPageBody, TSite, TUser } from '../../../../utils/types'
import { BeamSEO } from '../../../root/BeamSEO'
import { TCreateAutoBoostNonprofitData } from '../AutoBoostANonprofitModal.types'
import { BeamNonprofitPartnersPageNavigation } from '../BeamNonprofitPartnersPageNavigation'
import { PromoModal, PromoModalsComponent } from '../PromoModalsComponent/PromoModalsComponent'
import $$ from './campaign-page.module.css'
import { fetchCampaignData } from './CampaignPage.api'
import { CompletedPromosTable } from './CompletedPromosTable'
import { UpcomingAndLivePromosTable } from './UpcomingAndLivePromosTable'

interface ModalContextProps {
  modal: PromoModal | null
  setModal: (modal: PromoModal) => void
  currentPromo: TCreateAutoBoostNonprofitData | null
  setCurrentPromo: (promo: TCreateAutoBoostNonprofitData | null) => void
}

export const ModalContext = createContext<ModalContextProps>({
  modal: PromoModal.NoModal,
  setModal: () => null,
  currentPromo: null,
  setCurrentPromo: () => null,
})

export const CampaignsPage = () => {
  const user: TUser = useBeamSelector(({ user }) => user)
  const [campaignData, setCampaignData] = useState<CampaignPageBody | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [fetchError, setFetchError] = useState<string | null>(null)
  const [modal, setModal] = useState(PromoModal.NoModal)
  const [currentPromo, setCurrentPromo] = useState<TCreateAutoBoostNonprofitData | null>(null)
  const siteFilter: TSite | undefined = useBeamSelector(({ site }) => site)

  const loadListOfCampaigns = async () => {
    setFetchError(null)
    if (!user?.chainId) {
      return
    }

    try {
      const data = await fetchCampaignData(user.chainId)
      setCampaignData(data)
      setLoading(false)
    } catch (error: any) {
      setFetchError(error.message)
    }
  }

  useEffect(() => {
    if (!user?.chainId) return
    loadListOfCampaigns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.chainId])

  // Make sure the campaign dashboard is updated with the latest list of promos
  // each time the user make edits to a promo.
  useEffect(() => {
    if (!user?.chainId || modal !== PromoModal.NoModal) return
    loadListOfCampaigns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal])

  return (
    <ModalContext.Provider
      value={{
        modal,
        setModal,
        currentPromo,
        setCurrentPromo,
      }}>
      <BeamSEO title={`Boosted Campaigns`} />
      <main className={'beam--page-content--no-gutter'}>
        <div className={'bg-charcoal-50 flex justify-between items-center px-5'}>
          <BeamNonprofitPartnersPageNavigation promos={campaignData} site={siteFilter} />
        </div>

        <section className={'relative mx-5 my-5'}>
          {!!siteFilter?.storeId && (
            <p className={$$.campaignParagraph}>
              Campaigns listed here are not specific to the site selected.
            </p>
          )}

          {campaignData &&
            campaignData.currentPromosCount + campaignData.completedPromosCount === 0 && (
              <>
                <h2 className="col-span-8">Current Campaigns</h2>
                <BeamCTA
                  leftContent={
                    <>
                      <h3 className="my-1">Set Up Your First Boosted Impact Campaign</h3>
                      <p
                        className="beam--paragraph--small"
                        style={{ color: 'var(--beam-color--sky-800)' }}>
                        You have no Boosted Impact Campaigns scheduled yet!
                      </p>
                    </>
                  }
                  rightContent={
                    <>
                      <BeamButton
                        label={'Set Up First Boost'}
                        onClick={() => {
                          setCurrentPromo(null)
                          setModal(PromoModal.BoostANonprofit)
                        }}
                        variant={'emphasis_white'}
                        block={true}
                        size="small"
                      />
                    </>
                  }
                  backgroundColor="--beam-color--sky-50"
                  className={cx($$.textColor, 'pb-2 pt-1')}
                />
              </>
            )}

          {campaignData && campaignData.currentPromosCount > 0 && (
            <>
              <section>
                <div className="grid grid-cols-12">
                  <h2 className="col-span-8">Current Campaigns</h2>
                  {!siteFilter?.storeId && (
                    <BeamButton
                      label="Set Up New Boost"
                      variant="elevated"
                      className="col-span-4"
                      onClick={() => {
                        setCurrentPromo(null)
                        setModal(PromoModal.BoostANonprofit)
                      }}
                    />
                  )}
                </div>
              </section>

              <UpcomingAndLivePromosTable
                data={campaignData?.currentPromos}
                loading={loading}
                user={user}
              />
            </>
          )}
          {campaignData && campaignData.completedPromosCount > 0 && (
            <>
              <section>
                <div className="grid grid-cols-12">
                  <h2 className="col-span-8 mt-6">Completed Campaigns</h2>
                  {!siteFilter?.storeId && campaignData.currentPromosCount === 0 && (
                    <BeamButton
                      label="Set Up New Boost"
                      variant="elevated"
                      className="col-span-4"
                      onClick={() => {
                        setCurrentPromo(null)
                        setModal(PromoModal.BoostANonprofit)
                      }}
                    />
                  )}
                </div>
              </section>
              <CompletedPromosTable data={campaignData?.completedPromos} loading={loading} />
            </>
          )}
        </section>

        {/* Error Toast */}
        <BeamToast
          open={!!fetchError}
          text={fetchError || ''}
          variant={'error'}
          onClose={() => setFetchError(null)}
        />
      </main>

      <PromoModalsComponent activeModal={modal} currentPromo={currentPromo} />
    </ModalContext.Provider>
  )
}
