import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import { useBeamSelector } from '../../../hooks'
import { BeamButton } from '../../../stories/BeamButton'
import { BeamCard } from '../../../stories/BeamCard'
import { PartnerUploadBody, TUser } from '../../../utils/types'
import { BeamSEO } from '../../root/BeamSEO'
import { PageNotFound } from '../../root/PageNotFound'
import { fetchPartnerUploadById } from './PartnerUploadsPage.api'
import { handleClickDownloadPartnerUpload } from './PartnerUploadsPage.helpers'

export const SharedPartnerUploadPage = () => {
  const user = useBeamSelector(({ user }) => user) as TUser
  const { partnerUploadId }: any = useParams()
  const [partnerUpload, setPartnerUpload] = useState<PartnerUploadBody | null>(null)
  const [fetchError, setFetchError] = useState<string | null>(null)

  useEffect(() => {
    setFetchError(null)

    if (!user?.chainId || !partnerUploadId) return

    fetchPartnerUploadById(user.chainId, partnerUploadId)
      .then(partnerUploadResponse => {
        setPartnerUpload(partnerUploadResponse)
      })
      .catch((error: AxiosError) => {
        const errorCode = error.response?.data?.code
        let errorMessage = error.message

        if (errorCode === 'PartnerUploadValidationError') {
          errorMessage = 'File not found or invalid.'
        }

        console.error(error)
        setFetchError(errorMessage || error.message)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!partnerUpload || !!partnerUpload?.deletedAt) {
    return <PageNotFound />
  }

  return (
    <>
      <BeamSEO title={'Download Report'} />

      <main className={'pt-14 desktop:pr-20'}>
        <h1 className={'mb-3'}>Downloadable Report</h1>
        <p>
          You have been sent a report regarding {user.partner.name}&apos;s partnership with Beam to
          view.
        </p>

        <div>
          {!partnerUpload || fetchError ? (
            <h2>{fetchError}</h2>
          ) : (
            <div className={'pt-16'}>
              <BeamCard className={'bg-sun-50'} excludeBorder>
                <div
                  className={
                    'flex flex-col desktop:flex-row desktop:justify-between desktop:items-center'
                  }>
                  <div className={'pb-5 desktop:w-1/2 desktop:pb-0'}>
                    <h3 className={'my-0'}>{partnerUpload?.fileName}</h3>
                    <p className={'text-sm'}>
                      If you have any questions or need assistance downloading the file, please
                      reach out to your Beam Client Strategy Lead.
                    </p>
                  </div>
                  <div className={'desktop:pl-28 desktop:w-1/2'}>
                    <BeamButton
                      label={'Download Report'}
                      variant={'elevated'}
                      onClick={() => {
                        if (!user.chainId) return

                        console.log('CLICKED!')
                        handleClickDownloadPartnerUpload({
                          chainId: user.chainId,
                          partnerUploadId,
                          fileName: partnerUpload?.fileName,
                        })
                      }}
                      disabled={!partnerUpload}
                    />
                  </div>
                </div>
              </BeamCard>
            </div>
          )}
        </div>
      </main>
    </>
  )
}
