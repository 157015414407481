import {
  CBPartnerInfo,
  NonprofitOption,
  StoreInfo,
  StoreNonProfitOptions,
} from './CreateCentralBackendPartner'

export const mappingStoreData = (partner: CBPartnerInfo) => {
  if (!partner.name || !partner?.stores?.length) return null

  const stores: StoreInfo[] = partner.stores.map(store => {
    let nonprofitIds: NonprofitOption[] = []

    if (store.nonprofits && store.nonprofits.filter((np: StoreNonProfitOptions) => np).length) {
      nonprofitIds = store.nonprofits.map((np: StoreNonProfitOptions) => ({
        value: np?.id,
        display: np?.name,
      }))
    }

    return {
      active: store.active,
      id: store.id,
      name: store.name,
      displayName: store.displayName,
      nonprofitIds,
      type: store.type,
      zipCodes: store.regionZipCodes || [],
    }
  })

  return stores
}
