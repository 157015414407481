/* eslint-disable prefer-rest-params */
import SlSelectElement from '@shoelace-style/shoelace/dist/components/select/select'
import { SlButton, SlDropdown, SlMenu, SlMenuItem } from '@shoelace-style/shoelace/dist/react'
import cx from 'classnames'
import { forwardRef } from 'react'

import { BeamIcon } from '../BeamIcon/BeamIcon'
import { BeamDSProps } from '../interface'
import $$ from './beam-edit-delete.module.css'

interface BeamEditOrDeleteButton extends BeamDSProps {
  disabled?: boolean
  selectHandler?: (event: any) => void
}

type ShoelaceDropDownEvent = {
  detail: {
    item: {
      value: string
    }
  }
}

/**
 * A button or editing or deleting an item
 */
export const BeamEditOrDeleteButton = forwardRef<SlSelectElement, BeamEditOrDeleteButton>(
  function BeamEditOrDeleteButton({ disabled = false, selectHandler }, ref) {
    function handleSelectOnAnyElement(event: ShoelaceDropDownEvent) {
      if (selectHandler) {
        selectHandler(event.detail.item.value)
      }
    }
    return (
      <SlDropdown stayOpenOnSelect={true} disabled={disabled} hoist>
        <SlButton slot="trigger" className={$$['edit-delete-dropdown-button']}>
          <BeamIcon variant="pencil" disabled={disabled} />
        </SlButton>
        <SlMenu onSlSelect={handleSelectOnAnyElement} className={$$['edit-delete-dropdown']}>
          <SlMenuItem value="edit" className={cx($$['edit-delete-menu-item'])}>
            <BeamIcon variant="pencil" /> Edit
          </SlMenuItem>
          <SlMenuItem
            value="delete"
            className={cx($$['edit-delete-menu-item'], $$['delete-menu-item'])}>
            <BeamIcon variant="trashcan" /> Delete
          </SlMenuItem>
        </SlMenu>
      </SlDropdown>
    )
  }
)
