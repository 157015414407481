import { SlCard } from '@shoelace-style/shoelace/dist/react'
import cx from 'classnames'

import { BeamDSProps } from '../interface'
import $$ from './beam-card.module.css'

interface BeamCardProps extends BeamDSProps {
  /**
   * Content for card
   */
  children: string | React.ReactNode
  /**
   * Background color of the card. Potentials are based on colors defined in the branding guidelines (in other words, no custom hex values or RGBs)
   */
  backgroundColor?: string
  /**
   * Background pattern for the card. If set to 'striped', it uses the given "backgroundColor" for the color of the stripes.
   * @default solid
   */
  backgroundPattern?: 'solid' | 'striped'
  /**
   * Grey borders are included by default. Set this to true to exclude it on the card.
   */
  excludeBorder?: boolean
}

/**
 * Card component
 */
export const BeamCard = ({
  children,
  backgroundColor,
  excludeBorder = false,
  backgroundPattern = 'solid',
  ...props
}: BeamCardProps) => {
  let styles = {}
  let border = ''
  const usesStripedBackground = backgroundPattern === 'striped'

  if (backgroundColor && usesStripedBackground) {
    const stripedPattern = `repeating-linear-gradient(-56deg, var(${backgroundColor}), var(${backgroundColor}) 1px, var(--beam-color--charcoal-50) 2px, var(--beam-color--charcoal-50) 7px)`
    styles = { background: stripedPattern }
  } else if (backgroundColor) {
    styles = { backgroundColor: `var(${backgroundColor})` }
  }

  if (excludeBorder) {
    border = 'beam--card--hide-border'
  }

  return (
    <SlCard
      {...props}
      className={cx($$.card, props.className, 'beam--card', border)}
      style={styles}>
      {children}
    </SlCard>
  )
}
