import './beam-textarea.css'

import SlTextareaElement from '@shoelace-style/shoelace/dist/components/textarea/textarea'
import { SlTextarea } from '@shoelace-style/shoelace/dist/react'
import { forwardRef } from 'react'

interface TextareaProps {
  /**
   * Text inside text area box
   */
  placeholder?: string
  /**
   * Text above text area box
   */
  label?: string
  /**
   * Name
   */
  name: string
  /**
   * className
   */
  className?: string
  /**
   * onChange handler; Triggers whenever the field value has changed (on keypress).
   */
  onChange?: (e: Event) => void
  /**
   * The text value that shows up in the field
   */
  value?: string
}

/**
 * Primary UI component for user interaction
 */
export const BeamTextarea = forwardRef<SlTextareaElement, TextareaProps>(function BeamTextarea(
  { placeholder, label, name, className, value = undefined, onChange },
  ref
) {
  const optionalProps: { value?: string | undefined } = {}
  if (value !== undefined) {
    optionalProps.value = value
  }

  return (
    <SlTextarea
      className={`beam--textarea ${className}`}
      name={name}
      label={label}
      placeholder={placeholder}
      ref={ref}
      onSlInput={onChange}
      {...optionalProps}
    />
  )
})
