import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { REDESIGN_ROOT_PATH } from '../../../helpers'
import { associateYotpoAccountWithPartner } from './YotpoOathCallbackPage.api'

const YOTPO_OAUTH_PARAM_KEY = 'code'
const YOTPO_OAUTH_APP_KEY_KEY = 'app_key'
const loadingStr = 'Linking your Yotpo organization. Do not navigate away from this page.'
const successStr = 'Successfully linked your Yotpo organization 🎉. You may now close this page.'

async function getYotpoOauthCodeAndAssociateWithPartner() {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const codeParam = urlParams.get(YOTPO_OAUTH_PARAM_KEY)
  const appKeyParam = urlParams.get(YOTPO_OAUTH_APP_KEY_KEY)
  // clear out code to prevent user from refreshing/sending multiple requests
  window.history.replaceState('', '', '/')
  await associateYotpoAccountWithPartner({ code: codeParam, appKey: appKeyParam })
}

export const YotpoOauthCallbackPage = () => {
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory()

  useEffect(() => {
    const init = async () => {
      try {
        await getYotpoOauthCodeAndAssociateWithPartner()
        setIsLoading(false)
      } catch (e) {
        history.replace(`${REDESIGN_ROOT_PATH}/yotpo/error`)
      }
    }
    init()
  }, [history])

  const headerStr = isLoading ? loadingStr : successStr
  return (
    <div className="pt-6">
      <h2>{headerStr}</h2>
    </div>
  )
}
