import {
  ADD_NONPROFIT,
  SET_ACCESS_TOKEN,
  SET_ALL_PARTNERS,
  SET_API_KEYS,
  SET_CAUSES,
  SET_CB_PARTNER,
  SET_DISPLAY_LIST_OF_SITES,
  SET_EXTERNAL_PARTNERS,
  SET_IMPACT,
  SET_INVOICES,
  SET_LOADING_ALL_PARTNERS,
  SET_LOADING_API_KEYS,
  SET_LOADING_CAUSES,
  SET_LOADING_CB_PARTNER,
  SET_LOADING_IMPACT,
  SET_LOADING_INVOICES,
  SET_LOADING_NONPROFIT,
  SET_LOADING_NONPROFITS,
  SET_LOADING_PARTNER,
  SET_LOADING_PARTNERS,
  SET_LOADING_PAYMENT_STRUCTURES,
  SET_LOADING_REPORT,
  SET_LOADING_SITES,
  SET_LOADING_SUPPORT_EMAIL,
  SET_LOADING_USER,
  SET_NEW_API_KEY,
  SET_NONPROFIT,
  SET_NONPROFITS,
  SET_PARTNER,
  SET_PARTNERS,
  SET_PAYMENT_STRUCTURES,
  SET_REPORT_V2,
  SET_SITE,
  SET_SITES,
  SET_TOAST,
  SET_USER,
} from './constants'

export const accessToken = (state = '', action) => {
  switch (action.type) {
    case SET_ACCESS_TOKEN:
      return action.value
    default:
      return state
  }
}

export const user = (state = {}, action) => {
  switch (action.type) {
    case SET_USER:
      return action.user
    default:
      return state
  }
}

/**
 * @param {TSite|null} state - The new site
 * @param {Object} action - Action type.
 *  @param {string} action.type - Action type.
 *  @param {TSite|null} action.site - The new site
 */
export const site = (
  state = {
    name: '',
    storeId: null,
  },
  action
) => {
  switch (action.type) {
    case SET_SITE:
      return action.site
    default:
      return state
  }
}

export const sites = (state = {}, action) => {
  switch (action.type) {
    case SET_SITES:
      return action.sites || []
    default:
      return state || []
  }
}

export const displayListOfSites = (state = false, action) => {
  switch (action.type) {
    case SET_DISPLAY_LIST_OF_SITES:
      return !!action.value
    default:
      return state
  }
}

export const nonprofits = (state = [], action) => {
  switch (action.type) {
    case SET_NONPROFITS:
      return action.nonprofits
    case ADD_NONPROFIT:
      return [...state, action.nonprofit]
    default:
      return state
  }
}

export const causes = (state = [], action) => {
  switch (action.type) {
    case SET_CAUSES:
      return action.causes
    default:
      return state
  }
}

export const partners = (state = [], action) => {
  switch (action.type) {
    case SET_PARTNERS:
      return action.partners
    default:
      return state
  }
}

export const allPartners = (state = [], action) => {
  switch (action.type) {
    case SET_ALL_PARTNERS:
      return action.allPartners
    default:
      return state
  }
}

export const externalPartners = (state = [], action) => {
  switch (action.type) {
    case SET_EXTERNAL_PARTNERS:
      return action.externalPartners
    default:
      return state
  }
}

/**
 * @returns {import('../utils/types').TPPPartner}
 */
export const partner = (state = {}, action) => {
  switch (action.type) {
    case SET_PARTNER:
      return action.partner
    default:
      return state
  }
}

// TODO: Assess if cbPartner needs to be in global state. This could be in component state.
export const cbPartner = (state = {}, action) => {
  switch (action.type) {
    case SET_CB_PARTNER:
      return action.cbPartner
    default:
      return state
  }
}

export const nonprofit = (state = {}, action) => {
  switch (action.type) {
    case SET_NONPROFIT:
      return action.nonprofit
    default:
      return state
  }
}

export const loadingStates = (state = {}, action) => {
  switch (action.type) {
    case SET_LOADING_USER:
      return { ...state, user: action.value }
    case SET_LOADING_NONPROFITS:
      return { ...state, nonprofits: action.value }
    case SET_LOADING_PARTNERS:
      return { ...state, partners: action.value }
    case SET_LOADING_PARTNER:
      return { ...state, partner: action.value }
    case SET_LOADING_ALL_PARTNERS:
      return { ...state, allPartners: action.value }
    case SET_LOADING_CB_PARTNER:
      return { ...state, cbPartner: action.value }
    case SET_LOADING_NONPROFIT:
      return { ...state, nonprofit: action.value }
    case SET_LOADING_SUPPORT_EMAIL:
      return { ...state, supportEmail: action.value }
    case SET_LOADING_CAUSES:
      return { ...state, causes: action.value }
    case SET_LOADING_INVOICES:
      return { ...state, invoices: action.value }
    case SET_LOADING_REPORT:
      return { ...state, reports: action.value }
    case SET_LOADING_IMPACT:
      return { ...state, impact: action.value }
    case SET_LOADING_PAYMENT_STRUCTURES:
      return { ...state, paymentStructures: action.value }
    case SET_LOADING_API_KEYS:
      return { ...state, apiKeys: { ...state.apiKeys, ...action.value } }
    case SET_LOADING_SITES:
      return { ...state, sites: { ...state.sites, ...action.value } }
    default:
      return state
  }
}

export const invoices = (state = [], action) => {
  switch (action.type) {
    case SET_INVOICES:
      return action.invoices
    default:
      return state
  }
}

/*
 example return type:
 {
   123: {
     monthlyCalendar: { ... },
     monthlyInterval: { ... },
     quarterlyCalendar: { ... },
     quarterlyInterval: { ... },
     yearlyCalendar: { ... },
     yearlyInterval: { ... },
   },
 }
*/
export const reportsV2 = (state = {}, action) => {
  switch (action.type) {
    case SET_REPORT_V2:
      return {
        ...state,
        [action.partnerId]: {
          ...state[action.partnerId],
          [action.reportType]: {
            ...state[action.reportType],
            [action.index]: action.report,
          },
        },
      }
    default:
      return state
  }
}

export const impact = (state = {}, action) => {
  switch (action.type) {
    case SET_IMPACT:
      return action.impact
    default:
      return state
  }
}

export const toasts = (state = {}, action) => {
  switch (action.type) {
    case SET_TOAST:
      return action.toast
    default:
      return state
  }
}

export const paymentStructures = (state = [], action) => {
  switch (action.type) {
    case SET_PAYMENT_STRUCTURES:
      return action.paymentStructures
    default:
      return state
  }
}

export const apiKeys = (state = [], action) => {
  switch (action.type) {
    case SET_API_KEYS:
      return action.apiKeys
    default:
      return state
  }
}

export const newApiKey = (state = '', action) => {
  switch (action.type) {
    case SET_NEW_API_KEY:
      return action.newApiKey
    default:
      return state
  }
}
