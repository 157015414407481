import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { REDESIGN_ROOT_PATH } from '../../../helpers'
import { BeamButton } from '../../../stories/BeamButton'
import { BeamModal } from '../../../stories/BeamModal'
import { BeamTextfield } from '../../../stories/BeamTextfield'
import { isValidEmail, validateManyEmails } from '../../../utils/helpers/emailValidator'
import $$ from './payments-modals.module.css'
import { updateInvoiceEmailAddress } from './PaymentsEmailsModal.api'
import { PaymentsSetupModalsContext, PaymentsSetupModalsEnums } from './PaymentsSetupModals'

interface PaymentsEmailsModalProps {
  open: boolean
  afterCloseHandler: (closedModal: PaymentsSetupModalsEnums | null) => void
  originalInvoiceEmails?: {
    invoiceEmail: string | null
    invoiceCcEmails: string[]
  }
  isPaypalSetup: boolean
  updateInvoiceEmailAddressMethod?: typeof updateInvoiceEmailAddress
  partnerId: number
  profilePageToastHandler?: (errorMessage?: string) => void
}

/**
 * Modal displayed before the payment details modal with a summary of how auto pay works.
 */
export const PaymentsEmailsModal = ({
  open,
  afterCloseHandler,
  originalInvoiceEmails,
  isPaypalSetup,
  updateInvoiceEmailAddressMethod = updateInvoiceEmailAddress,
  partnerId,
  profilePageToastHandler,
}: PaymentsEmailsModalProps) => {
  const history = useHistory()
  const context = useContext(PaymentsSetupModalsContext)
  const isOnProfilePage = history.location.pathname.includes('profile')

  const [invoiceEmail, setInvoiceEmail] = useState<string>('')
  const [invoiceCcEmails, setInvoiceCcEmails] = useState<string[]>([])
  const [buttonAttributes, setButtonAttributes] = useState({
    elevatedButtonLabel: '',
    elevatedButtonOnClick: (e: any) => e,
    cancelButtonLabel: '',
    cancelButtonOnClick: (e: any) => e.target.value,
  })
  const [emailError, setEmailError] = useState(false)
  const [emailCcErrors, setEmailCcErrors] = useState<{ index: number; error: string }[]>([])

  // set emails on load
  useEffect(() => {
    setInvoiceEmail(originalInvoiceEmails?.invoiceEmail || '')
    setInvoiceCcEmails(originalInvoiceEmails?.invoiceCcEmails || [])
  }, [originalInvoiceEmails?.invoiceCcEmails, originalInvoiceEmails?.invoiceEmail])

  // Updates the button configurations based on state
  useEffect(() => {
    if (isOnProfilePage) {
      // IF ON PROFILE PAGE
      setButtonAttributes({
        elevatedButtonLabel: 'Save Changes',
        elevatedButtonOnClick: async () => {
          const foundErrors = validateEmails(invoiceEmail, invoiceCcEmails)
          if (foundErrors) return

          let responseError: string | undefined = undefined
          try {
            await updateInvoiceEmailAddressMethod(partnerId, {
              invoiceEmail,
              invoiceCcEmails,
            })
          } catch (error: any) {
            responseError = error.message
            console.error('Error fetching overview data:', error)
          }

          if (profilePageToastHandler) profilePageToastHandler(responseError)
        },
        cancelButtonLabel: 'Cancel',
        cancelButtonOnClick: () => {
          setInvoiceEmail(originalInvoiceEmails?.invoiceEmail || '')
          setInvoiceCcEmails(originalInvoiceEmails?.invoiceCcEmails || [])
        },
      })
    } else if (!isPaypalSetup) {
      // IF PAYPAL/AUTOMATED DONATIONS IS NOT SET UP
      setButtonAttributes({
        elevatedButtonLabel: 'Next, Sep Up Autopay for Donations',
        elevatedButtonOnClick: async () => {
          // first validate email
          const foundErrors = validateEmails(invoiceEmail, invoiceCcEmails)
          if (foundErrors) return

          // then update emails
          let responseError: string | undefined = undefined
          try {
            await updateInvoiceEmailAddressMethod(partnerId, {
              invoiceEmail,
              invoiceCcEmails,
            })
          } catch (error: any) {
            responseError = error.message
            console.error('Error fetching overview data:', error)
          }

          if (profilePageToastHandler) profilePageToastHandler(responseError)

          // finally, switch modal
          context.changeActiveModal(PaymentsSetupModalsEnums.setupPpgfAutoPay)
        },
        cancelButtonLabel: 'Skip for Now',
        cancelButtonOnClick: () => context.changeActiveModal(null),
      })
    } else if (isPaypalSetup) {
      // IF AUTOMATED DONATIONS IS SET UP
      setButtonAttributes({
        elevatedButtonLabel: 'Go to My Invoices',
        elevatedButtonOnClick: () => {
          resetErrors()
          history.push(`${REDESIGN_ROOT_PATH}/invoices`)
        },
        cancelButtonLabel: '',
        cancelButtonOnClick: (e: any) => e.target.value,
      })
    }
  }, [
    context,
    history,
    invoiceCcEmails,
    invoiceEmail,
    isOnProfilePage,
    isPaypalSetup,
    originalInvoiceEmails?.invoiceCcEmails,
    originalInvoiceEmails?.invoiceEmail,
    partnerId,
    profilePageToastHandler,
    updateInvoiceEmailAddressMethod,
  ])

  function handleAddAnotherEmail() {
    const newInvoiceCcEmails = [...invoiceCcEmails, '']
    setInvoiceCcEmails(newInvoiceCcEmails)
  }

  function handleRemoveCcEmail(index: number) {
    const newInvoiceCcEmails = [...invoiceCcEmails]
    newInvoiceCcEmails.splice(index, 1)
    setInvoiceCcEmails(newInvoiceCcEmails)
  }

  function handleCcEmailInput(e: React.ChangeEvent<HTMLInputElement>) {
    const target = e.target as HTMLInputElement
    if (!target?.name) return

    const index = Number(target.name.split('-')[1])

    if (!isNaN(index) && index >= 0) {
      const newCcEmails = [...invoiceCcEmails]
      newCcEmails[index] = target.value
      setInvoiceCcEmails(newCcEmails)
    }
  }

  function resetErrors() {
    setEmailError(false)
    setEmailCcErrors([])
  }

  /**
   * validates emails and updates error states.
   * @returns boolean (since state updates are async)
   */
  function validateEmails(invoiceEmail: string, invoiceCcEmails: string[]): boolean {
    let errorsFound = false

    const emailIsValid = isValidEmail(invoiceEmail)
    const ccEmailErrors = validateManyEmails(invoiceCcEmails)

    if (!emailIsValid) {
      errorsFound = true
      setEmailError(true)
    }
    if (ccEmailErrors.length > 0) {
      console.log({ ccEmailErrors })

      errorsFound = true
      setEmailCcErrors(ccEmailErrors)
    }

    return errorsFound
  }

  return (
    <BeamModal
      label={isOnProfilePage ? 'Update Billing Details' : 'Autopay for Beam fees is Set Up'}
      open={open}
      onCloseCallback={() => {
        afterCloseHandler(PaymentsSetupModalsEnums.collectEmail)
        resetErrors()

        if (isOnProfilePage) {
          setInvoiceEmail(originalInvoiceEmails?.invoiceEmail || '')
          setInvoiceCcEmails(originalInvoiceEmails?.invoiceCcEmails || [])
        }
      }}
      body={
        <div>
          <div>
            <p
              className={`mb-5 text-base text-center font-secondary text-light-grey-1 ${$$.modalSubheader}`}>
              {isOnProfilePage
                ? 'Update the email address invoices are sent to on the each month'
                : 'Thank you for setting up Autopay for Beam fees! Edit your details from the Accounts page at any time'}
            </p>
            <div>
              <h3 className="mb-1">Date of payments</h3>
              <strong>9th of the month</strong>
              <p className="font-secondary text-[12px] leading-4">
                Payments will be processed on the 10th or 11th when the usual invoicing date falls
                on a weekend
              </p>
            </div>
            <div>
              <h3 className="mb-1">Frequency of payments</h3>
              <strong>Monthly</strong>
            </div>
            <div className="relative grid mt-2 ">
              <span
                className="absolute justify-self-end text-[10px] text-sky-500 underline hover:cursor-pointer"
                onClick={handleAddAnotherEmail}>
                Add Another Email
              </span>
              <BeamTextfield
                name="invoiceEmail"
                label="Invoice Email"
                variant="small"
                className={emailError ? 'email-error' : undefined}
                onChange={e => setInvoiceEmail(e.target.value)}
                helptext={emailError ? 'Invalid Email. Please enter a valid email address.' : ''}
                value={invoiceEmail}
              />
              {!isOnProfilePage && (
                <p className="font-secondary text-[12px] leading-4">
                  This is the email address that currently receives invoices from Beam
                </p>
              )}
            </div>
          </div>
          {invoiceCcEmails.length > 0 && (
            <div>
              <h3>Additional Emails</h3>
              <div className="space-y-5">
                {invoiceCcEmails.map((ccEmail, i) => {
                  const validationError = emailCcErrors.find(err => err.index === i)

                  return (
                    <div className="relative flex flex-row items-center w-full mt-4" key={i}>
                      <div className="flex-grow">
                        <BeamTextfield
                          name={`invoiceCcEmail-${i}`}
                          label=""
                          emptyLabel
                          variant="small"
                          className={validationError ? $$.emailError : undefined}
                          onChange={handleCcEmailInput}
                          helptext={validationError?.error ?? undefined}
                          value={ccEmail}
                        />
                      </div>
                      <span
                        className="ml-2 justify-self-end text-[10px] text-sky-500 underline hover:cursor-pointer"
                        onClick={() => handleRemoveCcEmail(i)}>
                        Remove Email
                      </span>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
          {/*<div className="mt-8">*/}
          {/*  <BeamButton*/}
          {/*    label={buttonAttributes.elevatedButtonLabel}*/}
          {/*    variant="elevated"*/}
          {/*    onClick={() => buttonAttributes.elevatedButtonOnClick(null)}*/}
          {/*    className="mb-6"*/}
          {/*  />*/}
          {/*</div>*/}

          {/*<p className="font-secondary text-[12px] leading-4 text-center">*/}
          {/*  We use a separate system for Autopay for Donations as the best way to get donations from*/}
          {/*  you to each nonprofit in compliance with applicable donation regulations is via our*/}
          {/*  partner, PayPal Giving Fund*/}
          {/*</p>*/}
        </div>
      }
      footer={
        <div slot={'footer'} className={''}>
          <div className="mt-8">
            <BeamButton
              label={buttonAttributes.elevatedButtonLabel}
              variant="elevated"
              onClick={() => buttonAttributes.elevatedButtonOnClick(null)}
              className="mb-6"
            />
          </div>

          <p className="font-secondary text-[12px] leading-4 text-center">
            We use a separate system for Autopay for Donations as the best way to get donations from
            you to each nonprofit in compliance with applicable donation regulations is via our
            partner, PayPal Giving Fund
          </p>
        </div>
      }
    />
  )
}
