import cx from 'classnames'

import { BeamCard } from '../BeamCard'
import { BeamDSProps } from '../interface'
import $$ from './beam-cta.module.css'

interface BeamCTAProps extends BeamDSProps {
  /**
   * Icon to display to the left
   */
  icon?: React.ReactNode
  /**
   * Left Content - DOM elements that appear on the left/top of the CTA
   */
  leftContent: string | React.ReactNode
  /**
   * Right Content - DOM elements that appear on the right/bottom of the CTA
   */
  rightContent: string | React.ReactNode
  /**
   * Background Color
   */
  backgroundColor?: string
  /**
   * The button's click behavior
   */
  onClick?: () => void
}

/**
 * Call to Action component
 */
export const BeamCTA = ({
  leftContent,
  rightContent,
  icon = '',
  backgroundColor = '--beam-color--sun-100',
  onClick,
  ...props
}: BeamCTAProps) => {
  return (
    <BeamCard
      {...props}
      excludeBorder
      className={cx($$.cta, props.className)}
      backgroundColor={backgroundColor}>
      <div className={$$.row}>
        {icon && <div className={$$.icon}>{icon}</div>}

        <div className={$$.left}>{leftContent}</div>
        <div className={$$.right}>{rightContent}</div>
      </div>
    </BeamCard>
  )
}
