import './beam-copylink.css'

import { useState } from 'react'

import { BeamIcon } from '../BeamIcon'

export const BeamCopyLink = () => {
  const [linkCopied, setLinkCopied] = useState(false)
  const [linkCopiedError, setLinkCopiedError] = useState(false)
  const [linkCopiedInfo, setLinkCopiedInfo] = useState(false)

  function handleClick() {
    setLinkCopied(false)
    setLinkCopiedError(false)
    setLinkCopiedInfo(false)
    const url = document.location.href
    if (url.includes('?ppgf_modal')) {
      setLinkCopiedInfo(true)
      return
    }
    navigator.clipboard.writeText(`${url}?ppgf_modal=true`).then(
      () => {
        setLinkCopied(true)
      },
      error => {
        console.error(error)
        setLinkCopiedError(true)
      }
    )
  }

  return (
    <>
      <span
        className={'text-base font-secondary link link-icon'}
        title="Copy autopay donation link for partners"
        onClick={handleClick}>
        {' '}
        <BeamIcon variant="link" />
      </span>
      <span className={'text-base font-secondary'}>
        {linkCopied && <span className="text-moss-500"> Link successfully copied!</span>}
        {linkCopiedInfo && <span className="text-charcoal-500"> Link already used!</span>}
        {linkCopiedError && <span className="text-cherry-500"> Link unsuccesfully copied!</span>}
      </span>
    </>
  )
}
