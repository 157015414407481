import { API_BASE_PATH_PORTAL } from '../../../api/auth'
import { axiosRequest } from '../../../utils/axiosRequest'

export interface YotpoIntegration {
  id: string
  yotpoStoreId: string
}

export const fetchYotpoIntegrationsByChainId = async (
  chainId: number
): Promise<YotpoIntegration[]> => {
  const response = await axiosRequest(
    'post',
    `${API_BASE_PATH_PORTAL}/yotpo/find-yotpo-merchants-by-chain-id`,
    { chainId }
  )
  return response.data.results
}

export const disableYotpoIntegration = async (
  yotpoMerchantInternalId: string
): Promise<boolean> => {
  try {
    const response = await axiosRequest(
      'post',
      `${API_BASE_PATH_PORTAL}/yotpo/delete-yotpo-merchant`,
      {
        yotpoMerchantInternalId,
      }
    )
    if (response.status === 200) {
      return true
    } else {
      console.error('Failed to disable Yotpo integration:', response.statusText)
      return false
    }
  } catch (error) {
    console.error('Error disabling Yotpo integration:', error)
    return false
  }
}
