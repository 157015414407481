import '../../style/partner/invoices.css'

import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createCentralBackendPartner } from '../../redux/thunks/adminThunks'
import { processApiError } from '../../utils/root'
import BeamButton from '../root/BeamButton'
import { BeamModal } from '../root/BeamModal'
import { BeamProgressBar } from '../root/BeamProgressBar'
import { PageNotFound } from '../root/PageNotFound'
import { EditCBPartnerForm, formatData } from './EditCBPartnerForm'
import EditPortalPartnerForm from './EditPortalPartnerForm'

export type NonprofitOption = {
  value: number
  display: string
}

export type AggregateTargetChainNonprofitInfo = {
  chainNonprofitId?: string | number
  active: boolean
  causeDisplayIcon?: any
  causeDisplayName?: string
  causeSelectedImage?: any
  chainImpactImage?: any
  chainImpactDescription?: string
  filter?: string | string[]
  name: string
  nonprofitId: number
  sortOrder?: number
  targetChainDonationAmount?: number
  targetUserDonationAmount?: number
  userImpactDescription?: string
}

export type StoreNonProfitOptions = {
  id: number
  name: string
}

export type StoreInfo = {
  name?: string
  displayName?: string
  zipcodes?: string
  nonprofitIds: Array<NonprofitOption>
  type?: number
  id?: number
  active?: boolean
  regionZipCodes?: string[]
  nonprofits?: StoreNonProfitOptions[]
}

export type CBPartnerInfo = {
  id?: string
  name: string
  logoImage?: any
  communityImpactLink?: string
  isPercentBased?: boolean
  isInKind?: boolean
  donationPercentage?: number
  donationAmount?: number
  useRegionalization: boolean
  targetChainNonprofitInfo: Array<AggregateTargetChainNonprofitInfo>
  stores: Array<StoreInfo>
}

export type PortalPartner = {
  launchDate?: string
  invoiceFirstName: string
  invoiceLastName: string
  invoiceEmail: string
  paymentStructureId?: number
  invoiceMinimum: number
}

const CreateCentralBackendPartner = () => {
  const defaultStore: StoreInfo = {
    name: 'Default Store',
    type: 3,
    zipcodes: '',
    nonprofitIds: [],
  }

  const defaultCBPartner: CBPartnerInfo = {
    name: '',
    logoImage: undefined,
    communityImpactLink: '',
    isPercentBased: true,
    isInKind: false,
    donationPercentage: 0.01,
    donationAmount: 1,
    useRegionalization: false,
    targetChainNonprofitInfo: [],
    stores: [defaultStore],
  }

  const defaultPortalPartner: PortalPartner = {
    invoiceFirstName: '',
    invoiceLastName: '',
    invoiceEmail: '',
    invoiceMinimum: 0,
  }

  const [cbPartnerData, setCBPartnerData] = useState<CBPartnerInfo>(defaultCBPartner)
  const [portalPartnerData, setPortalPartnerData] = useState<PortalPartner>(defaultPortalPartner)
  const [showModal, setShowModal] = useState<boolean>(false)

  const dispatch = useDispatch()

  const { loadingStates, user }: { loadingStates: any; user: any } = useSelector((state: any) => ({
    loadingStates: state.loadingStates,
    user: state.user,
  }))

  const submitData = () => {
    const newCBPartnerData = formatData(cbPartnerData)
    const newPartner = {
      name: newCBPartnerData.name,
      logoImage: newCBPartnerData.logoImage,
      communityImpactLink: newCBPartnerData.communityImpactLink,
      isPercentBased: newCBPartnerData.isPercentBased,
      isInKind: newCBPartnerData.isInKind,
      donationPercentage: newCBPartnerData.donationPercentage,
      donationAmount: newCBPartnerData.donationAmount,
      useRegionalization: newCBPartnerData.useRegionalization,
      targetChainNonprofitInfo: newCBPartnerData.targetChainNonprofitInfo,
      stores: newCBPartnerData.stores,
      launchDate: portalPartnerData.launchDate,
      invoiceFirstName: portalPartnerData.invoiceFirstName,
      invoiceLastName: portalPartnerData.invoiceLastName,
      invoiceEmail: portalPartnerData.invoiceEmail,
      paymentStructureId: portalPartnerData.paymentStructureId
        ? +portalPartnerData.paymentStructureId
        : undefined,
      invoiceMinimum: portalPartnerData.invoiceMinimum,
    }
    dispatch(createCentralBackendPartner(newPartner))
  }

  if (['Super', 'Admin'].indexOf(user.type) == -1) return <PageNotFound />

  const isLoadingCBPartner = loadingStates?.cbPartner?.loading
  const apiError = loadingStates?.cbPartner?.error
  return (
    <div>
      <h1>Create Partner</h1>
      <EditCBPartnerForm
        formTitle="Central Backend Info:"
        partnerData={cbPartnerData}
        setPartnerData={setCBPartnerData}
        showSubmit={false}
      />
      <EditPortalPartnerForm
        formTitle="Partner Portal Info:"
        partnerData={portalPartnerData}
        setPartnerData={setPortalPartnerData}
        showSubmit={false}
      />
      <div>
        <BeamButton
          text="SUBMIT"
          handler={() => {
            setShowModal(true)
            submitData()
          }}
        />
      </div>
      <BeamModal
        title={isLoadingCBPartner ? 'Processing...' : apiError ? 'Error' : 'Success!'}
        titleColor={isLoadingCBPartner || apiError ? '#000' : '#4caf50'}
        open={showModal}
        handleClose={() => setShowModal(false)}>
        <div>
          {apiError ? (
            processApiError(apiError)
          ) : isLoadingCBPartner ? (
            <BeamProgressBar />
          ) : (
            <span>&#127881;</span>
          )}
        </div>
      </BeamModal>
    </div>
  )
}

export default CreateCentralBackendPartner
