import { API_BASE_PATH_PORTAL } from '../../../api/auth'
import { axiosRequest } from '../../../utils/axiosRequest'
import { CustomErrorClass } from '../../../utils/CustomErrorClass'
import { TUser } from '../../../utils/types'
import { CustomError } from '../../../utils/types/CustomError'
import {
  APIAutoBoostRequest,
  TCreateAutoBoostNonprofitData,
} from './AutoBoostANonprofitModal.types'

const validateMultiplier = (multiplier: string | number): number | undefined => {
  const parsedMultiplier = Number(multiplier)
  if (isNaN(parsedMultiplier)) {
    throw new Error('Invalid multiplier')
  }
  return parsedMultiplier
}

const validateAutoBoostRequestData = (requestData: TCreateAutoBoostNonprofitData, user: TUser) => {
  if (!user || user.chainId == null) {
    throw new Error('Invalid user info')
  }

  const { boostedStoreNonprofitIds } = requestData
  const sanitizedBoostedStoreNonprofitIds =
    boostedStoreNonprofitIds?.filter(id => !isNaN(Number(id))).map(Number) || []

  if (sanitizedBoostedStoreNonprofitIds.length !== boostedStoreNonprofitIds?.length) {
    throw new Error('Invalid boosted nonprofit Ids')
  }

  return sanitizedBoostedStoreNonprofitIds
}

export const createAutoBoost = async ({
  requestData,
  promoText,
  user,
  partnerId,
}: APIAutoBoostRequest) => {
  const { startTime, endTime, multiplier, color } = requestData

  const sanitizedBoostedStoreNonprofitIds = validateAutoBoostRequestData(requestData, user)
  const validatedMultiplier = multiplier ? validateMultiplier(multiplier) : undefined

  const requestDataToSend = {
    promoText,
    color,
    partnerId,
    name: requestData.name,
    sdkId: user.chainId,
    availableToWholeChain: false,
    boostAllNonprofits: false,
    boostedStoreNonprofitIds: sanitizedBoostedStoreNonprofitIds,
    startTime: startTime || undefined,
    endTime: endTime || undefined,
    multiplier: validatedMultiplier,
  }

  try {
    const response = await axiosRequest(
      'post',
      `${API_BASE_PATH_PORTAL}/v2/promotions`,
      requestDataToSend
    )
    return response.data
  } catch (error: any) {
    const errorDetail = error.response?.data?.detail
    const errorCode = error.response?.data?.code

    const customError: CustomError = {
      code: errorCode,
      detail: errorDetail,
    }
    throw new CustomErrorClass(customError)
  }
}

export async function updateAutoBoost({
  requestData,
  promoText,
  user,
  partnerId,
}: APIAutoBoostRequest) {
  const { startTime, endTime, multiplier, color } = requestData

  const sanitizedBoostedStoreNonprofitIds = validateAutoBoostRequestData(requestData, user)
  const validatedMultiplier = multiplier ? validateMultiplier(multiplier) : undefined

  const requestDataToSend = {
    id: requestData.promoId ? +requestData.promoId : null,
    promoText,
    color,
    partnerId,
    name: requestData.name,
    sdkId: user.chainId,
    availableToWholeChain: false,
    boostAllNonprofits: false,
    boostedStoreNonprofitIds: sanitizedBoostedStoreNonprofitIds,
    startTime: startTime || undefined,
    endTime: endTime || undefined,
    multiplier: validatedMultiplier,
  }

  try {
    const response = await axiosRequest(
      'post',
      `${API_BASE_PATH_PORTAL}/v2/promotions/update`,
      requestDataToSend
    )
    return response.data
  } catch (error: any) {
    const errorDetail = error.response?.data?.detail
    const errorCode = error.response?.data?.code

    const customError: CustomError = {
      code: errorCode,
      detail: errorDetail,
    }
    throw new CustomErrorClass(customError)
  }
}
