/**
 * Was receiving a Rollup error in playwright when attempting to use the auto generated ReactComponent
 * so the svg was manually converted into JSX to allow tests to pass
 */
export const Notification = (props: any) => {
  return (
    <svg
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="#00665C"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="6" cy="6" r="6" fill="inherit" />
    </svg>
  )
}
