import cx from 'classnames'
import { NavLink } from 'react-router-dom'

import { BeamDSProps } from '../interface'

interface LinkProps {
  label: string
  link: string
  onClick?: () => void
}

interface BeamHorizontalNavigationProps extends BeamDSProps {
  /**
   * List of links to display in the bar.
   */
  links: LinkProps[]
}

/**
 * Beam Horizontal Navigation component
 */
export const BeamHorizontalNavigation = ({ links }: BeamHorizontalNavigationProps) => {
  return (
    <div className="py-7">
      {links.map((link, i) => {
        const baseCssClasses = 'mt-0 px-6 py-7 text-charcoal-500 text-sm font-black no-underline'
        const activeCssClasses = 'text-sky-500 border-b-2 border-b-sky-500'
        return (
          <NavLink
            key={i}
            exact
            to={link.link}
            onClick={link.onClick ? link.onClick : undefined}
            className={activeLink => {
              return cx(baseCssClasses, activeLink ? activeCssClasses : '')
            }}>
            {link.label}
          </NavLink>
        )
      })}
    </div>
  )
}
