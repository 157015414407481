import { TSite } from '../../../utils/types'

export interface NonprofitInfoObject {
  name: string
  cause: string
  active: boolean
  personalPhoto: string
  nonprofitId: number
  associatedStores: {
    storeId: number
    name: string
  }[]
}

export interface AggregatedStoreNonprofitInfo {
  isActive: boolean
  nonprofitId: number
  nonprofitName: string
  storeId: number
  storeName: string
  storeNonprofitId: number
}

export interface ChainNonprofitsInfoResponse {
  nonprofits: NonprofitInfoObject[]
  storeNonprofits: AggregatedStoreNonprofitInfo[]
}

export function getNonprofitsBySiteFilter(
  nonprofits: NonprofitInfoObject[],
  storeNonprofits: AggregatedStoreNonprofitInfo[],
  siteFilter?: TSite
): NonprofitInfoObject[] {
  if (!siteFilter?.storeId) return nonprofits

  const filteredStoreNonprofits = storeNonprofits.filter(
    snp => Number(snp.storeId) === Number(siteFilter.storeId)
  )
  const filteredNonprofits = nonprofits.filter(np =>
    filteredStoreNonprofits.some(snp => snp.nonprofitId === np.nonprofitId)
  )

  return filteredNonprofits
}
