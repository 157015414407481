/**
 * Was receiving a Rollup error in playwright when attempting to use the auto generated ReactComponent
 * so the svg was manually converted into JSX to allow tests to pass
 */
export const Cause4 = (props: any) => {
  return (
    <svg
      {...props}
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9036_38172)">
        <path
          d="M16.2087 15.9998H11.2292C10.862 15.9998 10.5631 15.7003 10.5631 15.3322V11.5843L10.5635 11.58C10.5635 11.5322 10.5541 11.0713 10.2275 10.7366C9.95469 10.457 9.51418 10.315 8.91815 10.315C8.32213 10.315 7.88825 10.4559 7.61993 10.7336C7.28795 11.077 7.2935 11.5556 7.29393 11.5766C7.29436 11.5866 7.29457 11.5958 7.29457 11.6065V15.3324C7.29457 15.7005 6.9957 16 6.62847 16H1.5411C1.17387 16 0.875 15.7005 0.875 15.3324V7.02657C0.875 6.83195 0.959384 6.64739 1.10679 6.52042L8.4832 0.160589C8.73336 -0.0552307 9.10914 -0.0533037 9.35738 0.165085L11.9489 2.44597V1.32041C11.9489 0.952356 12.2478 0.652821 12.615 0.652821H14.9387C15.3059 0.652821 15.6048 0.952356 15.6048 1.32041V5.63659L16.64 6.51785C16.7893 6.64482 16.875 6.83045 16.875 7.02678V15.3322C16.875 15.7003 16.5759 15.9998 16.2087 15.9998Z"
          fill="#41312A"
        />
      </g>
      <defs>
        <clipPath id="clip0_9036_38172">
          <rect width="16" height="16" fill="white" transform="translate(0.875)" />
        </clipPath>
      </defs>
    </svg>
  )
}
