import { BeamCard } from '../BeamCard'
import { BeamDSProps } from '../interface'
import $$ from './beam-list-block.module.css'

interface BeamListBlockProps extends BeamDSProps {
  /**
   * Title of list
   */
  title: string | React.ReactNode
  /**
   * List of strings or react components.
   */
  listItems: (string | React.ReactNode)[]
  /**
   * Description that shows up under the title
   */
  description?: string | React.ReactNode
}

/**
 * List Block component
 */
export const BeamListBlock = ({ title, description, listItems, ...props }: BeamListBlockProps) => {
  return (
    <BeamCard {...props} className={props.className}>
      <div className={`${$$.title}`}>{title}</div>
      {description && <div className={$$.description}>{description}</div>}
      <ul className={`${$$.list}`}>
        {listItems.map((listItem, i) => {
          return <li key={i}>{listItem}</li>
        })}
      </ul>
    </BeamCard>
  )
}
