import { CustomError } from './types/CustomError'

export class CustomErrorClass extends Error {
  errorDetails: CustomError

  constructor(errorDetails: CustomError) {
    super(errorDetails.detail)
    this.errorDetails = errorDetails
  }

  get code(): string {
    return this.errorDetails.code
  }

  get detail(): string {
    return this.errorDetails.detail
  }
}
