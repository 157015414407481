import cx from 'classnames'
import { isNil } from 'lodash'
import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { fetchStripeData } from '../../../api/stripeApi'
import { REDESIGN_ROOT_PATH } from '../../../helpers'
import { useBeamSelector } from '../../../hooks'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { BeamButton } from '../../../stories/BeamButton'
import { BeamCTA } from '../../../stories/BeamCTA'
import { BeamMiniCTA } from '../../../stories/BeamMiniCTA'
import { BeamToast } from '../../../stories/BeamToast'
import { OverviewPartnerImpactResponse, TSite, TUser } from '../../../utils/types'
import { BeamSEO } from '../../root/BeamSEO'
import { InternalDevPanel } from '../../root/InternalDevPanel'
import {
  PaymentsSetupModals,
  PaymentsSetupModalsContext,
  PaymentsSetupModalsEnums,
} from '../PaymentsModal'
import {
  autopaySuccessful,
  initAutopayLaunchLocalStorageKey,
  onClickAutopayCTA,
} from '../PaymentsModal/PaymentsModalUtils'
import { PaypalGivingFundStatusEnums } from '../PaypalGivingFundPage'
import { ReactComponent as RainbowIcon } from './assets/rainbow.svg'
import { BusinessImpactModuleClone } from './BusinessImpactModule/BusinessImpactModuleClone'
import { CumulativeSocialImpactSection } from './CumulativeImpactSection'
import { MonthlyCustomerEngagementSection } from './MonthlyCustomerEngagementSection'
import { ActiveNonprofitPartnersBlock, ImpactFromActiveNonprofits } from './NonprofitSection'
import {
  fetchCumulativeImpact,
  fetchOptimalRoiWithSubscriptions,
  fetchPartner,
  holidayCumulativeImpactDataPartnerRequest,
} from './Overview.api'
import $$ from './overview-page.module.css'
import { OverviewNotifications } from './OverviewNotifications'
import { OverviewPage } from './OverviewPage'
import { isDataAvailable } from './OverviewPage.helper'
import {
  OptimalRoiWithSubscriptionsResponseWithLoading,
  ROIModuleState,
  TStateWithLoading,
} from './OverviewPage.types'
import { TimeframeToggleProvider } from './TimeframeToggleProvider'

const ErrorStateNoData = () => {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <p className="font-bold">
        There was a problem fetching your data. Please try refreshing the page or{' '}
        <Link to={`${REDESIGN_ROOT_PATH}/contact-support`}>contact support</Link> if the problem
        persists.
      </p>
    </div>
  )
}

const ErrorStateNoPartner = () => {
  return (
    <div>
      Your account does not have a partner associated with it. Please{' '}
      <Link to={`${REDESIGN_ROOT_PATH}/contact-support`}>contact us</Link> to resolve this issue
    </div>
  )
}

interface OverviewPageProps {
  fetchOverviewsMethod?: typeof fetchPartner
  fetchRoiMethod?: typeof fetchOptimalRoiWithSubscriptions
  fetchCumulativeImpactMethod?: typeof fetchCumulativeImpact
  fetchStripDataMethod?: typeof fetchStripeData
}

export const OverviewPageClone = ({
  fetchOverviewsMethod = fetchPartner,
  fetchRoiMethod = fetchOptimalRoiWithSubscriptions,
  fetchCumulativeImpactMethod = fetchCumulativeImpact,
  fetchStripDataMethod = fetchStripeData,
}: OverviewPageProps) => {
  const history = useHistory()

  const initialDataState = { data: null, loading: false, error: '' }
  const user: TUser = useBeamSelector(({ user }) => user)

  const [hasDataFetchingError, setHasDataFetchingError] = useState(false)
  const [invoiceEmails, setInvoiceEmails] = useState({ invoiceEmail: '', invoiceCcEmails: [] })
  const [openToast, setOpenToast] = useState<boolean>(false)
  const [isAutopaySuccessful, setIsAutopaySuccessful] = useState<boolean>(false)
  const [activeModal, setActiveModal] = useState<PaymentsSetupModalsEnums | null>(null)
  const [showHolidayCumulativeImpactRequestModal, setShowHolidayCumulativeImpactRequestModal] =
    useState<boolean>(false)
  const [statusFromHolidayCumulativeImpactRequest, setStatusFromHolidayCumulativeImpactRequest] =
    useState<boolean>(false)

  const [partnerData, setPartnerData] = useState<TStateWithLoading>(initialDataState)
  const [impactData, setImpactData] =
    useState<TStateWithLoading<OverviewPartnerImpactResponse>>(initialDataState)
  const [cumulativeImpactData, setCumulativeImpactData] =
    useState<TStateWithLoading>(initialDataState)
  const [stripeData, setStripeData] = useState<TStateWithLoading>(initialDataState)
  const [roiData, setRoiData] =
    useState<OptimalRoiWithSubscriptionsResponseWithLoading>(initialDataState)

  const featureFlag = useFeatureFlags()

  const currentSiteFilter: TSite | null = useBeamSelector(({ site }) => site)

  const showAutopayLaunchModal = localStorage.getItem(initAutopayLaunchLocalStorageKey) || 0
  const partnerId = user?.partnerId
  const canSeeAutopayFeatures = !!user?.partner?.canSeeAutopayFeatures

  // show initial launch autopay modal
  useEffect(() => {
    if (
      canSeeAutopayFeatures &&
      user?.autopayModalSeenCount < 10 &&
      showAutopayLaunchModal < user.autopayModalSeenCount
    ) {
      setActiveModal(PaymentsSetupModalsEnums.setupBeamFeesAutoPayLanding)
    }
  }, [canSeeAutopayFeatures, showAutopayLaunchModal, user?.autopayModalSeenCount])

  // Shows success modal if query params are present after redirect
  useEffect(() => {
    autopaySuccessful({ history, changeActiveModal })
      .then(({ success, skipped }) => {
        if (skipped) return

        setIsAutopaySuccessful(success)
      })
      .catch(error => {
        console.error(error)
        setIsAutopaySuccessful(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Displays toast after autopay setup is successful
  useEffect(() => {
    if (isAutopaySuccessful && !activeModal) {
      setOpenToast(true)
    }
  }, [isAutopaySuccessful, activeModal])

  // Fetch initial data
  useEffect(() => {
    if (partnerId) {
      fetchAllData().catch(error => {
        setHasDataFetchingError(true)

        console.error('ERROR -->', error)
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Fetch calculated data after loading partnerData
  useEffect(() => {
    const noPartnerData = !partnerData.data || partnerData.loading || partnerData.error
    const roiDataAlreadyLoaded = roiData.data
    if (noPartnerData || roiDataAlreadyLoaded) {
      return
    }

    ;(async () => {
      try {
        await getCalculatedData()
      } catch (error: any) {
        console.error(error)
      }
    })()
  }, [roiData.data, getCalculatedData, partnerData])

  useEffect(() => {
    fetchCumulativeImpactData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // re-fetches roi data when store filter changes
  useEffect(() => {
    const currentStoreId = currentSiteFilter?.storeId || null
    const roiDataStoreId = roiData.data?.storeId || null
    const shouldSkip =
      currentStoreId == roiDataStoreId || roiData.loading || roiData.error || !partnerData.data

    if (shouldSkip) return

    getCalculatedData().catch(error => {
      console.error(error)
    })
  }, [
    roiData.data?.storeId,
    roiData.error,
    roiData.loading,
    currentSiteFilter?.storeId,
    getCalculatedData,
    partnerData.data,
  ])

  // Fetch stripeData
  useEffect(() => {
    if (!canSeeAutopayFeatures || !user?.chainId) return

    const { data, loading, error } = stripeData
    if (data || loading || error) return

    setStripeData({ data: null, loading: true, error: null })

    fetchStripDataMethod(user?.chainId)
      .then(stripeData => {
        setStripeData({ data: stripeData, loading: false, error: null })
      })
      .catch(error => {
        console.error(error)
        setStripeData({ data: null, loading: false, error: error as any })
      })
  }, [user?.chainId, stripeData, canSeeAutopayFeatures, fetchStripDataMethod])

  useEffect(() => {
    setInvoiceEmails({
      invoiceEmail: partnerData?.data?.invoiceEmail || '',
      invoiceCcEmails: partnerData?.data?.invoiceCcEmails || [],
    })
  }, [partnerData?.data?.invoiceEmail, partnerData?.data?.invoiceCcEmails])

  // BEGIN Data fetching methods

  async function fetchAllData() {
    await Promise.all([fetchOverviewData()])
  }

  async function fetchOverviewData() {
    if (!partnerId || !user?.chainId || partnerData.loading || partnerData.error) return

    setPartnerData({ data: null, loading: true, error: null })
    setImpactData({ data: null, loading: true, error: null })

    try {
      const overviewData = await fetchOverviewsMethod(partnerId)
      setPartnerData({ data: overviewData.partner, loading: false, error: null })
      setImpactData({ data: overviewData.impact, loading: false, error: null })
    } catch (error) {
      console.error('Error fetching overview data:', error)
      setPartnerData({ data: null, loading: false, error: error as any })
      setImpactData({ data: null, loading: false, error: error as any })
    }
  }

  async function fetchCumulativeImpactData() {
    if (!user?.chainId || isDataAvailable(cumulativeImpactData)) {
      return
    }

    setCumulativeImpactData({ data: null, loading: true, error: null })

    try {
      const cumulativeImpactData = await fetchCumulativeImpactMethod(user?.chainId)
      setCumulativeImpactData({ data: cumulativeImpactData, loading: false, error: null })
    } catch (error) {
      console.error('Error fetching cumulative impact data:', error)
      setCumulativeImpactData({ data: null, loading: false, error: error as any })
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function getCalculatedData(): Promise<void> {
    if (roiData.loading || roiData.error || !partnerData.data) return

    setRoiData({ data: null, loading: true, error: null })

    try {
      const storeId =
        currentSiteFilter && currentSiteFilter.storeId !== null
          ? currentSiteFilter.storeId
          : undefined

      const roiDataResponse = await fetchRoiMethod(partnerId, storeId)

      setRoiData({ data: roiDataResponse, loading: false, error: null })
    } catch (error) {
      console.error('Error fetching calculatedData: ', error)
      setRoiData({ data: null, loading: false, error: error as any })
    }
  }

  // END Data fetching methods

  function afterModalCloseHandler(closedModal: PaymentsSetupModalsEnums | null) {
    // The onCloseCallback event gets triggered after the context has been updated, causing modals to close when transitioning from one modal to another.
    if (activeModal && activeModal !== closedModal) {
      return
    }

    setActiveModal(null)

    // Set the number of times the user has seen the modal locally so we know not to show it again
    // in the same session.
    localStorage.setItem(
      initAutopayLaunchLocalStorageKey,
      user.autopayModalSeenCount.toString() || '0'
    )
  }

  function changeActiveModal(newModal: PaymentsSetupModalsEnums | null) {
    if (newModal !== activeModal) {
      setActiveModal(newModal)
    }
  }

  if (!featureFlag['subscription-reporting']) {
    return <OverviewPage />
  }

  if (!partnerId || !user.partner) return <ErrorStateNoPartner />
  if (hasDataFetchingError) return <ErrorStateNoData />

  const enabledAutopayBeamFees = !!stripeData.data?.autopay
  const enabledAutopayNonprofits =
    user?.partner?.ppgfStatus === PaypalGivingFundStatusEnums.enrolled

  // Grabs current state of cumulative impact request from the database so we don't allow
  // users to re-request them
  let hasPartnerRequestedCumulativeImpactDataForThisYear = user.cumulativeImpactRequest

  // This validates whether the user clicked on the button.  Provided the API response is a 200
  // we want to change the CTA dynamically to show that the request was sent.  This conditional
  // handles that.
  if (statusFromHolidayCumulativeImpactRequest) {
    hasPartnerRequestedCumulativeImpactDataForThisYear = true
  }

  const shouldDisplayAutopayCTA =
    user.upcomingPromos?.length === 0 &&
    canSeeAutopayFeatures &&
    (!enabledAutopayBeamFees ||
      (!enabledAutopayNonprofits && !user.partner.inKindDonations.displayInKindDonations))

  const AutopayCTAComponent = (
    <div className={'mt-3'}>
      <BeamCTA
        leftContent={
          <>
            <h3 className="m-0">Automate Your Payments</h3>
            <p className="beam--paragraph--small">
              {enabledAutopayBeamFees && !enabledAutopayNonprofits
                ? 'Take 2 minutes to set up Autopay for Donations and we’ll automatically process your payment each time it is due, so you don’t have to think about it.'
                : 'Take 2 minutes to set up Autopay for Beam Fees and Autopay for Donations and we’ll automatically process your payments each time they’re due, so you don’t have to think about it.'}
            </p>
          </>
        }
        rightContent={
          <BeamButton
            label={
              enabledAutopayBeamFees && !enabledAutopayNonprofits
                ? 'Set Up Autopay For Donations'
                : 'Set up Autopay for Beam Fees'
            }
            onClick={() =>
              onClickAutopayCTA(
                stripeData.data?.autopay,
                user?.partner?.ppgfStatus as PaypalGivingFundStatusEnums,
                setActiveModal
              )
            }
            variant={'emphasis_white'}
            block={true}
          />
        }
      />
    </div>
  )

  const supplementaryMetrics = roiData.data?.oneMonthMetrics.supplementaryMetrics

  return (
    <TimeframeToggleProvider>
      <PaymentsSetupModalsContext.Provider
        value={{
          activeModal,
          changeActiveModal,
        }}>
        <BeamSEO title="Overview" />

        <PaymentsSetupModals
          activeModal={activeModal}
          afterModalCloseHandler={afterModalCloseHandler}
          invoiceEmails={invoiceEmails}
          isPaypalSetup={user?.partner?.ppgfStatus === PaypalGivingFundStatusEnums.enrolled}
          partnerId={user?.partnerId}
        />

        {!!user.canSeeCumulativeImpactCTA && (
          <>
            <br />
            <BeamMiniCTA
              backgroundColor={'--beam-color--coral-50'}
              title="2023 Impact Wrapped"
              description={
                !hasPartnerRequestedCumulativeImpactDataForThisYear
                  ? 'This Giving Tuesday, share your 2023 Impact with your customers. Request your Impact Wrapped by the 11/16 and we’ll share the tangible outcomes you’ve funded in 2023 in 11/20'
                  : 'Your request was sent for your 2023 Impact Wrapped! You will receive it by email on November 20th with a template to tailor social assets to share with your followers'
              }
              icon={<RainbowIcon />}
              buttonLabel={
                !hasPartnerRequestedCumulativeImpactDataForThisYear
                  ? 'Request Your 2023 Impact Wrapped'
                  : '2023 Impact Wrapped Requested'
              }
              disabled={hasPartnerRequestedCumulativeImpactDataForThisYear}
              variant="mini"
              buttonVariant={
                !hasPartnerRequestedCumulativeImpactDataForThisYear ? 'white' : 'neutral'
              }
              onClick={async () => {
                const response = await holidayCumulativeImpactDataPartnerRequest(partnerId)

                setStatusFromHolidayCumulativeImpactRequest(response.ok)
                setShowHolidayCumulativeImpactRequestModal(true)
              }}
            />
            <br />
            <hr className={$$.hrGivingTuesday} />
            <br />
          </>
        )}

        <OverviewNotifications
          autopayCTAComponent={AutopayCTAComponent}
          shouldDisplayAutopayCTA={shouldDisplayAutopayCTA}
          promos={user.upcomingPromos}
        />

        <div className="grid grid-cols-1">
          {!isNil(currentSiteFilter?.storeId) && (
            <h1 className="beam--heading--1">{currentSiteFilter?.name} Overview</h1>
          )}
          {isNil(currentSiteFilter?.storeId) && (
            <h1 className="beam--heading--1">&#128075; Hello, Here&apos;s your Beam Overview!</h1>
          )}

          <p className={cx($$.tagLine, 'pb-8 mobileOnly:!leading-normal desktop:pb-4')}>
            Here&apos;s how Beam is performing for {user.partner.name}. Have any questions?{' '}
            <Link to={`${REDESIGN_ROOT_PATH}/contact-support`}>Contact Support</Link>.
          </p>
        </div>

        {!!roiData.data &&
          roiData.data.oneMonthMetrics.optimalMetrics?.roiModuleState === ROIModuleState.Show && (
            <BusinessImpactModuleClone roiData={roiData.data} loading={roiData.loading} />
          )}

        {roiData?.data?.oneMonthMetrics.optimalMetrics?.roiModuleState ===
          ROIModuleState.SiteSpecific && (
          <div className={cx('px-8 py-6 bg-lime-50', $$.siteIndicatorForRoiModule)}>
            ROI Highlights are not available in Partner Portal for individual sites. Contact your
            Client Strategy Lead if you need to access this data.
          </div>
        )}

        <MonthlyCustomerEngagementSection
          calculatedData={supplementaryMetrics ?? null}
          loading={roiData.loading}
        />
        <CumulativeSocialImpactSection data={supplementaryMetrics} loading={roiData.loading} />
        <div className="grid grid-cols-1 mb-4 space-x-0 space-y-4 desktop:space-y-4 desktop:mt-4 desktop:space-x-4 desktop:grid-cols-2">
          <ImpactFromActiveNonprofits
            impactData={cumulativeImpactData.data}
            loading={impactData.loading}
          />
          <ActiveNonprofitPartnersBlock impact={impactData.data} loading={impactData.loading} />
        </div>
        <BeamToast
          onClose={() => setOpenToast(false)}
          open={openToast}
          closable={true}
          text={'Autopay is now set up!'}
          variant={'success'}
          icon={<span>&#127881;</span>}
        />

        {!!showHolidayCumulativeImpactRequestModal && (
          <BeamToast
            onClose={() => setShowHolidayCumulativeImpactRequestModal(false)}
            open={showHolidayCumulativeImpactRequestModal}
            closable={true}
            duration={5000}
            text={
              !statusFromHolidayCumulativeImpactRequest
                ? 'There was an error with your request.  Please try again.'
                : 'Request Sent! Your 2023 Impact Recap will be emailed on November 20'
            }
            variant={!statusFromHolidayCumulativeImpactRequest ? 'error' : 'success'}
            icon={!statusFromHolidayCumulativeImpactRequest ? null : <span>&#127881;</span>}
          />
        )}

        <InternalDevPanel
          data={[
            {
              label: 'chainId',
              value: user?.chainId,
            },
            {
              label: 'partnerId',
              value: user?.partnerId,
            },
            {
              label: 'reportPeriodId',
              value: roiData.data?.oneMonthMetrics.optimalMetrics.reportPeriodId,
            },
            {
              label: 'Feature Flags',
              value: user?.featureFlags
                ?.map(f => f.key)
                .sort()
                .join(' | '),
            },
          ]}
        />
      </PaymentsSetupModalsContext.Provider>
    </TimeframeToggleProvider>
  )
}
