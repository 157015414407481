import cx from 'classnames'

import { BeamDSProps } from '../interface'
import $$ from './beam-sticker.module.css'

export enum BEAM_STICKER_VARIANT {
  info = 'info',
  positive = 'positive',
  negative = 'negative',
  warning = 'warning',
  failure = 'failure',
  pending = 'pending',
}

interface BeamStickerProps extends BeamDSProps {
  /**
   * Content for sticker
   */
  label: string
  /**
   * Type of sticker
   */
  variant?: BEAM_STICKER_VARIANT
  /**
   * Icon that shows up on the left side
   */
  icon?: React.ReactNode
}

/**
 * Sticker component
 */
export const BeamSticker = ({
  label,
  variant = BEAM_STICKER_VARIANT.info,
  icon,
  ...props
}: BeamStickerProps) => {
  const styleVariant = variant.charAt(0).toUpperCase() + variant.slice(1)

  return (
    <span
      {...props}
      className={cx($$.sticker, props.className, 'beam--sticker', $$[`variant${styleVariant}`])}>
      <span className={$$.icon}>{icon}</span> {label}
    </span>
  )
}
