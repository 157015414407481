import { getV2ApiUrl } from '../../../api/auth'
import { axiosRequest } from '../../../utils/axiosRequest'
import { PartnerUploadBody } from '../../../utils/types'

export async function fetchPartnerUploadById(chainId: number, partnerUploadId: string) {
  const res = await axiosRequest(
    'GET',
    `${getV2ApiUrl()}/partner/${chainId}/partnerUploads/${partnerUploadId}`
  )

  return res.data as PartnerUploadBody
}

export async function fetchPartnerUploads(chainId: number) {
  const url = `${getV2ApiUrl()}/partner/${chainId}/partnerUploads/list`
  const res = await axiosRequest('GET', url)

  return res?.data as PartnerUploadBody[]
}
