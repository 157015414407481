import { createColumnHelper } from '@tanstack/react-table'
import { truncate } from 'lodash'

import { BeamTable } from '../../../../stories/BeamTable'
import { formatDate } from '../../../../utils/helpers/formatDate'
import { CampaignPromoBody } from '../../../../utils/types'
import $$ from './campaign-page.module.css'
import { CampaignTableLoadingOverlay } from './CampaignTableLoadingOverlay'

interface CompletedPromosTableProps {
  data: CampaignPromoBody[]
  /**
   * Loading state for the table
   */
  loading: boolean
}

const expandedRowComponent = ({ row }: { row: { original: CampaignPromoBody } }) => {
  const rowData = row.original

  return (
    <div className="grid grid-cols-12 gap-4 px-4">
      <div className="col-span-4">
        <p className={$$.campaignDetailsHeader}>Campaign Name</p>
        <p className={$$.campaignDetailsDatum}>{rowData.campaignName}</p>
      </div>
      <div className="col-span-8">
        <p className={$$.campaignDetailsHeader}>Boost Nonprofits</p>
        <p className={$$.campaignDetailsDatum}>{rowData.boostedNonprofits}</p>
      </div>
      <div className="col-span-4">
        <p className={$$.campaignDetailsHeader}>Date Range</p>
        <p className={$$.campaignDetailsDatum}>
          {formatDate(rowData.startDate, 'MM/dd/yyyy')} -{' '}
          {formatDate(rowData.endDate, 'MM/dd/yyyy')}
        </p>
      </div>
      <div className="col-span-8">
        <p className={$$.campaignDetailsHeader}>Boost Amount</p>
        <p className={$$.campaignDetailsDatum}>{rowData.boostAmount}x</p>
      </div>
    </div>
  )
}

export const CompletedPromosTable = (props: CompletedPromosTableProps) => {
  const { data, loading = false } = props
  const columnHelper = createColumnHelper<CampaignPromoBody>()

  const columns = [
    columnHelper.accessor('campaignName', {
      header: 'Campaign Name',
    }),
    columnHelper.accessor('startDate', {
      header: 'Date Range',
      cell: ({ row }) => {
        const rowData = row.original

        return (
          <>
            {formatDate(rowData.startDate, 'MM/dd/yyyy')} -{' '}
            {formatDate(rowData.endDate, 'MM/dd/yyyy')}
          </>
        )
      },
    }),
    columnHelper.accessor('boostAmount', {
      header: 'Boost Amount',
      cell: ({ row }) => {
        const rowData = row.original

        return <>{rowData.boostAmount}x</>
      },
    }),
    columnHelper.accessor('boostedNonprofits', {
      header: 'Boosted Nonprofits',
      cell: ({ row }) => {
        const rowData = row.original

        return (
          <>
            {truncate(rowData.boostedNonprofits, {
              length: 50,
              omission: '...',
            })}
          </>
        )
      },
    }),
  ]

  return (
    <div className={'relative'} aria-live={'polite'}>
      <CampaignTableLoadingOverlay loading={loading} />

      <BeamTable
        columns={columns}
        data={data}
        noDataMessage={'No reports uploaded to Partner Portal yet'}
        enableSorting
        expandedRowComponent={expandedRowComponent}
      />
    </div>
  )
}
