import './beam-navigation.css'

import { toString } from 'lodash'

import { gtag } from '../../components/root/BeamGoogleAnalytics/googleAnalyticsHelpers'
import { REDESIGN_ROOT_PATH } from '../../helpers'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import { getCurrentPageTitle } from '../../utils/getCurrentPageTitle'
import { TSite, TUser } from '../../utils/types'
import { BeamDropdown } from '../BeamDropdown'
import { BEAM_DROPDOWN_OPTION } from '../BeamDropdown/BeamDropdown'
import { ReactComponent as BarGraph } from './assets/barGraph.svg'
import { ReactComponent as BulletList } from './assets/bulletList.svg'
import { ReactComponent as DownArrow } from './assets/downArrow.svg'
import { ReactComponent as Folder } from './assets/folder.svg'
import { ReactComponent as NonprofitPartners } from './assets/nonprofitPartners.svg'
import { ReactComponent as UserAvatar } from './assets/userAvatar.svg'
import { BeamSideNavigationLink } from './BeamSideNavigationLink'
import { BeamUserBadge } from './BeamUserBadge'

interface PartnerSideNavBarProps {
  user: TUser
  pathname: string | null
  site?: TSite
  toggleFunction?: () => void
  invoicesCount?: number
  showMultiSiteFeatures?: boolean
  siteDropdownOptions?: BEAM_DROPDOWN_OPTION[]
  showSiteDropDown?: boolean
  handleSiteChange?: (_: any, value: number) => void
}

export const BeamSideNavigation = ({
  user,
  pathname,
  site,
  toggleFunction,
  showMultiSiteFeatures = false,
  siteDropdownOptions = [],
  handleSiteChange,
}: PartnerSideNavBarProps) => {
  const featureFlags = useFeatureFlags()
  // This is added to allow dropdown clicks while the left nav is docked
  const handleNavClick = (e: any) => {
    if (
      e.target instanceof HTMLAnchorElement ||
      (e.target as HTMLElement).parentElement instanceof HTMLAnchorElement
    ) {
      toggleFunction && toggleFunction()
    }
  }

  const gtagMetadata = {
    chainId: user?.chainId || null,
    userId: user?.id || null,
    siteFilter: site?.name || null,
    pageWhereEventTriggered: getCurrentPageTitle(),
  }

  const partnerNav = [
    {
      type: 'link',
      label: 'Overview',
      icon: <BulletList />,
      link: `${REDESIGN_ROOT_PATH}/overview`,
    },
    {
      type: 'link',
      label: 'Reports & Analytics',
      icon: <BarGraph />,
      link: `${REDESIGN_ROOT_PATH}/reports`,
    },
    {
      type: 'link',
      label: 'Nonprofit Partners',
      icon: <NonprofitPartners />,
      link: `${REDESIGN_ROOT_PATH}/nonprofits`,
    },
    { type: 'link', label: 'Invoices', icon: <Folder />, link: `${REDESIGN_ROOT_PATH}/invoices` },
    {
      type: 'link',
      label: 'Contact Support',
      icon: <UserAvatar />,
      link: `${REDESIGN_ROOT_PATH}/contact-support`,
    },
  ]

  if (featureFlags['nonprofits-by-store-filtering']) {
    partnerNav.splice(3, 0, { type: 'header', label: 'Invoices & Support' } as any)
  } else {
    partnerNav.splice(2, 0, { type: 'header', label: 'Invoices & Partners' } as any)
  }

  return (
    <div className="beam-navigation" onClick={handleNavClick} onKeyDown={handleNavClick}>
      <div className="beam-navigation--links">
        {showMultiSiteFeatures && (
          <BeamDropdown
            label=""
            value={toString(site?.storeId || 0)}
            className="beam-navigation--site-drop-down"
            options={siteDropdownOptions}
            onClickCallback={() => {
              gtag(`Multi-site dropdown clicked`, gtagMetadata)
            }}
            onChange={(e: any) => {
              const storeId = e.target.value as string
              handleSiteChange && handleSiteChange(e, +storeId)
              toggleFunction && toggleFunction()
              gtag(`Site filter applied`, gtagMetadata)
            }}>
            <div slot="expand-icon">
              <DownArrow style={{ fill: 'var(--beam-color--charcoal-50) !important' }} />
            </div>
          </BeamDropdown>
        )}
        {partnerNav.map(sideNavItem => {
          if (sideNavItem.type === 'header') {
            return (
              <div className="beam-navigation--section-header" key={sideNavItem.label}>
                {sideNavItem.label}
              </div>
            )
          } else if (sideNavItem.link) {
            return (
              <BeamSideNavigationLink
                key={sideNavItem.label}
                path={sideNavItem.link}
                itemLabel={sideNavItem.label}
                active={pathname?.startsWith(sideNavItem.link)}>
                {sideNavItem.icon}
                <span className="beam-navigation--link">{sideNavItem.label}</span>
              </BeamSideNavigationLink>
            )
          }
        })}
      </div>
      <BeamUserBadge user={user} pathname={pathname} />
    </div>
  )
}
