export const EditPencilEnabled = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0001 4.00002L12.0001 6.00002M8.66675 13.3334H14.0001M3.33341 10.6667L2.66675 13.3334L5.33341 12.6667L13.0574 4.94269C13.3074 4.69265 13.4478 4.35357 13.4478 4.00002C13.4478 3.64647 13.3074 3.30739 13.0574 3.05736L12.9427 2.94269C12.6927 2.69273 12.3536 2.55231 12.0001 2.55231C11.6465 2.55231 11.3075 2.69273 11.0574 2.94269L3.33341 10.6667Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
