import { createColumnHelper } from '@tanstack/react-table'
import cx from 'classnames'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { useEffect, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { API_BASE_PATH_PORTAL } from '../../../api/auth'
import { fetchStripeData } from '../../../api/stripeApi'
import { REDESIGN_ROOT_PATH } from '../../../helpers'
import { useBeamSelector } from '../../../hooks'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { BeamButton } from '../../../stories/BeamButton'
import { BeamDropdown } from '../../../stories/BeamDropdown'
import { BeamDropdownOnChangeEvent } from '../../../stories/BeamDropdown/BeamDropdown.types'
import { BeamIcon } from '../../../stories/BeamIcon'
import { ReactComponent as InfoIcon } from '../../../stories/BeamInfoPanel/assets/infoIcon.svg'
import { BeamLoadingIndicator } from '../../../stories/BeamLoadingIndicator'
import { BeamMiniCTA } from '../../../stories/BeamMiniCTA'
import { BeamSticker } from '../../../stories/BeamSticker'
import { BEAM_STICKER_VARIANT } from '../../../stories/BeamSticker/BeamSticker'
import { BeamTable } from '../../../stories/BeamTable'
import { BeamToast } from '../../../stories/BeamToast'
import { BeamTooltip } from '../../../stories/BeamTooltip'
import { axiosRequest } from '../../../utils/axiosRequest'
import { getInvoiceType } from '../../../utils/getInvoiceType'
import { dollarFormat } from '../../../utils/root'
import { InvoiceTypes, TInvoice, TLineItem } from '../../../utils/types'
import { BeamSEO } from '../../root/BeamSEO'
import { TStateWithLoading } from '../OverviewPage/OverviewPage.types'
import {
  PaymentsSetupModals,
  PaymentsSetupModalsContext,
  PaymentsSetupModalsEnums,
} from '../PaymentsModal'
import { autopaySuccessful, onClickAutopayCTA } from '../PaymentsModal/PaymentsModalUtils'
import { PaypalGivingFundStatusEnums } from '../PaypalGivingFundPage'
import $$ from './invoice-page.module.css'
import {
  INVOICE_STATUS,
  invoiceInvoicerDropdownOptions,
  invoiceStatusDropdownOptions,
} from './InvoicePage.options'

enum LineItemDisplayPaymentStatus {
  PAID = 'Paid',
  FAILED = 'Failed',
  UNPAID = 'Unpaid',
}

function getLineItemPaymentStatus({
  lineItem,
}: {
  lineItem: TLineItem
}): LineItemDisplayPaymentStatus {
  if (lineItem.isPaid) {
    return LineItemDisplayPaymentStatus.PAID
  }

  // don't show "Failed" status when the lineItem error is because the nonprofit isn't on PPGF
  const hasPpgfOnboardingError = lineItem.error?.toLowerCase().includes('no ppgf') || false

  if (lineItem.error && !lineItem.isPaid && !hasPpgfOnboardingError) {
    return LineItemDisplayPaymentStatus.FAILED
  }

  return LineItemDisplayPaymentStatus.UNPAID
}

function formatDateInUTC(date: Date) {
  return format(utcToZonedTime(date, 'UTC'), 'MM/dd/yyyy')
}

async function fetchInvoices(partnerId: number) {
  const data = await axiosRequest('get', `${API_BASE_PATH_PORTAL}/invoices/partner/${partnerId}`)
  return data.data
}

function sortRows(data: TInvoice[]) {
  // sort by most recent date
  return data.sort((a, b) => {
    const dateA = new Date(a.date).getTime()
    const dateB = new Date(b.date).getTime()

    return dateB - dateA
  })
}

function determineCategory(value: InvoiceTypes) {
  return value === null ? '' : value !== 'monthly' ? 'Nonprofit' : 'Beam'
}

function determineOverdue(row: TInvoice) {
  const today = new Date()

  if (row.partiallyPaid) {
    return { label: INVOICE_STATUS.partially_paid, variant: BEAM_STICKER_VARIANT.positive }
  }

  // both "partiallyPaid" and "paymentFailed" can be true. Prioritize the partiallyPaid status
  if (row.paymentFailed) {
    return { label: INVOICE_STATUS.payment_failed, variant: BEAM_STICKER_VARIANT.failure }
  }

  if (row.isPaid) {
    return { label: INVOICE_STATUS.paid, variant: BEAM_STICKER_VARIANT.positive }
  }

  const pastDueDate = new Date(row.dueDate)

  if (today.getTime() > pastDueDate.getTime()) {
    return { label: INVOICE_STATUS.past_due, variant: BEAM_STICKER_VARIANT.negative }
  }

  return { label: INVOICE_STATUS.outstanding, variant: BEAM_STICKER_VARIANT.warning }
}

function calculateTotalAmountPaidBasedOnLineItems(row: TInvoice) {
  return !row.lineItems ? 0 : row.lineItems.reduce((sum: number, item) => sum + item.amount, 0)
}

type Columns = {
  date: string
  description: string
  requiresReceipt: boolean
  amount: number
  paymentStatus: string
}

interface LineItemWithReceipt extends TLineItem {
  requiresReceipt?: boolean
}

const expandedRowComponent = ({ row: parentRow }: { row: any }) => {
  const data: TInvoice = parentRow.original
  const columnHelper = createColumnHelper<Columns>()
  const shouldShowPaymentStatusColumn = getInvoiceType(data) === 'nonprofit'

  const columns = [
    columnHelper.accessor('description', {
      header: 'Description',
      footer: () => (
        <>
          {!!shouldShowPaymentStatusColumn && (
            <div className={cx($$.nonPpgfNonprofitsDisclaimer, 'absolute z-10 w-[58rem]')}>
              <InfoIcon className={'inline-block w-4 h-4 align-middle'} />

              <p className={'pl-2 inline-block whitespace-normal align-middle'}>
                <span>
                  Payment status for nonprofits not paid via Autopay is updated 3-5 business days
                  after Beam&apos;s accounts receivable team receives proof of payment via email
                </span>
              </p>
            </div>
          )}
          <div className={cx({ ['mt-10']: shouldShowPaymentStatusColumn })}>Total</div>
        </>
      ),
    }),
    columnHelper.accessor('amount', {
      header: 'Amount',
      cell: (row: any) => <span>{dollarFormat(row.getValue(), 2)}</span>,
      footer: () => (
        <div
          className={cx({
            ['mt-10']: shouldShowPaymentStatusColumn,
          })}>
          {dollarFormat(calculateTotalAmountPaidBasedOnLineItems(data), 2)}
        </div>
      ),
    }),
  ]

  // Append "Payment Method" column to the end
  if (shouldShowPaymentStatusColumn) {
    columns.push(
      columnHelper.display({
        header: 'Payment Method',

        cell: ({ row }) => {
          const lineItem = row.original as any as LineItemWithReceipt
          const paymentStatus = getLineItemPaymentStatus({
            lineItem,
          })

          const shouldShowPayNowButton =
            paymentStatus === LineItemDisplayPaymentStatus.UNPAID &&
            lineItem.requiresReceipt &&
            !!lineItem.linkToPay

          if (shouldShowPayNowButton) {
            return (
              <span className={'inline-block'}>
                <BeamButton
                  label="Pay Now"
                  variant="mini"
                  onClick={() => window.open(lineItem.linkToPay?.toString(), '_blank')}
                />
              </span>
            )
          }
          let stickerVariant = BEAM_STICKER_VARIANT.warning

          if (paymentStatus === LineItemDisplayPaymentStatus.PAID) {
            stickerVariant = BEAM_STICKER_VARIANT.positive
          } else if (paymentStatus === LineItemDisplayPaymentStatus.FAILED) {
            stickerVariant = BEAM_STICKER_VARIANT.failure
          }

          return (
            <span>
              <BeamSticker
                label={paymentStatus}
                variant={stickerVariant}
                icon={<span>&#x25cf;</span>}
              />
            </span>
          )
        },
      })
    )

    columns.splice(
      1,
      0,
      columnHelper.display({
        id: 'Status',
        header: () => (
          <>
            Status{' '}
            <BeamTooltip
              content={
                <span className="whitespace-normal ">
                  Nonprofits signed up with Paypal Giving Fund (PPGF) will be marked as paid
                  automatically when Autopay is triggered. Nonprofits not paid via Autopay will not
                  be marked as paid until Beam receives proof of payment via email.
                </span>
              }>
              <InfoIcon className={'inline-block w-4 h-4 align-middle'} />
            </BeamTooltip>
          </>
        ),
        cell: ({ row }) => {
          const value = row.original as any

          return (
            <>
              {value.requiresReceipt === true
                ? 'Manual (Requires Receipt)'
                : 'Processing via Autopay'}
            </>
          )
        },
      })
    )
  }

  return (
    <div>
      <div className={$$.expandedInvoiceNumberHeader}>Invoice No. {data.id}</div>
      <div className={$$.expandedLineItemHeader}>Line Items</div>
      <div>
        {!data.lineItems ? (
          <p>There was an error loading line items.</p>
        ) : (
          <BeamTable
            variant={'Barebones'}
            className={$$.expandedViewTable}
            columns={columns}
            data={data.lineItems}
          />
        )}
      </div>
    </div>
  )
}

type FilterTypes = {
  invoicer: string
  status: string
}

interface InvoicePageProps {
  fetchInvoiceMethod?: typeof fetchInvoices
  fetchStripeDataMethod?: typeof fetchStripeData
}

export const InvoicePage = ({
  fetchInvoiceMethod = fetchInvoices,
  fetchStripeDataMethod = fetchStripeData,
}: InvoicePageProps) => {
  const history = useHistory()
  const initialDataState = { data: null, loading: false, error: '' }

  const user = useBeamSelector(({ user }) => user)
  const [loading, setLoading] = useState<boolean>(true)
  const [originalInvoices, setOriginalInvoices] = useState<TInvoice[] | never[]>([])
  const [filters, setFilters] = useState<FilterTypes>({ invoicer: '', status: '' })
  const [activeModal, setActiveModal] = useState<PaymentsSetupModalsEnums | null>(null)
  const [stripeData, setStripeData] = useState<TStateWithLoading>(initialDataState)
  const [invoiceEmails, setInvoiceEmails] = useState({ invoiceEmail: '', invoiceCcEmails: [] })
  const [openToast, setOpenToast] = useState<boolean>(false)
  const [isAutopaySuccessful, setIsAutopaySuccessful] = useState(false)
  const featureFlags = useFeatureFlags()

  const canSeeAutopayFeatures = !!user?.partner?.canSeeAutopayFeatures

  useEffect(() => {
    async function fetchData() {
      try {
        const data = sortRows(await fetchInvoiceMethod(user.partnerId))

        setOriginalInvoices(data)
        setLoading(false)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
    // We just need this to trigger once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Shows success modal if query params are present after redirect
  useEffect(() => {
    autopaySuccessful({ history, changeActiveModal }).then(({ success, skipped }) => {
      if (skipped) return

      setIsAutopaySuccessful(success)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isAutopaySuccessful && !activeModal) {
      setOpenToast(true)
    }
  }, [isAutopaySuccessful, activeModal])

  useEffect(() => {
    if (canSeeAutopayFeatures) fetchStripeDataFn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.chainId, canSeeAutopayFeatures])

  useEffect(() => {
    setInvoiceEmails({
      invoiceEmail: user?.partner?.invoiceEmail || '',
      invoiceCcEmails: user?.partner?.invoiceCcEmails || [],
    })
  }, [user?.partner?.invoiceEmail, user?.partner?.invoiceCcEmails])

  async function fetchStripeDataFn() {
    if (!user?.chainId) return

    const { data, loading, error } = stripeData
    if (data || loading || error) return

    setStripeData({ data: null, loading: true, error: null })

    try {
      const stripeData = await fetchStripeDataMethod(user?.chainId)
      setStripeData({ data: stripeData ?? null, loading: false, error: null })
    } catch (error) {
      setStripeData({ data: null, loading: false, error: error as any })
    }
  }

  // START Filter functionality
  function filteredDataSet() {
    let filteredData: never[] | TInvoice[] = originalInvoices

    if (filters.invoicer) {
      filteredData = filteredData.filter(
        oi => determineCategory(oi.type).toLowerCase() === filters.invoicer
      )
    }
    if (filters.status) {
      filteredData = filteredData.filter(oi => determineOverdue(oi).label === filters.status)
    }

    return filteredData
  }

  function changeActiveModal(newModal: PaymentsSetupModalsEnums | null) {
    if (newModal !== activeModal) {
      setActiveModal(newModal)
    }
  }

  function afterModalCloseHandler(closedModal: PaymentsSetupModalsEnums | null) {
    // The onCloseCallback event gets triggered after the context has been updated, causing modals to close when transitioning from one modal to another.
    if (activeModal && activeModal !== closedModal) {
      return
    }

    setActiveModal(null)
  }

  const filteredInvoices = useMemo(filteredDataSet, [
    filters.invoicer,
    filters.status,
    originalInvoices,
  ])

  interface ChangeHandler {
    target: { value: string }
  }

  function handleChangeInvoiceFilter(event: BeamDropdownOnChangeEvent) {
    const selectedValue = event.target.value
    setFilters({ ...filters, invoicer: selectedValue as string })
  }

  function handleChangeStatusFilter(event: ChangeHandler) {
    const selectedValue = event.target.value
    setFilters({ ...filters, status: selectedValue })
  }

  // END Filter functionality

  type Columns = {
    description: string
    type: string
    status: string
    startDate: string
    date: string
    paidOn: string
    amountPaid: string
  }

  const columnHelper = createColumnHelper<Columns>()

  const columns = [
    columnHelper.accessor('description', {
      header: 'Transaction',
    }),
    columnHelper.accessor('type', {
      header: 'Category',
      cell: (row: { getValue: () => any }) => <>{determineCategory(row.getValue())}</>,
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: (row: any) => {
        const status = determineOverdue(row.row.original)

        return (
          <>
            <BeamSticker
              label={status.label}
              icon={<span>&#x25cf;</span>}
              variant={status.variant}
            />
          </>
        )
      },
    }),
    columnHelper.accessor('startDate', {
      header: 'Sub Range',
      cell: (row: any) => {
        const data = row.row.original
        return (
          <>
            {formatDateInUTC(data.startDate)} - {formatDateInUTC(data.endDate)}
          </>
        )
      },
    }),
    columnHelper.accessor('date', {
      header: 'Issue Date',
      cell: (row: any) => {
        const data = row.row.original
        return <>{formatDateInUTC(data.date)}</>
      },
    }),
    columnHelper.accessor('paidOn', {
      header: 'Paid Date',
      cell: (row: { getValue: () => any; row: any }) => {
        const data = row.row.original

        if (row.getValue()) {
          return <>{formatDateInUTC(row.getValue())}</>
        } else if (data.isPaid) {
          return <></>
        }

        function onClickPayNow(type: InvoiceTypes) {
          if (determineCategory(type) === 'Beam') {
            // Beam Invoices
            window.open(data.partnerInvoiceLink, '_blank')
          } else {
            // Nonprofit Invoices
            row.row.toggleExpanded()
          }
        }

        // Default case
        return (
          <>
            <BeamButton
              label="Pay Now"
              variant="mini"
              onClick={() => onClickPayNow(row.row.original.type)}
            />
          </>
        )
      },
    }),
    columnHelper.accessor('amountPaid', {
      header: 'Amount Paid',
      cell: (row: any) => {
        const data = row.row.original
        return <>{dollarFormat(calculateTotalAmountPaidBasedOnLineItems(data))}</>
      },
    }),
  ]

  const filterByStatusOptions = invoiceStatusDropdownOptions()

  let bodyComponent = <BeamLoadingIndicator />

  if (!loading) {
    bodyComponent = (
      <BeamTable
        className={$$.mainInvoiceTable}
        columns={columns}
        data={filteredInvoices}
        enableSorting={true}
        expandedRowComponent={expandedRowComponent}
        noDataMessage={`There are no invoices for ${user.partner.name} to view`}
      />
    )
  }

  const enabledAutopayBeamFees = !!stripeData.data?.autopay
  const enabledAutopayNonprofits =
    user?.partner?.ppgfStatus === PaypalGivingFundStatusEnums.enrolled

  const oldAchDetailsDisclaimer = featureFlags['old-ach-details-message'] ? (
    <p className={$$.description}>
      Please note, If you are using the old ACH details, your payment will be manually marked as
      paid which could lead to a delay in your payment status updating in Partner Portal.
    </p>
  ) : (
    ''
  )
  const basicSubheading = (
    <>
      <p className={$$.description}>
        Need additional help? Check our{' '}
        <a
          href="https://judicious-pilot-61e.notion.site/Invoicing-FAQs-9c865df426ee42a7bfa4da1f16915d6a?pvs=4"
          target="_blank"
          rel="noreferrer">
          FAQs
        </a>{' '}
        or{' '}
        <Link to={`${REDESIGN_ROOT_PATH}/contact-support`} className={$$.link}>
          Contact Support
        </Link>
        .
      </p>
      {oldAchDetailsDisclaimer}
    </>
  )
  const inKindDonationSubheading = (
    <>All {user.partner.name} nonprofit invoices will be sent by email.</>
  )
  const subheading = !user.partner.inKindDonations.displayInKindDonations
    ? basicSubheading
    : inKindDonationSubheading

  return (
    <PaymentsSetupModalsContext.Provider
      value={{
        activeModal,
        changeActiveModal,
      }}>
      <BeamSEO title="My Invoices" />
      <PaymentsSetupModals
        activeModal={activeModal}
        afterModalCloseHandler={afterModalCloseHandler}
        invoiceEmails={invoiceEmails}
        isPaypalSetup={user?.partner?.ppgfStatus === PaypalGivingFundStatusEnums.enrolled}
        partnerId={user?.partnerId}
      />
      <div className="flex flex-col">
        {canSeeAutopayFeatures && (
          <>
            {(!enabledAutopayBeamFees ||
              (!enabledAutopayNonprofits &&
                !user.partner.inKindDonations.displayInKindDonations)) && (
              <div className={$$.setUpPPGF}>
                <BeamMiniCTA
                  title="Make Payments Seamless"
                  description={
                    enabledAutopayBeamFees && !enabledAutopayNonprofits
                      ? 'Set up Autopay for Donations to automate your payment when it is due'
                      : 'Set up Autopay for Beam Fees and Autopay for Donations to automate payment when they’re due'
                  }
                  buttonLabel={'Set Up Autopay'}
                  onClick={() =>
                    onClickAutopayCTA(
                      stripeData.data?.autopay,
                      user?.partner?.ppgfStatus,
                      setActiveModal
                    )
                  }
                />
              </div>
            )}
          </>
        )}
        <div>
          <h1 className="mt-[30px] mb-0">My Invoices</h1>
          <p className={$$.description}>{subheading}</p>
        </div>
      </div>
      <div className="grid grid-cols-1 desktop:grid-cols-2">
        <div>
          <h2 className="beam--heading--2">All Invoices</h2>
        </div>
        <div className="grid grid-cols-2 space-x-4 place-content-center">
          <BeamDropdown
            placeholder={`Filter by Invoice`}
            options={invoiceInvoicerDropdownOptions}
            onChange={handleChangeInvoiceFilter}
          />
          <BeamDropdown
            placeholder={`Filter by Status`}
            options={filterByStatusOptions}
            onChange={handleChangeStatusFilter}
          />
        </div>
      </div>
      <div className="grid grid-cols-1">{bodyComponent}</div>
      <BeamToast
        onClose={() => setOpenToast(false)}
        open={openToast}
        closable={true}
        text={'Autopay is now set up!'}
        variant={'success'}
        icon={<BeamIcon variant="party" />}
      />
    </PaymentsSetupModalsContext.Provider>
  )
}
