import { useContext, useState } from 'react'

import { getV2ApiUrl } from '../../../../api/auth'
import { useBeamSelector } from '../../../../hooks'
import { BeamButton } from '../../../../stories/BeamButton'
import { BeamModal } from '../../../../stories/BeamModal'
import { BeamToast } from '../../../../stories/BeamToast'
import { axiosRequest } from '../../../../utils/axiosRequest'
import { TUser } from '../../../../utils/types'
import { CustomError } from '../../../../utils/types/CustomError'
import { AutoBoostANonprofitModal } from '../AutoBoostANonprofitModal'
import { TCreateAutoBoostNonprofitData } from '../AutoBoostANonprofitModal.types'
import { ModalContext } from '../CampaignsPage/CampaignPage'
import { customErrorMessageHelper } from '../NonprofitPartnersPage'
import { RequestNewNonprofitCauseModal } from '../RequestNewNonprofitCauseModal'

export enum PromoModal {
  NoModal = 'NoModal',
  BoostANonprofit = 'BoostANonprofit',
  RequestANewNonprofit = 'RequestANewNonprofit',
  DeleteAPromoConfirmation = 'DeleteAPromoConfirmation',
}

export const PromoModalsComponent = ({
  activeModal,
  currentPromo,
}: {
  activeModal: PromoModal
  currentPromo: TCreateAutoBoostNonprofitData | null
}) => {
  const { setModal, setCurrentPromo } = useContext(ModalContext)
  const user: TUser | undefined = useBeamSelector(({ user }) => user)
  const [openToast, setOpenToast] = useState<boolean>(false)
  const [customErrorMessage, setCustomErrorMessage] = useState<CustomError | null>(null)
  const [customSuccessMessage, setCustomSuccessMessage] = useState<string>('')

  const layout: React.ReactNode[] = []

  if (!user) return <></>

  switch (activeModal) {
    case PromoModal.BoostANonprofit:
      layout.push(
        <AutoBoostANonprofitModal
          partnerId={user.partnerId}
          onCloseHandler={() => {
            setModal(PromoModal.NoModal)
            setCurrentPromo(null)
          }}
          user={user}
          openToast={() => {
            setCustomSuccessMessage(
              currentPromo
                ? 'Your promo has been updated!'
                : 'Boost set up! Check your inbox for a confirmation email'
            )
            setOpenToast(true)
          }}
          setMessage={(e: CustomError | null) => {
            setCustomErrorMessage(e)
          }}
          openNonprofitModalAction={() => setModal(PromoModal.RequestANewNonprofit)}
          promo={currentPromo}
        />
      )
      break
    case PromoModal.RequestANewNonprofit:
      layout.push(
        <RequestNewNonprofitCauseModal
          partnerId={user.partnerId}
          openToast={() => setOpenToast(true)}
          setMessage={(e: CustomError | null) => {
            setCustomErrorMessage(e)
          }}
        />
      )
      break
    case PromoModal.DeleteAPromoConfirmation:
      layout.push(
        <BeamModal
          open={true}
          body={
            <div className={'pt-4 flex flex-col items-center'}>
              <div className={'pt-12 pb-6'}>
                <BeamButton
                  variant={'elevated'}
                  label={'Delete Promo'}
                  className={'w-min'}
                  onClick={async () => {
                    if (currentPromo?.promoId) {
                      await deletePromo(currentPromo.promoId, user.partnerId)
                    }
                    setOpenToast(true)
                    setCustomSuccessMessage('Promo has been deleted')
                    setCurrentPromo(null)
                    setModal(PromoModal.NoModal)
                  }}
                />
              </div>
            </div>
          }
          label={'Are you sure you want to delete?'}
          onCloseCallback={() => setModal(PromoModal.NoModal)}
        />
      )
      break
    default:
      layout.push(<></>)
      break
  }

  layout.push(
    <BeamToast
      onClose={() => setOpenToast(false)}
      open={openToast}
      closable={true}
      text={
        customErrorMessage ? customErrorMessageHelper(customErrorMessage) : customSuccessMessage
      }
      variant={customErrorMessage ? 'error' : 'success'}
      icon={customErrorMessage ? null : <span>&#127881;</span>}
      duration={10000}
    />
  )

  return <>{layout}</>
}

export async function deletePromo(promoId: number, partnerId: number) {
  const url = `${getV2ApiUrl()}/promotions/delete`
  const res = await axiosRequest('DELETE', url, { promoId: +promoId, partnerId: +partnerId })

  return res.data
}
