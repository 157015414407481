import TextField from '@material-ui/core/TextField'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { API_BASE_PATH_PORTAL } from '../../../api/auth'
import { useBeamSelector } from '../../../hooks'
import { setToast } from '../../../redux/actions'
import { fetchPaymentStructures } from '../../../redux/thunks/adminThunks'
import { createCombinedInvoice } from '../../../redux/thunks/invoiceThunks'
import styles from '../../../style/admin/partner-report.module.css'
import dropdownStyles from '../../../style/root/beam-dropdown.module.css'
import modalStyles from '../../../style/root/beam-modal.module.css'
import { axiosRequest } from '../../../utils/axiosRequest'
import { htmlDateFormat } from '../../../utils/root'
import { TPaymentStructure, TPPPartner } from '../../../utils/types'
import { APIError } from '../../root/APIError'
import BeamButton from '../../root/BeamButton'
import BeamDropdown from '../../root/BeamDropdown'
import { BeamModal } from '../../root/BeamModal'
import { BeamProgressBar } from '../../root/BeamProgressBar'
import { BeamSwitch } from '../../root/BeamSwitch'
import { PartnerEditableData, updatePartnerData } from './PartnerReport.api'

export const PartnerReport = () => {
  const [showSetLaunchDateModal, setShowSetLaunchDateModal] = useState(false)
  const [showCreationModal, setShowCreationModal] = useState(false)
  const [nonprofitDonationsDue, setNonprofitDonationsDue] = useState(true)
  const [emailPartner, setEmailPartner] = useState(false)
  const [beamInvoiceDue, setBeamInvoiceDue] = useState(true)
  const [showEditPartnerModal, setShowEditPartnerModal] = useState(false)
  const [partnerEditApiError, setPartnerEditApiError] = useState(null)
  const [launchDateApiError, setLaunchDateApiError] = useState(null)
  const [submittingPartnerEditData, setSubmittingPartnerEditData] = useState(false)
  const [submittingLaunchDate, setSubmittingLaunchDate] = useState(false)
  const [launchDateSubmitted, setLaunchDateSubmitted] = useState(false)
  const [partnerEditsSubmitted, setPartnerEditsSubmitted] = useState(false)
  const [makeInvoiceVisible, setMakeInvoiceVisible] = useState(true)
  const [scheduleNextInvoice, setScheduleNextInvoice] = useState(true)
  const [launchDateData, setLaunchDateData] = useState<string>()
  const [partnerEditData, setPartnerEditData] = useState<PartnerEditableData>()

  const dispatch = useDispatch() // FIXME: Use `useDispatch` when we have typedefs
  const partner = (useBeamSelector(({ partner }) => partner) as TPPPartner) || undefined
  const loadingStates = useBeamSelector(({ loadingStates }) => loadingStates) as any // FIXME: Remove any when we have typedefs for reducers
  const toasts = useBeamSelector(({ toasts }) => toasts) as any // FIXME: Remove typecasting when we have the typedefs

  const paymentStructures = useBeamSelector(
    ({ paymentStructures }) => paymentStructures
  ) as TPaymentStructure[] // FIXME: Remove typecasting when we have the typedefs

  const launchDate = useMemo(() => {
    if (!partner?.launchDate) return null

    const timeZoneOffset = new Date().getTimezoneOffset() * 60 * 1000
    const date = partner?.launchDate || Date.now()
    return new Date(new Date(date).getTime() + timeZoneOffset)
  }, [partner?.launchDate])

  const isoLaunchDate = useMemo(() => {
    return htmlDateFormat(launchDate)
  }, [launchDate])

  const closeToast = useCallback(() => {
    dispatch(setToast({ showToast: false, message: null, color: null }))
  }, [dispatch])

  // Fetch all the data on first load
  useEffect(() => {
    if (!paymentStructures || !paymentStructures.length) {
      dispatch(fetchPaymentStructures())
    }
    return () => closeToast()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // Populate partner data after first load. Skipped if data was already populated
  useEffect(() => {
    if (partnerEditData !== undefined && launchDateData !== undefined) return

    setPartnerEditData({
      paymentStructureId: partner?.paymentStructure?.id,
      name: partner?.name,
      invoiceFirstName: partner?.invoiceFirstName || undefined,
      invoiceLastName: partner?.invoiceLastName || undefined,
      invoiceCcEmails: partner?.invoiceCcEmails,
      inKindDonations: partner?.inKindDonations,
      invoiceEmail: partner?.invoiceEmail || undefined,
      usePpgf: !!partner?.usePpgf,
      isActive: partner?.isActive,
      liveInCbe: partner?.liveInCbe,
      onDiscovery: partner?.onDiscovery,
      donateForUnredeemed: partner?.donateForUnredeemed,
      transactionVerificationRequired: partner?.transactionVerificationRequired,
      ppgfEmail: partner?.ppgfEmail,
      invoiceMinimum: Number(partner?.invoiceMinimum || 0),
      locations: partner?.locations,
    })
    setLaunchDateData(isoLaunchDate)
  }, [partner, isoLaunchDate, setPartnerEditData, partnerEditData, launchDateData])

  const isLocationBasedPaymentStructure = useMemo(() => {
    return !!paymentStructures.find(p => p.id === partnerEditData?.paymentStructureId)
      ?.isLocationBased
  }, [partnerEditData?.paymentStructureId, paymentStructures])

  const createInvoice = () => {
    if (!partner?.id) return

    dispatch(
      createCombinedInvoice(
        partner.id,
        nonprofitDonationsDue,
        emailPartner,
        true,
        beamInvoiceDue,
        makeInvoiceVisible,
        scheduleNextInvoice
      )
    )
    setShowCreationModal(false)
  }

  const handlePartnerEditDataInput = (name: string, value: any) => {
    setPartnerEditData({ ...partnerEditData, [name]: value })
  }

  const submitPartnerEdits = () => {
    if (!partner?.chainId || partnerEditData === undefined) return

    setSubmittingPartnerEditData(true)

    updatePartnerData(partner.chainId, partnerEditData)
      .then(() => {
        setPartnerEditsSubmitted(true)
        setSubmittingPartnerEditData(false)
      })
      .catch(error => {
        setPartnerEditApiError(error?.response?.data?.error || 'An unknown error occurred...')
        setSubmittingPartnerEditData(false)
      })
  }

  const submitLaunchDate = () => {
    setSubmittingLaunchDate(true)
    axiosRequest('put', `${API_BASE_PATH_PORTAL}/v2/admin/${partner.id}/launch/${launchDateData}`)
      .then(() => {
        setLaunchDateSubmitted(true)
        setSubmittingLaunchDate(false)
      })
      .catch(error => {
        setLaunchDateApiError(error?.response?.data?.error || 'An unknown error occurred...')
        setSubmittingLaunchDate(false)
      })
  }

  const handleSelectedAction = (_: string, action: string) => {
    switch (action) {
      case 'Edit Info':
        return setShowEditPartnerModal(true)
      case 'Set Launch Date':
        return setShowSetLaunchDateModal(true)
      default:
        return
    }
  }

  if (!partner || !partnerEditData) return null
  if (!partner) {
    return <h2>No Partner data found.</h2>
  }

  if (loadingStates.reports?.loading) return <div>Generating a report...</div>
  if (loadingStates.reports?.error) {
    return <APIError error={loadingStates.reports.error} withRedirect={true} />
  }

  return (
    <div>
      <div className={styles['title-container']}>
        <div>
          <h1>{partner && partner.name}</h1>
          <div className={styles['info-container']}>
            <div className="text-small">
              Launch Date:{' '}
              {partner && partner.launchDate
                ? new Date(partner.launchDate).toLocaleDateString('en-us', { timeZone: 'UTC' })
                : 'No Launch Date Set'}
            </div>
            <br />
            <div className="text-small">
              We are updating Admin Portal to pull data from the correct source. In the interim, to
              access reports for this brand, go to Metabase or login to Partner Portal using the
              brand’s test credentials (<a href="mailto: brand@impact.com">brand@beamimpact.com</a>)
            </div>
          </div>
        </div>
        <BeamDropdown
          placeholderDisplay="Actions"
          options={['Edit Info', 'Set Launch Date' /*, 'Update Data' */]}
          width="175px"
          hideClearSelection={true}
          changeHandler={(name, value) => handleSelectedAction(name, value as string)}
        />
      </div>
      <BeamModal
        title={
          submittingPartnerEditData
            ? 'Submitting Your Edits...'
            : partnerEditsSubmitted
            ? 'Edits Submitted'
            : 'Editing Partner Info'
        }
        titleColor={partnerEditsSubmitted ? '#4caf50' : '#000'}
        open={showEditPartnerModal}
        handleClose={() => {
          setShowEditPartnerModal(false)
          setPartnerEditsSubmitted(false)
        }}>
        {submittingPartnerEditData ? (
          <div className={modalStyles['modal-container']}>
            <BeamProgressBar />
          </div>
        ) : partnerEditsSubmitted ? (
          <div className={modalStyles['modal-container']}>
            <div style={{ color: '#4caf50' }}>&#127881;</div>
          </div>
        ) : (
          <div className={modalStyles['modal-container']}>
            <div
              className={modalStyles['select-container']}
              style={{ justifyContent: 'space-between' }}>
              Name:
              <input
                className={dropdownStyles['beam-dropdown-placeholder']}
                value={partnerEditData?.name || ''}
                style={{ cursor: 'text', maxWidth: '287px' }}
                onChange={e => handlePartnerEditDataInput('name', e.target.value)}
              />
            </div>
            <div
              className={modalStyles['select-container']}
              style={{ justifyContent: 'space-between' }}>
              Payment Structure:{' '}
              <BeamDropdown
                options={paymentStructures?.map(p => ({ value: p.id, display: p.name })) || []}
                selectedValue={partnerEditData.paymentStructureId}
                changeHandler={(_, value: any) =>
                  handlePartnerEditDataInput('paymentStructureId', value)
                }
              />
            </div>
            {isLocationBasedPaymentStructure && (
              <div
                className={modalStyles['select-container']}
                style={{ justifyContent: 'space-between' }}>
                Locations:{' '}
                <BeamDropdown
                  options={Array.from({ length: 20 }).map((e, i) => i + 1)}
                  selectedValue={partnerEditData.locations}
                  changeHandler={(_: string, value: any) =>
                    handlePartnerEditDataInput('locations', +value)
                  }
                />
              </div>
            )}
            <div
              className={modalStyles['select-container']}
              style={{ justifyContent: 'space-between' }}>
              Invoice First Name:
              <input
                className={dropdownStyles['beam-dropdown-placeholder']}
                value={partnerEditData.invoiceFirstName || ''}
                style={{ cursor: 'text', maxWidth: '287px' }}
                onChange={e => handlePartnerEditDataInput('invoiceFirstName', e.target.value)}
              />
            </div>
            <div
              className={modalStyles['select-container']}
              style={{ justifyContent: 'space-between' }}>
              Invoice Last Name:
              <input
                className={dropdownStyles['beam-dropdown-placeholder']}
                value={partnerEditData.invoiceLastName || ''}
                style={{ cursor: 'text', maxWidth: '287px' }}
                onChange={e => handlePartnerEditDataInput('invoiceLastName', e.target.value)}
              />
            </div>
            <div
              className={modalStyles['select-container']}
              style={{ justifyContent: 'space-between' }}>
              Invoice Email:
              <input
                className={dropdownStyles['beam-dropdown-placeholder']}
                value={partnerEditData.invoiceEmail || ''}
                style={{ cursor: 'text', maxWidth: '287px' }}
                onChange={e => handlePartnerEditDataInput('invoiceEmail', e.target.value)}
              />
            </div>
            <div
              className={modalStyles['select-container']}
              style={{ position: 'relative', justifyContent: 'space-between' }}>
              Invoice CC Emails*:
              <input
                className={dropdownStyles['beam-dropdown-placeholder']}
                value={
                  partnerEditData.invoiceCcEmails && partnerEditData.invoiceCcEmails.length
                    ? partnerEditData.invoiceCcEmails.join(',')
                    : ''
                }
                style={{ cursor: 'text', maxWidth: '287px' }}
                onChange={e =>
                  handlePartnerEditDataInput('invoiceCcEmails', e.target.value.split(','))
                }
              />
              <div style={{ position: 'absolute', top: '110%', left: '0', fontSize: '12px' }}>
                *Comma separated
              </div>
            </div>
            <br />
            <div
              className={modalStyles['select-container']}
              style={{ position: 'relative', justifyContent: 'space-between' }}>
              PPGF Email:
              <input
                className={dropdownStyles['beam-dropdown-placeholder']}
                value={partnerEditData.ppgfEmail || ''}
                style={{ cursor: 'text', maxWidth: '287px' }}
                onChange={e => handlePartnerEditDataInput('ppgfEmail', e.target.value)}
              />
            </div>
            <div
              className={modalStyles['select-container']}
              style={{ position: 'relative', justifyContent: 'space-between' }}>
              Minimum ($):
              <input
                type={'number'}
                min={0}
                className={dropdownStyles['beam-dropdown-placeholder']}
                value={partnerEditData.invoiceMinimum ? Number(partnerEditData.invoiceMinimum) : ''}
                style={{ cursor: 'text', maxWidth: '287px' }}
                onWheel={e => {
                  // prevents scrolling from changing input value
                  e.currentTarget.blur()
                  e.stopPropagation()
                }}
                onChange={e => {
                  const value = +e.target.value
                  if (value < 0) return
                  if (value === undefined || isNaN(value)) return undefined
                  handlePartnerEditDataInput('invoiceMinimum', value)
                }}
                name={'invoiceMinimum'}
              />
            </div>
            <div
              className={modalStyles['select-container']}
              style={{ justifyContent: 'space-between' }}>
              <BeamSwitch
                on={partnerEditData.inKindDonations}
                handleClick={() => {
                  setPartnerEditData({
                    ...partnerEditData,
                    inKindDonations: !partnerEditData.inKindDonations,
                  })
                }}
                label="In Kind Donations"
              />
            </div>
            <div
              className={modalStyles['select-container']}
              style={{ justifyContent: 'space-between' }}>
              <BeamSwitch
                on={partnerEditData.usePpgf}
                handleClick={() => {
                  setPartnerEditData({
                    ...partnerEditData,
                    usePpgf: !partnerEditData.usePpgf,
                  })
                }}
                label="Enable PPGF"
              />
            </div>
            <div
              className={modalStyles['select-container']}
              style={{ justifyContent: 'space-between' }}>
              <BeamSwitch
                on={partnerEditData.donateForUnredeemed}
                handleClick={() =>
                  setPartnerEditData({
                    ...partnerEditData,
                    donateForUnredeemed: !partnerEditData.donateForUnredeemed,
                  })
                }
                label="Donate For Unredeemed"
              />
            </div>
            <div
              className={modalStyles['select-container']}
              style={{ justifyContent: 'space-between' }}>
              <BeamSwitch
                on={partnerEditData.transactionVerificationRequired}
                handleClick={() =>
                  setPartnerEditData({
                    ...partnerEditData,
                    transactionVerificationRequired:
                      !partnerEditData.transactionVerificationRequired,
                  })
                }
                label="Require Transaction Verification"
              />
            </div>
            <div
              className={modalStyles['select-container']}
              style={{ justifyContent: 'space-between' }}>
              <BeamSwitch
                on={partnerEditData.isActive}
                handleClick={() =>
                  setPartnerEditData({
                    ...partnerEditData,
                    isActive: !partnerEditData.isActive,
                  })
                }
                label="Active"
              />
            </div>
            <div
              className={modalStyles['select-container']}
              style={{ justifyContent: 'space-between' }}>
              <BeamSwitch
                on={partnerEditData.liveInCbe}
                handleClick={() =>
                  setPartnerEditData({
                    ...partnerEditData,
                    liveInCbe: !partnerEditData.liveInCbe,
                  })
                }
                label="Live on CBE"
              />
            </div>
            <div
              className={modalStyles['select-container']}
              style={{ justifyContent: 'space-between' }}>
              <BeamSwitch
                on={partnerEditData.onDiscovery}
                handleClick={() =>
                  setPartnerEditData({
                    ...partnerEditData,
                    onDiscovery: !partnerEditData.onDiscovery,
                  })
                }
                label="On Discovery"
              />
            </div>
            {partnerEditApiError && <div className={modalStyles.error}>{partnerEditApiError}</div>}
            <BeamButton text="SUBMIT" handler={submitPartnerEdits} />
          </div>
        )}
      </BeamModal>
      <BeamModal
        title={loadingStates?.invoice?.loading ? 'Submitting Your Invoice...' : 'Create An Invoice'}
        open={!!showCreationModal || !!loadingStates?.invoices?.loading}
        handleClose={() => setShowCreationModal(false)}>
        {loadingStates?.invoices?.loading ? (
          <div className={modalStyles['modal-container']}>
            <BeamProgressBar />
          </div>
        ) : (
          <div className={modalStyles['modal-container']}>
            <form onSubmit={e => e.preventDefault()}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <div className={modalStyles['select-container']}>
                  <BeamSwitch
                    on={scheduleNextInvoice}
                    handleClick={() => setScheduleNextInvoice(!scheduleNextInvoice)}
                    label="Schedule Next Invoice"
                  />
                </div>
                <div className={modalStyles['select-container']}>
                  <BeamSwitch
                    on={beamInvoiceDue}
                    handleClick={() => setBeamInvoiceDue(!beamInvoiceDue)}
                    label="Beam Fees Due"
                  />
                </div>
                <div className={modalStyles['select-container']}>
                  <BeamSwitch
                    on={nonprofitDonationsDue}
                    handleClick={() => setNonprofitDonationsDue(!nonprofitDonationsDue)}
                    label="Donations Due*"
                  />
                </div>
                <div
                  style={{
                    textAlign: 'center',
                    fontSize: '12px',
                    position: 'relative',
                    top: '-25px',
                  }}>
                  *{partner.name} {partner.usePpgf ? 'is using ' : 'is not using '}PPGF
                </div>
                <div className={modalStyles['select-container']}>
                  <BeamSwitch
                    on={emailPartner}
                    handleClick={() => setEmailPartner(!emailPartner)}
                    label="Email Partner"
                  />
                </div>
                <div className={modalStyles['select-container']}>
                  <BeamSwitch
                    on={makeInvoiceVisible}
                    handleClick={() => setMakeInvoiceVisible(!makeInvoiceVisible)}
                    label="Display Invoices to Partner"
                  />
                </div>
              </div>
            </form>
            {loadingStates?.apiKeys?.error && (
              <div className={modalStyles.error}>{loadingStates.apiKeys.error.toString()}</div>
            )}
            <BeamButton text="CREATE" handler={createInvoice} />
          </div>
        )}
      </BeamModal>
      <BeamModal
        title={
          submittingLaunchDate
            ? 'Submitting update...'
            : launchDateSubmitted
            ? 'Launch Date Updated'
            : 'Update Launch Date'
        }
        titleColor={launchDateSubmitted ? '#4caf50' : '#000'}
        open={showSetLaunchDateModal}
        handleClose={() => {
          setShowSetLaunchDateModal(false)
          setPartnerEditsSubmitted(false)
        }}>
        <div
          className={modalStyles['select-container']}
          style={{ justifyContent: 'space-between' }}>
          Launch Date:
          <TextField
            required
            name="launchDate"
            type="date"
            onChange={e => setLaunchDateData(e.target.value)}
            value={launchDateData}
          />
        </div>
        {!partner.launchDate ? (
          <div>
            {launchDateApiError && <div className={modalStyles.error}>{launchDateApiError}</div>}
            <BeamButton text="SUBMIT" handler={submitLaunchDate} />
          </div>
        ) : (
          'Launch Date is Already Set. To Reset Launch Date Contact Engineering'
        )}
      </BeamModal>
      <BeamModal
        open={!!toasts.showToast}
        handleClose={() => closeToast()}
        title={loadingStates?.invoices?.error ? 'Error' : 'Invoice Created'}
        titleColor={toasts.color}>
        <div style={{ color: toasts.color }}>
          {toasts.message === 'Invoice Created' ? (
            <span>&#127881;</span>
          ) : (
            JSON.stringify(toasts.message)
          )}
        </div>
      </BeamModal>
    </div>
  )
}
