import { useState } from 'react'

import { API_BASE_PATH_PORTAL } from '../../../api/auth'
import { BeamModal } from '../../../stories/BeamModal'
import { axiosRequest } from '../../../utils/axiosRequest'

const getApprovalLink = async () => {
  const data = await axiosRequest(
    'post',
    `${API_BASE_PATH_PORTAL}/invoices/paypal/billing-agreement/token`
  )

  return data.data
}

export const RedirectToPPGF = () => {
  const [approvalLink, setApprovalLink] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [showModal, setShowModal] = useState<boolean>(false)

  const navigateToPaypal = async () => {
    setShowModal(true)

    try {
      const generatedLink = await getApprovalLink()
      setApprovalLink(generatedLink)
      window.location.href = generatedLink
    } catch (error: any) {
      setError(error.message)
    }
  }

  return (
    <>
      <a className="text-base font-secondary text-[14px]" href="#" onClick={navigateToPaypal}>
        View or Edit PayPal Giving Fund
      </a>

      <BeamModal
        open={showModal}
        label={error ? 'Error' : 'Setting you up'}
        body={
          <div>
            {!approvalLink && 'Getting Link'}
            {!error && approvalLink && (
              <div>
                If you are not redirected, please follow <a href={approvalLink}>this link</a> to
                complete your set up with PayPal
              </div>
            )}
            <p>{error}</p>
          </div>
        }
      />
    </>
  )
}
