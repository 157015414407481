import { Grid } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchNonprofits } from '../../redux/thunks/adminThunks'
import formStyles from '../../style/root/beam-form-fields.module.css'
import asNumber from '../../utils/helpers/asNumber'
import { processApiError } from '../../utils/root'
import { TNonprofit } from '../../utils/types'
import { APIError } from '../root/APIError'
import BeamButton from '../root/BeamButton'
import BeamDropdown from '../root/BeamDropdown'
import BeamForm from '../root/BeamForm'
import { BeamModal } from '../root/BeamModal'
import { BeamProgressBar } from '../root/BeamProgressBar'
import { PageNotFound } from '../root/PageNotFound'
import {
  AggregateTargetChainNonprofitInfo,
  CBPartnerInfo,
  NonprofitOption,
  StoreInfo,
} from './CreateCentralBackendPartner'
import CreatePartnerNonprofit from './CreatePartnerNonprofit'
import CreatePartnerStore from './CreatePartnerStore'

// StoreInfo in the expected shape to send to the API
interface StoreInfoPayload extends Omit<StoreInfo, 'nonprofitIds'> {
  nonprofitIds: number[]
}

// PartnerInfo in the expected shape to send to the API
export interface PartnerInfoPayload extends Omit<CBPartnerInfo, 'stores'> {
  stores: StoreInfoPayload[]
}

interface EditCBPartnerFormProps {
  formTitle: string
  partnerData: CBPartnerInfo
  showSubmit?: boolean
  setPartnerData: (partnerData: CBPartnerInfo) => void
  submitHandler?: (formData: PartnerInfoPayload) => void
}

// Formats form data into the expected shape for an API request
export const formatData = (partnerData: CBPartnerInfo): PartnerInfoPayload => {
  const newPartner: PartnerInfoPayload = {
    ...partnerData,
    name: partnerData.name,
    useRegionalization: partnerData.useRegionalization,
    donationAmount: asNumber(partnerData.donationAmount),
    donationPercentage: asNumber(partnerData.donationPercentage),
    stores: [],
    targetChainNonprofitInfo: [],
  }

  const formattedNonprofits = partnerData.targetChainNonprofitInfo.map(nonprofit => {
    const targetUserDonationAmount = asNumber(nonprofit.targetUserDonationAmount)
    const targetChainDonationAmount = asNumber(nonprofit.targetChainDonationAmount)
    const sortOrder = asNumber(nonprofit.sortOrder)
    const filter = typeof nonprofit.filter === 'string' ? nonprofit.filter.split(',') : []

    return {
      ...nonprofit,
      targetUserDonationAmount,
      targetChainDonationAmount,
      sortOrder,
      filter,
    }
  })

  newPartner.targetChainNonprofitInfo = formattedNonprofits

  const formattedStores: StoreInfoPayload[] = partnerData.stores.map(store => {
    const type = asNumber(store.type)
    const storeNonprofitIds = store.nonprofitIds.map(nonprofitIds => nonprofitIds.value)

    return { ...store, type, nonprofitIds: storeNonprofitIds }
  })

  newPartner.stores = formattedStores

  return newPartner
}

/**
 * Reusable form component for editing Central Backend partner info.
 */
export const EditCBPartnerForm = ({
  formTitle,
  partnerData,
  setPartnerData,
  submitHandler,
  showSubmit = true,
}: EditCBPartnerFormProps) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [nonProfitOptions, setNonprofitOptions] = useState<NonprofitOption[]>([])
  //keep track of AggregateTargetChainNonprofitInfo if the partner already had associated nonprofits
  const [originalTargetChainNonprofitInfo, setOriginalTargetChainNonprofitInfo] = useState<
    AggregateTargetChainNonprofitInfo[]
  >(partnerData.targetChainNonprofitInfo)
  const dispatch = useDispatch()
  const {
    nonprofits,
    loadingStates,
    user,
  }: { nonprofits: TNonprofit[]; loadingStates: any; user: any } = useSelector((state: any) => ({
    nonprofits: state.nonprofits,
    loadingStates: state.loadingStates,
    user: state.user,
  }))

  useEffect(() => {
    if (nonprofits.length || loadingStates?.nonprofits?.loading || loadingStates?.nonprofits?.error)
      return

    dispatch(fetchNonprofits())
  }, [dispatch, nonprofits, loadingStates])

  // Pull nonprofits from global state, format them into options, and sort.
  useEffect(() => {
    if (nonprofits.length > 0 && nonProfitOptions.length < 1) {
      //if the partner already has nonprofits associated don't add them to the selcetion
      const currentNonprofitIds: number[] = partnerData.targetChainNonprofitInfo.map(
        (targetChainNonprofitInfo: AggregateTargetChainNonprofitInfo) =>
          targetChainNonprofitInfo.nonprofitId
      )

      const newNonprofitOptions =
        nonprofits?.reduce((result: NonprofitOption[], nonprofit: TNonprofit) => {
          if (nonprofit.id && nonprofit.name && !currentNonprofitIds.includes(nonprofit.id)) {
            result.push({ value: nonprofit.id, display: nonprofit.name })
          }
          return result
        }, []) || []

      newNonprofitOptions.sort((a, b) =>
        a.display > b.display ? 1 : b.display > a.display ? -1 : 0
      )
      setNonprofitOptions(newNonprofitOptions)
    }
  }, [
    nonProfitOptions.length,
    nonprofits,
    partnerData.targetChainNonprofitInfo,
    setNonprofitOptions,
  ])

  if (['Super', 'Admin'].indexOf(user.type) == -1) return <PageNotFound />

  if (loadingStates?.nonprofits?.loading) return <div>One moment please...</div>

  if (loadingStates?.nonprofits?.error) return <APIError error={loadingStates.nonprofits.error} />

  const handleInput = (name: string, value: any) => {
    if (name === 'useRegionalization') {
      if (!value) {
        const defaultStore = partnerData.stores.slice(0, 1)
        defaultStore[0].name = partnerData.name
        setPartnerData({ ...partnerData, stores: defaultStore, [name]: value })
      } else {
        setPartnerData({ ...partnerData, [name]: value })
      }
    } else if (name === 'isPercentBased') {
      setPartnerData({ ...partnerData, isInKind: !value, [name]: value })
    } else if (name === 'name' && !partnerData.useRegionalization) {
      const defaultStore: StoreInfo[] = partnerData.stores.slice(0, 1)
      defaultStore[0].name = value
      setPartnerData({ ...partnerData, stores: defaultStore, [name]: value })
    } else {
      setPartnerData({ ...partnerData, [name]: value })
    }
  }

  const handleNonprofitSelection = (value: number) => {
    const updatedNonprofitOptions = [...nonProfitOptions]
    const nonprofitIndex = updatedNonprofitOptions.findIndex(nonprofit => {
      return nonprofit.value === +value
    })
    const mappedNonprofit: NonprofitOption[] = updatedNonprofitOptions.splice(nonprofitIndex, 1)

    setNonprofitOptions(updatedNonprofitOptions)

    let newNonprofitInfo: AggregateTargetChainNonprofitInfo | undefined =
      originalTargetChainNonprofitInfo.find(
        (targetChainNonprofitInfo: AggregateTargetChainNonprofitInfo) => {
          return targetChainNonprofitInfo.nonprofitId === +value
        }
      )

    if (!newNonprofitInfo) {
      newNonprofitInfo = {
        nonprofitId: mappedNonprofit[0].value,
        name: mappedNonprofit[0].display,
        active: true,
      }
    }

    setPartnerData({
      ...partnerData,
      targetChainNonprofitInfo: [...partnerData.targetChainNonprofitInfo, newNonprofitInfo],
    })
  }

  const handleNonprofitRemoval = (value: number) => {
    const newPartnerNonprofitInfo = [...partnerData.targetChainNonprofitInfo]
    const nonprofitIndex = newPartnerNonprofitInfo.findIndex(nonprofit => {
      return nonprofit.nonprofitId === +value
    })

    const removedNonprofit: AggregateTargetChainNonprofitInfo | undefined = newPartnerNonprofitInfo
      .splice(nonprofitIndex, 1)
      .at(0)

    if (!removedNonprofit) return

    setPartnerData({ ...partnerData, targetChainNonprofitInfo: newPartnerNonprofitInfo })

    const newNonProfitOptions: NonprofitOption[] = [
      ...nonProfitOptions,
      { value: removedNonprofit.nonprofitId, display: removedNonprofit.name },
    ]

    newNonProfitOptions.sort((a, b) => (a.display > b.display ? 1 : b.display > a.display ? -1 : 0))

    setNonprofitOptions(newNonProfitOptions)
  }

  const handleCreateNewStore = () => {
    const newStore: StoreInfo = { name: '', zipcodes: '', nonprofitIds: [], type: 2 }
    setPartnerData({
      ...partnerData,
      stores: [...partnerData.stores, newStore],
    })
  }

  const handleRemoveStore = (index: number) => {
    const newStoreArray = [...partnerData.stores]
    newStoreArray.splice(index, 1)

    setPartnerData({ ...partnerData, stores: newStoreArray })
  }

  const getStoreNonprofits = (): NonprofitOption[] => {
    return partnerData.targetChainNonprofitInfo.map(nonprofit => ({
      value: nonprofit.nonprofitId,
      display: nonprofit.name,
    }))
  }

  const handleNonprofitInput = (name: string, value: number, index: number): void => {
    const nonprofits: AggregateTargetChainNonprofitInfo[] = [
      ...partnerData.targetChainNonprofitInfo,
    ]
    const nonprofit: AggregateTargetChainNonprofitInfo = { ...nonprofits[index], [name]: value }
    nonprofits[index] = nonprofit

    setPartnerData({ ...partnerData, targetChainNonprofitInfo: nonprofits })
  }

  const handleStoreInput = (name: string, value: number, index: number) => {
    const stores: StoreInfo[] = [...partnerData.stores]
    const store: StoreInfo = { ...stores[index], [name]: value }
    stores[index] = store

    setPartnerData({ ...partnerData, stores })
  }

  const formFields = [
    { name: 'name', label: 'Name', type: 'text', value: partnerData.name },
    {
      name: 'communityImpactLink',
      label: 'Community Impact Link',
      type: 'text',
      value: partnerData.communityImpactLink || '',
    },
    {
      name: 'logoImage',
      label: 'Logo Image',
      type: 'file',
      file:
        typeof partnerData.logoImage === 'string'
          ? partnerData.logoImage
          : partnerData.logoImage?.name || '',
      value: partnerData.logoImage,
    },
    {
      name: 'isPercentBased',
      label: 'Is Percentage Base',
      type: 'toggle',
      value: partnerData.isPercentBased,
    },
    {
      name: partnerData.isPercentBased ? 'donationPercentage' : 'donationAmount',
      label: partnerData.isPercentBased ? 'Donation Percentage' : 'Donation Amount',
      type: 'text',
      value: partnerData.isPercentBased
        ? partnerData.donationPercentage
        : partnerData.donationAmount,
    },
    {
      name: 'useRegionalization',
      label: 'Use Regionalization',
      type: 'toggle',
      value: partnerData.useRegionalization,
    },
  ]

  const isLoadingCBPartner = loadingStates?.cbPartner?.loading
  const apiError = loadingStates?.cbPartner?.error

  const nonProfitSelectedValues = partnerData.targetChainNonprofitInfo
    .filter(({ name }) => !originalTargetChainNonprofitInfo.some(e => e.name === name))
    .map(nonprofitInfo => ({
      value: nonprofitInfo.nonprofitId,
      display: nonprofitInfo.name,
    }))

  return (
    <div>
      <h1>{formTitle}</h1>

      {showSubmit && submitHandler && (
        <div>
          <BeamButton
            text="SUBMIT"
            handler={() => {
              setShowModal(true)
              submitHandler(formatData(partnerData))
              setOriginalTargetChainNonprofitInfo(partnerData.targetChainNonprofitInfo)
            }}
          />
        </div>
      )}

      <BeamForm fields={formFields} globalChangeHandler={handleInput} />

      <div className={formStyles['beam-form-field-container']}>
        <Grid
          container
          spacing={1}
          style={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Grid item xs={5} sm={3}>
            <div>Nonprofits:</div>
          </Grid>
          <Grid item xs={7} sm={9}>
            <div>
              <strong>Selected Chain Nonprofits</strong>
              <ul>
                {originalTargetChainNonprofitInfo.map(nonprofitInfo => (
                  <li key={nonprofitInfo.nonprofitId}>{nonprofitInfo.name}</li>
                ))}
              </ul>
            </div>
            <strong>Nonprofits to add</strong>
            <BeamDropdown
              useMultipleSelection={true}
              multipleSelectedValues={nonProfitSelectedValues}
              options={nonProfitOptions}
              placeholderDisplay={'Nonprofits'}
              hideClearSelection={true}
              changeHandler={(_, value) => handleNonprofitSelection(value as number)}
              handleMultiClose={(_, value) => handleNonprofitRemoval(value as number)}
            />
          </Grid>
        </Grid>
      </div>

      <div>
        {partnerData.useRegionalization && (
          <div style={{ color: 'red' }}>
            Make sure to provide Engineering with a list of Regions for each Store
          </div>
        )}
        {partnerData.stores.map((store: StoreInfo, index: number) => (
          <div key={index}>
            <CreatePartnerStore
              onClose={() => handleRemoveStore(index)}
              storeInformation={store}
              getNonprofitList={() => getStoreNonprofits()}
              handleStoreInput={(name: string, value: number) =>
                handleStoreInput(name, value, index)
              }
              isDefaultStore={index === 0}
            />{' '}
          </div>
        ))}
        {partnerData.useRegionalization && (
          <BeamButton
            style={{ marginBottom: '5px' }}
            text={'New Store'}
            handler={() => handleCreateNewStore()}
          />
        )}
      </div>

      {partnerData.targetChainNonprofitInfo.map(
        (nonprofitInformation: AggregateTargetChainNonprofitInfo, index: number) => (
          <div key={nonprofitInformation.nonprofitId}>
            <CreatePartnerNonprofit
              nonprofitInformation={nonprofitInformation}
              handleNonprofitInput={(name, value) => handleNonprofitInput(name, value, index)}
            />{' '}
          </div>
        )
      )}

      {showSubmit && submitHandler && (
        <div>
          <BeamButton
            text="SUBMIT"
            handler={() => {
              setShowModal(true)
              submitHandler(formatData(partnerData))
              setOriginalTargetChainNonprofitInfo(partnerData.targetChainNonprofitInfo)
            }}
          />
        </div>
      )}

      {showSubmit && (
        <BeamModal
          title={isLoadingCBPartner ? 'Processing...' : apiError ? 'Error' : 'Success!'}
          titleColor={isLoadingCBPartner || apiError ? '#000' : '#4caf50'}
          open={showModal}
          handleClose={() => setShowModal(false)}>
          <div>
            {apiError ? (
              processApiError(apiError)
            ) : isLoadingCBPartner ? (
              <BeamProgressBar />
            ) : (
              <span>&#127881;</span>
            )}
          </div>
        </BeamModal>
      )}
    </div>
  )
}
