import { SlProgressBar } from '@shoelace-style/shoelace/dist/react'
import cx from 'classnames'

import { BeamDSProps } from '../interface'
import $$ from './beam-progress-bar.module.css'

interface BeamProgressBarProps extends BeamDSProps {
  /**
   * Progress bar label (shows up above the progress bar as well as acts as the a11y label)
   */
  label: string
  /**
   * The percentage value of the bar.  Stick with any number between 0 through 100.
   */
  value: number
  /**
   * Description of the data point presented (shows up below the progress bar on the lower left)
   */
  description?: string
  /**
   * Description of the data point presented (shows up below the progress bar on the lower right)
   */
  secondaryDescription?: string
}

/**
 * Beam Progress Bar component
 */
export const BeamProgressBar = ({
  label,
  value,
  description,
  secondaryDescription,
  ...props
}: BeamProgressBarProps) => {
  return (
    <div className={cx(props.className)}>
      <label className={$$.title}>{label}</label>
      <div className={$$.progressBarContainer}>
        <SlProgressBar value={value} label={label} className={cx($$.progressBar)} />
        <div className={cx($$.percentageLabel, 'beam--progress-bar--percentage-label')}>
          {value}%
        </div>
      </div>
      {description && <span className={$$.description}>{description}</span>}
      {secondaryDescription && <span className={$$.completions}>{secondaryDescription}</span>}
    </div>
  )
}
