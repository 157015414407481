import { axiosRequest } from '../utils/axiosRequest'
import { API_BASE_PATH_PORTAL } from './auth'

const AUTOPAY_API_BASE_URL = `${API_BASE_PATH_PORTAL}/v2/invoices`

export async function removePaymentMethod(): Promise<{
  updatedInDb: boolean
  updatedInStripe: boolean
}> {
  const data = await axiosRequest('POST', `${AUTOPAY_API_BASE_URL}/removePaymentMethod`)
  return data?.data
}
