import { BeamCard } from '../BeamCard'
import { BeamDSProps } from '../interface'
import $$ from './beam-image-data-point-block.module.css'

interface BeamImageDataPointBlockProps extends BeamDSProps {
  /**
   * The data point, usually with a suffix like "7 grapes" or "30% of mice"
   */
  datum: string
  /**
   * Description of data point
   */
  description: string
  /**
   * Icon that displays at the top of the card
   */
  icon: React.ReactNode
  /**
   * Background color of the card. Potentials are based on colors defined in the branding guidelines (in other words, no custom hex values or RGBs)
   */
  backgroundColor?: string
}

/**
 * Image Data Point Block component
 */
export const BeamImageDataPointBlock = ({
  datum,
  description,
  icon,
  backgroundColor,
  ...props
}: BeamImageDataPointBlockProps) => {
  return (
    <BeamCard {...props} backgroundColor={backgroundColor} excludeBorder={true}>
      <div className={`${$$.beamImageDataPointBlock}`}>
        <div className={`${$$.icon}`}>{icon}</div>
        <span className={`${$$.datum}`}>{datum}</span>
        <span className={`${$$.description}`}>{description}</span>
      </div>
    </BeamCard>
  )
}
