import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { gtag } from '../../../components/root/BeamGoogleAnalytics/googleAnalyticsHelpers'
import { useBeamSelector } from '../../../hooks'
import { getCurrentPageTitle } from '../../../utils/getCurrentPageTitle'
import { TSite, TUser } from '../../../utils/types'

interface SideNavLinkProps {
  path: string
  children?: ReactNode
  active?: boolean
  itemLabel?: string
}

export const BeamSideNavigationLink = ({
  path,
  active = false,
  children,
  itemLabel,
}: SideNavLinkProps) => {
  const user: TUser | undefined = useBeamSelector(({ user }) => user)
  const siteFilter: TSite | undefined = useBeamSelector(({ site }) => site)

  const gtagMetadata = {
    chainId: user?.chainId || null,
    userId: user?.id || null,
    siteFilter: siteFilter?.name || null,
    pageWhereEventTriggered: getCurrentPageTitle(),
  }

  function gtagEventName(itemName: string) {
    return `${itemName} clicked`
  }

  return (
    <Link
      to={path}
      onClick={() => {
        gtag(gtagEventName(itemLabel || ''), gtagMetadata)
      }}
      className={active ? 'active' : ''}>
      {children}
    </Link>
  )
}
