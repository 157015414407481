import cx from 'classnames'

import { BeamCard } from '../../../../stories/BeamCard'
import { BeamLoadingIndicator } from '../../../../stories/BeamLoadingIndicator'
import { BeamTooltip } from '../../../../stories/BeamTooltip'
import { BeamDSProps } from '../../../../stories/interface'
import $$ from './beam-metric-input-box.module.css'

export enum MetricInputTypes {
  header = 'header',
  metric = 'metric',
  description = 'description',
}

interface MetricInputTypeHeader {
  type: MetricInputTypes
  label: string
}

interface MetricInputTypeMetric {
  type: MetricInputTypes
  label: string
  value: string
  tooltip: string
  icon: React.ReactNode
}

interface MetricInputTypeDescription {
  type: MetricInputTypes
  text: string
}

interface BeamMetricInputProps extends BeamDSProps {
  /**
   * Array of data we want to present
   */
  data: (MetricInputTypeHeader | MetricInputTypeMetric | MetricInputTypeDescription)[] | null
  /**
   * Indicates the page is loading data
   */
  loading: boolean
  /**
   * Custom background color for the box
   */
  backgroundColor?: string
  /**
   * The button's click behavior
   */
  onClick?: () => void
}

/**
 * Metric Input Box component
 */
export const BeamMetricInputBox = ({
  data,
  loading = true,
  backgroundColor = '--beam-color--lime-100',
  ...props
}: BeamMetricInputProps) => {
  return (
    <BeamCard backgroundColor={backgroundColor} excludeBorder={true}>
      {loading && (
        <div className="flex items-center justify-center w-full h-[145px]">
          <BeamLoadingIndicator />
        </div>
      )}
      {!loading && (
        <>
          <h1 className={cx($$.header, '!my-0')}>Inputs for the Calculation Below</h1>
          {dataProcessor(data)}
        </>
      )}
    </BeamCard>
  )
}

const dataProcessor = (data: any) => {
  const presentation = []
  for (const datum of data) {
    switch (datum.type) {
      case MetricInputTypes.header:
        presentation.push(<h2 className={cx($$.subheader, '!my-1')}>{datum.label}</h2>)
        break
      case MetricInputTypes.metric:
        presentation.push(
          <div className={cx($$.datum, 'flex my-4 text-left')}>
            <div className={cx($$.label, 'grow')}>
              <BeamTooltip content={datum.tooltip}>
                <span className={'border-b border-dashed pb-1.5'}>{datum.label}</span>
              </BeamTooltip>
            </div>

            <div className={cx($$.value, 'flex-none')}>
              {datum.value} <span className={cx('float-right ml-1 w-6')}>{datum.icon}</span>
            </div>
          </div>
        )
        break
      case MetricInputTypes.description:
        presentation.push(<div className={cx($$.description, 'my-1')}>{datum.text}</div>)
        break
      default:
        break
    }
  }

  return <>{presentation}</>
}
