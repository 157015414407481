import cx from 'classnames'

import { BeamLoadingIndicator } from '../../../../stories/BeamLoadingIndicator'

export const CampaignTableLoadingOverlay = (props: { loading: boolean }) => {
  const sharedClasses = cx(
    'absolute inset-0 flex justify-center items-center rounded-base',
    'transition-all ease-in-out duration-200',
    props.loading ? 'opacity-100 visible' : 'opacity-0 invisible'
  )

  return (
    <>
      <div
        aria-label={props.loading ? 'Loading data' : ''}
        className={cx(sharedClasses, 'z-20 backdrop-blur-sm')}>
        <div>
          <BeamLoadingIndicator />
        </div>
      </div>
      <div className={cx(sharedClasses)} />
    </>
  )
}
