import cx from 'classnames'
import { useState } from 'react'

import { useBeamSelector } from '../../../hooks'
import { BeamDataPointBlock } from '../../../stories/BeamDataPointBlock'
import { BeamLoadingIndicator } from '../../../stories/BeamLoadingIndicator'
import { BeamTooltip } from '../../../stories/BeamTooltip'
import { isPositiveNumber } from '../../../utils/helpers/isPositiveNumber'
import { dollarFormat, percentFormat } from '../../../utils/root'
import { TUser } from '../../../utils/types'
import { CenteredContent } from '../../root/CenteredContent'
import { TitleWithTooltip } from '../../root/TitleWithTooltip'
import { ReactComponent as CartIcon } from './assets/cartIcon.svg'
import { ReactComponent as GraphIcon } from './assets/graphIcon.svg'
import { ReactComponent as InfoIcon } from './assets/infoIcon.svg'
import { ReactComponent as UpwardTrendIcon } from './assets/upwardTrendIcon.svg'
import $$ from './overview-page.module.css'
import { overviewTestIds } from './OverviewPage.helper'
import { OptimalRoiMetricsData } from './OverviewPage.types'

function shouldShowCCRMetrics(calculatedData: OptimalRoiMetricsData): boolean {
  // always show ccr metrics for brands using estimated CCR
  if (calculatedData.isEstimatedCCRIncluded && isPositiveNumber(calculatedData.pctCcrLift))
    return true

  return (
    isPositiveNumber(calculatedData.beamCCR) &&
    isPositiveNumber(calculatedData.pctCcrLift) &&
    !!calculatedData.reportPeriodId
  )
}

function shouldWeDisplayAOV(calculatedData: OptimalRoiMetricsData): boolean {
  return (
    isPositiveNumber(calculatedData.averageOrderValueLift) &&
    isPositiveNumber(calculatedData.beamAOVSalesLift)
  )
}

function shouldWeDisplayAOF(calculatedData: OptimalRoiMetricsData): boolean {
  return (
    isPositiveNumber(calculatedData.averageOrderFrequencyLift) &&
    isPositiveNumber(calculatedData.beamOrderFrequencySalesLift)
  )
}

function howManyMetricsAreVisible(calculatedData: OptimalRoiMetricsData): number {
  return [
    shouldWeDisplayAOF(calculatedData),
    shouldWeDisplayAOV(calculatedData),
    shouldShowCCRMetrics(calculatedData),
  ].filter(entry => entry).length
}

function displayDateRange(calculatedData: OptimalRoiMetricsData | null): string {
  // remove "M123" or "Q123" prefixes
  return calculatedData?.roiPeriodDate?.replace(/[Q|M][0-9]./, '').trim() || 'N/A'
}

interface BusinessImpactModuleProps {
  calculatedData: OptimalRoiMetricsData | null
  loading?: boolean
}

export const BusinessImpactModule = ({
  calculatedData,
  loading = true,
}: BusinessImpactModuleProps) => {
  const user = useBeamSelector(({ user }) => user) as TUser
  const [displayExpandedData, setDisplayExpandedData] = useState<boolean>(false)

  function renderMainContent() {
    // This section should not show if there's no data for business impact.
    if (!calculatedData) return <></>

    const pctCcrDisplayValue = percentFormat(calculatedData.pctCcrLift, null, 1, true)

    return (
      <>
        <div
          className={cx('grid grid-cols-1 desktop:grid-cols-3', $$.businessImpactPart1)}
          data-testid={overviewTestIds.blendedRoiMetric}>
          <div data-testid={overviewTestIds.salesLiftRoiMetric}>
            <BeamDataPointBlock
              title={
                <TitleWithTooltip
                  title={
                    'Sales Lift' + (calculatedData.isEstimatedCCRIncluded ? ' (with est. CCR)' : '')
                  }
                  tooltipContent={
                    calculatedData.isEstimatedCCRIncluded ? (
                      <>
                        Sales lift is the total top-line impact of the Beam integration. Sales Lift
                        here is calculated using minimum CCR Lift for brands on Beam (+1%).
                        <br />
                        <br />
                        Note: Approx. Sales lift is conservative, as it excludes top-line gains from
                        increased friend referral or new customers driven from Beam&apos;s B2C app
                      </>
                    ) : (
                      <>
                        Sales lift is the total top-line impact of the Beam integration.
                        <br />
                        <br />
                        Note: Approx. Sales lift is conservative, as it excludes top-line gains from
                        increased friend referral or new customers driven from Beam&apos;s B2C app.
                      </>
                    )
                  }
                />
              }
              datum={calculatedData.salesLift ? `~${dollarFormat(calculatedData.salesLift)}` : '--'}
              datumSize="large"
            />
          </div>

          <div>
            <BeamDataPointBlock
              title={
                <TitleWithTooltip
                  title="ROI against Donations and Beam Fees"
                  tooltipContent={
                    <>
                      Top-line sales impact / (Beam fees + total donations)
                      <br />
                      <br />
                      Note: if your brand was already donating prior to launching the Beam
                      integration, see &quot;ROI against Beam fees&quot; for ROI on just incremental
                      program costs
                    </>
                  }
                />
              }
              datum={`~${calculatedData.blendedROI?.toFixed(1)}x`}
              datumSize="large"
            />
          </div>

          <div data-testid={overviewTestIds.beamRoiMetric}>
            <BeamDataPointBlock
              title={
                <TitleWithTooltip
                  title="ROI against Beam Fees only"
                  tooltipContent={
                    <>
                      Top-line sales lift / Beam fees
                      <br />
                      <br />
                      Note: this is the accurate representation of program ROI for brands that were
                      already donating prior to launch Beam
                    </>
                  }
                />
              }
              datum={calculatedData.beamROI ? `~${calculatedData.beamROI.toFixed(1)}x` : '--'}
              datumSize="large"
            />
          </div>

          <div className="desktop:col-span-3" data-testid={overviewTestIds.roiDetailsView}>
            <button
              className={cx($$.expansionButton, 'text-right w-full underline p-5 leading-5')}
              onClick={() => setDisplayExpandedData(!displayExpandedData)}>
              {displayExpandedData ? 'Collapse' : 'See'} ROI Calculation
            </button>
            <div className={cx(displayExpandedData ? 'block' : 'hidden', $$.expandedStats, 'p-4')}>
              {[
                shouldWeDisplayAOV(calculatedData),
                shouldWeDisplayAOF(calculatedData),
                shouldShowCCRMetrics(calculatedData),
              ].some(checkIfAnyIsTrue => checkIfAnyIsTrue) && (
                <>
                  <div className="grid grid-cols-2 font-bold">
                    <h3>Estimated monthly sales lift driven</h3>
                    <h3 className="text-right">{`~${dollarFormat(calculatedData.salesLift)}`}</h3>
                  </div>
                  {shouldWeDisplayAOV(calculatedData) && (
                    <div className="grid grid-cols-2 text-charcoal-500">
                      <div>Lift from increased Average Order Value</div>
                      <div className="text-right">
                        {dollarFormat(calculatedData.beamAOVSalesLift)}
                      </div>
                    </div>
                  )}
                  {shouldWeDisplayAOF(calculatedData) && (
                    <div className="grid grid-cols-2 text-charcoal-500">
                      <div>Lift from increased Average Order Frequency</div>
                      <div className="text-right">
                        {dollarFormat(calculatedData.beamOrderFrequencySalesLift)}
                      </div>
                    </div>
                  )}
                  {shouldShowCCRMetrics(calculatedData) && (
                    <div className="grid grid-cols-2 text-charcoal-500">
                      {calculatedData.isEstimatedCCRIncluded ? (
                        <div>
                          <em>
                            Estimated lift from increased Cart Completion Rate (+
                            {pctCcrDisplayValue})
                          </em>
                        </div>
                      ) : (
                        <div>Lift from increased Cart Completion Rate</div>
                      )}
                      <div className="text-right">
                        {calculatedData.isEstimatedCCRIncluded ? (
                          <em>{dollarFormat(calculatedData.ccrSalesLift)}</em>
                        ) : (
                          dollarFormat(calculatedData.ccrSalesLift)
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
              <div className="grid grid-cols-2 font-bold">
                <h3>Total monthly costs</h3>
                <h3 className="text-right">{dollarFormat(calculatedData.totalFees ?? 0)}</h3>
              </div>
              <div className="grid grid-cols-2 text-charcoal-500">
                <div>
                  Donations{' '}
                  {!user.partner.inKindDonations.displayInKindDonations
                    ? ''
                    : '(shipping cost of in-kind donations)'}
                </div>
                <div className="text-right">{dollarFormat(calculatedData.donations ?? 0)}</div>
                <div>Beam Fees</div>
                <div className="text-right">{dollarFormat(calculatedData.beamFees ?? 0)}</div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={cx(
            `grid grid-cols-1 space-x-0 space-y-4 desktop:grid-cols-${
              howManyMetricsAreVisible(calculatedData) <= 2 ? 2 : 3
            } desktop:space-y-0 desktop:space-x-4`,
            $$.businessImpactPart2
          )}>
          {shouldWeDisplayAOV(calculatedData) && (
            <div className={`${$$.block}`}>
              <BeamDataPointBlock
                title={
                  <TitleWithTooltip
                    title="Average Order Value Lift"
                    tooltipContent={`The difference between average dollars spent per transaction, within the report period date range, between transactions that didn't include a redemption and transactions that included a nonprofit selection`}
                  />
                }
                datum={`+${percentFormat(calculatedData.averageOrderValueLift, null, 1)}`}
                description={
                  <span className={`iconOverrideOverviewPage ${$$.roiMetricComparisonSubheading}`}>
                    <UpwardTrendIcon /> ({dollarFormat(calculatedData.nonbeamAOV, 2)} without Beam
                    vs {dollarFormat(calculatedData.beamAOV, 2)} with Beam)
                  </span>
                }
                icon={<GraphIcon />}
                backgroundColor={'--beam-color--lime-100'}
              />
            </div>
          )}

          {shouldWeDisplayAOF(calculatedData) && (
            <div className={`${$$.block}`}>
              <BeamDataPointBlock
                title={
                  <TitleWithTooltip
                    title="Average Order Frequency Lift"
                    tooltipContent={`The difference between the average number of transactions per unique customer where a donation was not redeemed and the average number of transactions per unique customer where a donation was redeemed`}
                  />
                }
                datum={`+${percentFormat(calculatedData.averageOrderFrequencyLift, null, 1)}`}
                description={
                  <span className={`iconOverrideOverviewPage ${$$.roiMetricComparisonSubheading}`}>
                    <UpwardTrendIcon />({calculatedData.nonBeamOrderFrequency?.toFixed(3) || 'N/A'}{' '}
                    without Beam vs {calculatedData.beamOrderFrequency?.toFixed(3) || 'N/A'} With
                    Beam)
                  </span>
                }
                icon={<GraphIcon />}
                backgroundColor={'--beam-color--lime-100'}
              />
            </div>
          )}

          {shouldShowCCRMetrics(calculatedData) && (
            <div className={$$.block} data-testid={overviewTestIds.roiCcrLiftBlock}>
              <BeamDataPointBlock
                title={
                  <TitleWithTooltip
                    title={
                      calculatedData.isEstimatedCCRIncluded
                        ? 'Est. Cart Completion Rate Lift (CCR)'
                        : 'Cart Completion Rate Lift (CCR)'
                    }
                    tooltipContent={
                      <>
                        Cart completion rate (CCR) is the inverse of cart abandonment rate: the % of
                        unique customers who reach cart that convert (total conversions / total
                        unique carts).
                        <br />
                        <br />
                        To isolate the impact Beam has on CCR, we look at the difference between the
                        CCR of your customers checking out without making a donation vs. site-wide
                        conversion. To illustrate the most conservative case, our calculation
                        assumes we drive half the lift observed: (CCR lift = (CCR site-wide - CCR
                        without Beam)/2)
                      </>
                    }
                  />
                }
                datum={
                  <div className="flex items-center">
                    <span className={'pr-2'}>
                      {calculatedData.isEstimatedCCRIncluded ? (
                        <em>{`~${pctCcrDisplayValue}`}</em>
                      ) : (
                        `+${pctCcrDisplayValue}`
                      )}
                    </span>
                    {calculatedData.isEstimatedCCRIncluded && (
                      <BeamTooltip
                        content={`Since Beam doesn't currently have access to ${user.partner.name}'s cart data, we've provided a very conservative view of sales lift assuming we only drive a ${pctCcrDisplayValue} improvement in ${user.partner.name}'s cart completion rate, a figure we've consistently exceeded in A/B tests`}>
                        <div className={'flex items-center'}>
                          <InfoIcon className="inline" />
                        </div>
                      </BeamTooltip>
                    )}
                  </div>
                }
                description={
                  <span className={`iconOverrideOverviewPage ${$$.roiMetricComparisonSubheading}`}>
                    <UpwardTrendIcon />({percentFormat(calculatedData.totalCCR, null, 0)} Overall vs{' '}
                    {percentFormat(calculatedData.nonBeamCCR, null, 0)} Non-Beam)
                  </span>
                }
                icon={<CartIcon />}
                backgroundColor={'--beam-color--lime-100'}
              />
            </div>
          )}
        </div>
      </>
    )
  }

  const roiDateRangePeriod = displayDateRange(calculatedData)

  return (
    <div className={cx($$.businessImpactSection, { 'h-[800px] desktop:h-[450px]': loading })}>
      <div className="grid grid-cols-1">
        <div className="my-auto">
          <h2 className="mb-2">Recent ROI Highlights</h2>
        </div>

        <p className={cx($$.businessImpactDateRange, 'mb-[24px]')}>
          ROI calculation from {roiDateRangePeriod}. These estimates are conservative and represent
          the minimum potential ROI.
        </p>
      </div>

      {loading && (
        <CenteredContent>
          <BeamLoadingIndicator />
        </CenteredContent>
      )}

      {!loading && calculatedData && renderMainContent()}

      {!loading && !calculatedData && (
        <CenteredContent>
          <p className="font-bold">There was a problem fetching this data.</p>
        </CenteredContent>
      )}
    </div>
  )
}
