import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { usePrevious } from '../../../hooks'
import { Maybe, TRemoteConfig } from '../../../utils/types'
import {
  WidgetSupportedLanguages,
  widgetSupportedLanguageSelections,
} from '../../../utils/types/WidgetLanguages'
import { WidgetName, WidgetNameEnum } from '../../../utils/types/WidgetNames'
import BeamButton from '../../root/BeamButton'
import BeamDropdown, { DropdownOption } from '../../root/BeamDropdown'
import BeamInput from '../../root/BeamInput'
import { BeamModal } from '../../root/BeamModal'
import { BeamSwitch } from '../../root/BeamSwitch'
import { RenderWidget } from './RenderWidget'
import { StatusBanner, StatusBannerType } from './StatusBanner'
import { WidgetConfigInput } from './WidgetConfigInput'
import {
  fetchWidgetData,
  loadWidgetScript,
  saveConfig,
  updateStyleElContent,
  widgetStyleElementId,
} from './WidgetConfigPage.helpers'

const WIDGET_NAMES: WidgetName[] = [
  'community-impact',
  'cumulative-impact',
  'impact-overview',
  'redeem-transaction',
  'select-nonprofit',
  'product-details-page',
]
const localStorageId = 'beam_active_widget'
const webSdkVersions = [
  { display: '1.0.0', value: '1.0.0' },
  { display: '1.0', value: '1.0' },
]

function debounced(callback: () => any, wait = 300) {
  clearTimeout(window?.timeoutId)
  const timeoutId = setTimeout(() => callback(), wait)
  window.timeoutId = timeoutId
}

const LowerRegion = ({
  draftData,
  toggleShowLiveWidget,
  showLiveWidget,
  updateConfig,
  selectedWidget,
  chainId,
  lang,
}: {
  draftData: Maybe<TRemoteConfig['config']['draft']['web']>
  toggleShowLiveWidget: () => void
  showLiveWidget: boolean
  updateConfig: (name: string, value: string) => void
  selectedWidget: WidgetName
  chainId: Maybe<string>
  lang: WidgetSupportedLanguages
}) => {
  const [customBackgroundHex, setCustomBackgroundHex] = useState<string>('')

  const onCustomBackgroundInput = (value: string) => {
    const cleanedVal = value.trim().replace('#', '')
    if (!cleanedVal) {
      return setCustomBackgroundHex('')
    }

    const newVal = `#${cleanedVal}`
    setCustomBackgroundHex(newVal)
  }

  if (!draftData) return null

  return (
    <div className="relative grid w-full grid-cols-12">
      <div className="relative flex flex-col col-span-4">
        <h3>Config</h3>
        <WidgetConfigInput
          type="text"
          label="Title"
          name="title"
          value={draftData?.title}
          changeHandler={updateConfig}
        />
        <WidgetConfigInput
          type="text"
          label="Description"
          name="description"
          value={draftData?.description}
          changeHandler={updateConfig}
        />
        {draftData?.theme &&
          Object.entries(draftData.theme).map(([key, val]) => {
            return (
              <WidgetConfigInput
                key={key}
                type="text"
                label={key}
                name={key}
                value={val}
                changeHandler={updateConfig}
              />
            )
          })}
      </div>

      <div className="relative col-start-5 col-span-full">
        <div className="flex justify-between">
          <div className="flex items-center space-x-4">
            <h3>Preview</h3>
            <div>
              <BeamSwitch
                on={showLiveWidget}
                handleClick={toggleShowLiveWidget}
                label="Show Live Config"
                styleOverrides={{ paddingBottom: 0, margin: 0 }}
              />
            </div>
          </div>

          <div className="">
            <BeamInput
              label="Background(hex)"
              name="customBackground"
              value={customBackgroundHex}
              changeHandler={(_, val) => onCustomBackgroundInput(val)}
              labelContainerStyleOverride={{
                maxWidth: 'none',
              }}
              inputContainerStyle={{
                flexWrap: 'nowrap',
              }}
              labelStyle={{ fontSize: '14px', maxWidth: 'none' }}
              inputStyle={{ padding: '6px', width: '120px', justifyContent: 'flex-start' }}
            />
          </div>
        </div>
        <div
          className="mt-4 p-2.5 sticky top-0"
          style={
            customBackgroundHex
              ? { backgroundColor: customBackgroundHex, fontFamily: 'Arial' }
              : {
                  // Transparent background checkered pattern
                  backgroundImage:
                    'linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%),linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%)',
                  backgroundSize: '20px 20px',
                  backgroundPosition: '0px 0px, 10px 10px',
                  fontFamily: 'Arial',
                }
          }>
          {chainId && <RenderWidget widgetName={selectedWidget} chainId={chainId} lang={lang} />}
        </div>
      </div>
    </div>
  )
}

export const WidgetConfigPage = () => {
  const [showLiveWidget, setShowLiveWidget] = useState(false)
  const [selectedWidget, setSelectedWidget] = useState<WidgetName>('community-impact')
  const [selectedVersion, setSelectedVersion] = useState('1.0.0')

  const [widgetData, setWidgetData] = useState<TRemoteConfig | null>(null)
  const [draftData, setDraftData] = useState<TRemoteConfig['config']['draft']['web'] | null>(null) // the draft data being edited
  const [loadingData, setLoadingData] = useState(false)

  const [successMessage, setSuccessMessage] = useState<string | null>(null)
  const [submissionError, setSubmissionError] = useState<string | null>(null)
  const [validationErrors, setValidationErrors] = useState<string[]>([])
  const [lang, setLang] = useState<WidgetSupportedLanguages>('en')

  const [saveConfirmationModal, setSaveConfirmationModal] =
    useState<'saveDraft' | 'publish' | null>(null)
  const [widgetSwitchConfirmationModal, setWidgetSwitchConfirmationModal] =
    useState<WidgetName | null>(null)

  const { id: chainId }: any = useParams()
  const previousVersion = usePrevious(selectedVersion)
  // const prevSelectedWidget = usePrevious(selectedWidget)

  // on mount, check localStorage for the last active widget, otherwise load the default.
  useEffect(() => {
    const activeWidget = localStorage.getItem(localStorageId) as WidgetName | null
    const isValid = Object.keys(WidgetNameEnum).includes(activeWidget || '')

    if (activeWidget && isValid && selectedWidget !== activeWidget) {
      loadWidgetScript(activeWidget)
      setSelectedWidget(activeWidget)
      return
    }

    const defaultWidget: WidgetName = 'community-impact'
    loadWidgetScript(defaultWidget)
    setSelectedWidget(defaultWidget)
    localStorage.setItem(localStorageId, defaultWidget)

    return () => {
      // cleanup before unmounting
      const widgetScript = document.querySelector(`beam-${selectedWidget}-script`)
      const styleEl = document.querySelector(`style#${widgetStyleElementId}`)
      widgetScript?.remove()
      styleEl?.remove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // on mount, fetch widgetData
  useEffect(() => {
    if (loadingData || widgetData?.id) return

    fetchDataHandler({ chainId, selectedVersion, selectedWidget })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // update style element when draftData changes
  useEffect(() => {
    if (draftData?.theme && !showLiveWidget) {
      debounced(() => {
        updateStyleElContent(draftData.theme)
      }, 1000)
    }
  }, [draftData?.theme, showLiveWidget])

  function fetchDataHandler({
    selectedVersion,
    selectedWidget,
    chainId,
  }: {
    selectedWidget: WidgetName
    chainId: string | number
    selectedVersion: string
  }) {
    setLoadingData(true)

    fetchWidgetData(selectedWidget, chainId, selectedVersion)
      .then(data => {
        setWidgetData(data)
        setDraftData(data?.config?.draft?.web || null)
        setLoadingData(false)
      })
      .catch(error => {
        console.error('There was a problem fetching widget data --> ', error)
        setLoadingData(false)
      })
  }

  const handleOnInputChange = (name: string, value: string) => {
    if (!draftData) return

    let newDraftData: TRemoteConfig['config']['draft']['web']
    if (name === 'title' || name === 'description') {
      newDraftData = {
        ...draftData,
        [name]: value,
      }
    } else {
      newDraftData = {
        ...draftData,
        theme: {
          ...draftData?.theme,
          [name]: value,
        },
      }
    }
    return setDraftData(newDraftData)
  }

  function handleWidgetSelect(newWidget: WidgetName) {
    loadWidgetScript(newWidget, () => {
      setSelectedWidget(newWidget)
      fetchDataHandler({ selectedWidget: newWidget, chainId, selectedVersion })
      localStorage.setItem(localStorageId, newWidget)
    })
  }

  function handleVersionSelect(newVersion: string) {
    if (loadingData || previousVersion === selectedVersion) return

    setSelectedVersion(newVersion)
    fetchDataHandler({ selectedVersion: newVersion, chainId, selectedWidget })
  }

  const onClickShowLive = useCallback(() => {
    const newVal = !showLiveWidget
    setShowLiveWidget(newVal)

    if (newVal && widgetData?.config.live.web.theme) {
      updateStyleElContent(widgetData.config.live.web.theme)
      return
    } else if (draftData) {
      updateStyleElContent(draftData?.theme)
      return
    }

    return null
  }, [draftData, showLiveWidget, widgetData?.config.live.web.theme])

  const saveDraft = async () => {
    if (!draftData) return

    const { data, error } = await saveConfig('draft', {
      chainId: +chainId,
      config: draftData,
      widgetName: selectedWidget,
      widgetVersion: selectedVersion,
    })

    if (error?.errorMessage && error?.invalidKeys) {
      const errors = error.invalidKeys.map(
        ({ key, msg }: { key: string; msg: string }) => `${key}: ${msg}`
      )
      return setValidationErrors([...validationErrors, ...errors])
    } else if (error?.errorMessage) {
      return setSubmissionError(error.errorMessage)
    } else if (error?.message) {
      return setSubmissionError(error.message)
    }

    if (data) {
      setDraftData(data.config.draft.web)
      setSuccessMessage('Draft successfully updated!')
    }

    // TODO:
    // - Show loading indicator while it saves
  }

  const publishLive = async () => {
    if (!draftData) return

    const { data, error } = await saveConfig('live', {
      chainId: +chainId,
      widgetName: selectedWidget,
      widgetVersion: selectedVersion,
    })

    if (error?.invalidKeys) {
      const errors = error.invalidKeys.map(
        ({ key, msg }: { key: string; msg: string }) => `${key}: ${msg}`
      )
      return setValidationErrors([...validationErrors, ...errors])
    } else if (error?.message) {
      return setSubmissionError(error.message)
    }

    if (data) {
      setWidgetData(data)
      setDraftData(data.config.draft.web)
      setSuccessMessage('Successfully promoted to production!')
    }
  }

  const handleBannerClose = useCallback(
    (type: StatusBannerType, msg: string) => {
      if (type === 'success') {
        setSuccessMessage(null)
      }
      if (msg === submissionError) {
        setSubmissionError(null)
        return
      }
      if (type === 'error') {
        const newValidationErrors = validationErrors.filter(err => err !== msg)
        setValidationErrors(newValidationErrors)
      }
    },
    [submissionError, validationErrors]
  )

  return (
    <>
      {widgetSwitchConfirmationModal && (
        <BeamModal
          handleClose={() => setWidgetSwitchConfirmationModal(null)}
          open
          title="Switch Widget"
          titleClasses="text-xl font-bold">
          <div className="mt-4 text-center mb-9">
            <p>Are you sure you want to switch widgets?</p>
            <p className="font-bold">Any unsaved changes will be lost.</p>
          </div>

          <BeamButton
            handler={() => {
              handleWidgetSelect(widgetSwitchConfirmationModal)
              setWidgetSwitchConfirmationModal(null)
            }}
            variant="filled"
            text="Confirm"
          />
        </BeamModal>
      )}
      {saveConfirmationModal && (
        <BeamModal
          handleClose={() => setSaveConfirmationModal(null)}
          open
          title={saveConfirmationModal === 'saveDraft' ? 'Save Draft' : 'Promote to Production'}
          titleClasses="text-xl font-bold">
          {saveConfirmationModal === 'saveDraft' && (
            <p className="mt-4 mb-9">Are you sure you want to save this draft?</p>
          )}
          {saveConfirmationModal === 'publish' && (
            <p className="mt-4 mb-9">
              Are you sure you want to publish draft to live? Any unsaved draft changes will be
              lost.
            </p>
          )}

          <BeamButton
            handler={() => {
              saveConfirmationModal === 'saveDraft'
                ? saveDraft().then(() => setSaveConfirmationModal(null))
                : publishLive().then(() => setSaveConfirmationModal(null))
            }}
            variant="filled"
            text="Confirm"
          />
        </BeamModal>
      )}
      <div className="min-h-full pt-14">
        <div className="flex justify-between w-full">
          <div className="flex flex-wrap gap-4">
            <BeamDropdown
              hideClearSelection={true}
              placeholderDisplay={'Web SDK Version'}
              options={webSdkVersions}
              selectedValue={selectedVersion}
              changeHandler={(_, value) => {
                handleVersionSelect(value)
              }}
              padding={{ top: '5px', right: '5px', bottom: '5px', left: '5px' }}
            />
            <BeamDropdown
              hideClearSelection={true}
              placeholderDisplay={'Widget Name'}
              options={WIDGET_NAMES.map(widgetName => ({ display: widgetName, value: widgetName }))}
              selectedValue={selectedWidget}
              changeHandler={(_, value) => {
                setWidgetSwitchConfirmationModal(value as WidgetName)
              }}
              padding={{ top: '5px', right: '5px', bottom: '5px', left: '5px' }}
            />
            <BeamDropdown
              hideClearSelection={true}
              options={Object.keys(widgetSupportedLanguageSelections).map<DropdownOption>(key => {
                return {
                  display: key,
                  value: widgetSupportedLanguageSelections[key],
                }
              })}
              placeholderDisplay={'Language'}
              changeHandler={(_, value) => {
                setLang(value as WidgetSupportedLanguages)
              }}
              selectedValue={lang}
              padding={{ top: '5px', right: '5px', bottom: '5px', left: '5px' }}
            />
          </div>

          <div className="flex flex-col space-y-4">
            <BeamButton
              variant="outlined"
              text="Save Draft"
              handler={() => setSaveConfirmationModal('saveDraft')}
              style={{ width: 'auto', height: 'auto', padding: '10px' }}
            />
            <BeamButton
              variant="gradient"
              text="Publish"
              handler={() => setSaveConfirmationModal('publish')}
              style={{ width: 'auto', height: 'auto', padding: '10px' }}
              selected
            />
          </div>
        </div>

        <div className="flex flex-col mt-12">
          <div className="relative mb-4">
            <div className="absolute top-0 right-0 z-40 flex flex-col space-y-2">
              {successMessage && (
                <StatusBanner type="success" onClose={handleBannerClose} message={successMessage} />
              )}
              {submissionError && (
                <StatusBanner type="error" onClose={handleBannerClose} message={submissionError} />
              )}
              {!!validationErrors.length &&
                validationErrors.map(err => (
                  <StatusBanner key={err} type="error" onClose={handleBannerClose} message={err} />
                ))}
            </div>
          </div>
          <LowerRegion
            draftData={draftData}
            toggleShowLiveWidget={onClickShowLive}
            showLiveWidget={showLiveWidget}
            updateConfig={handleOnInputChange}
            selectedWidget={selectedWidget}
            chainId={chainId}
            lang={lang}
          />
        </div>
      </div>
    </>
  )
}
