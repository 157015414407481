import { useEffect, useState } from 'react'

import { API_BASE_PATH_PORTAL } from '../../api/auth'
import { useBeamSelector } from '../../hooks'
import { axiosRequest } from '../../utils/axiosRequest'
import { EUserType } from '../../utils/types'
import { BeamProgressBar } from '../root/BeamProgressBar'
import { PageNotFound } from '../root/PageNotFound'
import { WelcomeNote } from '../root/WelcomeNote'

export const PayPalApproval = () => {
  const [gettingBillingAgreement, setGettingBillingAgreement] = useState(false)
  const [billingAgreementError, setBillingAgreementError] = useState('')
  const [success, setSuccess] = useState(false)

  const user = useBeamSelector(({ user }) => user)

  // Get billing agreement
  useEffect(() => {
    if (!gettingBillingAgreement && billingAgreementError) {
      axiosRequest('post', `${API_BASE_PATH_PORTAL}/v2/invoices/createBillingAgreement`)
        .then(() => {
          setGettingBillingAgreement(false)
          setSuccess(true)
        })
        .catch(error => {
          setBillingAgreementError(JSON.stringify(error))
          setSuccess(false)
        })
    }
  }, [gettingBillingAgreement, billingAgreementError])

  if (user?.type !== EUserType.Executive) return <PageNotFound />

  return (
    <div>
      <WelcomeNote firstName={user.firstName} lastName={user.lastName} />
      {gettingBillingAgreement && (
        <div>
          <div>Finishing up your billing agreement now. Hang on...</div>
          <BeamProgressBar />
        </div>
      )}
      {success && (
        <div>
          You’ve successfully authorized donation fulfillment via PayPal Giving Fund! 🎉 You’ll now
          be able to make donations with just two clicks via the “Invoices” tab when they become
          due.
        </div>
      )}
      <div>{billingAgreementError}</div>
    </div>
  )
}
