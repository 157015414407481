import { SlCopyButton } from '@shoelace-style/shoelace/dist/react'
import cx from 'classnames'
import { useEffect, useRef, useState } from 'react'

import { useBeamSelector } from '../../hooks'
import { BeamTooltip } from '../../stories/BeamTooltip'
import { AccountType, TUser } from '../../utils/types'

interface InternalDevPanelProps {
  data: {
    label: string
    value: any
  }[]
}

enum DevPanelStatusEnum {
  open = 'open',
  closed = 'closed',
}

const LOCAL_STORAGE_VAR_NAME = 'dev_panel'

/**
 * Developer panel intended for Beam internal use. Include this in any page to provide easy access to info used for debugging.
 * This component is only shown when the user inputs ";;" anywhere in the page.
 */
export const InternalDevPanel = ({ data }: InternalDevPanelProps) => {
  const [enabled, setEnabled] = useState<boolean>(false)
  const input = useRef('')
  const timeout = useRef<NodeJS.Timeout | undefined>(undefined)
  const user: TUser | undefined = useBeamSelector(({ user }) => user)

  // Grabs the dev panel state from localStorage if it exists
  useEffect(() => {
    localStorage.getItem(LOCAL_STORAGE_VAR_NAME) === DevPanelStatusEnum.open
      ? setEnabled(true)
      : setEnabled(false)
  }, [])

  function handlePanelToggle() {
    setEnabled(prev => {
      const newStatus = !prev
      localStorage.setItem(
        LOCAL_STORAGE_VAR_NAME,
        newStatus ? DevPanelStatusEnum.open : DevPanelStatusEnum.closed
      )

      return newStatus
    })
  }

  // Captures the user inputting ";;" in quick succession
  useEffect(() => {
    function keydownHandler(event: KeyboardEvent) {
      clearTimeout(timeout.current) // clear the previous timer

      input.current += event.key

      if (input.current.endsWith(';;')) {
        handlePanelToggle()
        input.current = ''
      } else {
        // reset the input if there is a delay of more than 500ms between key presses
        timeout.current = setTimeout(() => {
          input.current = ''
        }, 500)
      }
    }

    document.addEventListener('keydown', keydownHandler)

    return () => {
      clearTimeout(timeout.current)
      document.removeEventListener('keydown', keydownHandler)
    }
  }, [])

  const codeSnippetClasses = 'text-cherry-600 bg-light-grey rounded-sm'

  if (user?.accountType !== AccountType.internal) {
    return null
  }
  if (!enabled) return null

  return (
    <div
      className={
        'bg-charcoal-100 backdrop-opacity-25 rounded-base p-4 fixed top-0 right-0 z-50 max-w-[33vw]'
      }>
      <div>
        <BeamTooltip content={'Hide Dev Panel'}>
          <button onClick={handlePanelToggle}>❌</button>
        </BeamTooltip>
      </div>
      <div className={cx('overflow-y-scroll, overflow-x-hidden, max-h-[66vh]')}>
        <h1 className={'my-0 text-2xl'}>🚧 DEV PANEL 🚧</h1>
        <p>
          ℹ️ Type <code className={codeSnippetClasses}>;;</code> to show/hide this panel.
        </p>
        {data.map(({ label, value }, index) => {
          return (
            <>
              <div className={'mb-2'}>
                <strong>{label}: </strong>
                <br />
                <code className={codeSnippetClasses}>{value}</code> <SlCopyButton value={value} />
              </div>
              {index === data.length - 1 ? <></> : <div className={'bg-black h-[1px] w-full'} />}
            </>
          )
        })}
      </div>
    </div>
  )
}
