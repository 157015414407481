import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useBeamSelector } from '../../hooks'
import { createUser } from '../../redux/thunks/adminThunks'
import { fetchPartners } from '../../redux/thunks/adminThunks'
import { fetchUser } from '../../redux/thunks/authThunks'
import { processApiError } from '../../utils/root'
import { TPPPartner } from '../../utils/types'
import BeamButton from '../root/BeamButton'
import { BeamModal } from '../root/BeamModal'
import { BeamProgressBar } from '../root/BeamProgressBar'
import { BEAM_COLORS } from '../root/constants'
import { PageNotFound } from '../root/PageNotFound'

export const CreateUser = () => {
  const [newUser, setNewUser] = useState({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
    type: 'Executive',
    partnerId: '',
    useTwoFactor: true,
  })
  const [errors, setErrors] = useState({
    email: { error: false, message: '' },
    firstName: { error: false, message: '' },
    lastName: { error: false, message: '' },
    password: { error: false, message: '' },
    confirmPassword: { error: false, message: '' },
    type: { error: false, message: '' },
    partnerId: { error: false, message: '' },
    useTwoFactor: { error: false, message: '' },
  })
  const [showModal, setShowModal] = useState(false)

  // FIXME: Remove all `any` when whe have typedefs
  const user = useBeamSelector(({ user }) => user)
  const loadingStates = useBeamSelector(({ loadingStates }) => loadingStates) as any
  const partners = useBeamSelector(({ partners }) => partners) as TPPPartner[] | null
  const dispatch = useDispatch()

  useEffect(() => {
    if (!user) {
      dispatch(fetchUser())
    }
  }, [user, dispatch])

  useEffect(() => {
    if (!partners?.length) {
      dispatch(fetchPartners())
    }
  }, [partners, dispatch])

  const handleInput = (e: any) => {
    if (e.target.name === 'useTwoFactor') {
      setNewUser({ ...newUser, useTwoFactor: !newUser.useTwoFactor })
    } else {
      setNewUser({ ...newUser, [e.target.name]: e.target.value })
      setErrors({ ...errors, [e.target.name]: e.target.value })
    }
  }

  const handleSubmit = () => {
    const userToCreate = {
      ...newUser,
      partnerId: +newUser.partnerId || undefined,
    }

    if (typeof userToCreate.password !== 'string' || userToCreate.password.length < 8) {
      return setErrors({
        ...errors,
        password: { error: true, message: 'password must be at least 8 characters' },
      })
    }
    if (!userToCreate.password.match(/[!#$@]/)) {
      return setErrors({
        ...errors,
        password: {
          error: true,
          message: 'password must contain at least one special character (!, #, $, @)',
        },
      })
    }
    if (!userToCreate.password.match(/[ABCDEFGHIJKLMNOPQRSTUVWXYZ]/)) {
      return setErrors({
        ...errors,
        password: {
          error: true,
          message: 'password must contain at least one upper case',
        },
      })
    }
    if (!userToCreate.password.match(/[abcdefghijklmnopqrstuvwxyz]/)) {
      return setErrors({
        ...errors,
        password: {
          error: true,
          message: 'password must contain at least one lower case',
        },
      })
    }
    if (!userToCreate.password.match(/[0123456789]/)) {
      return setErrors({
        ...errors,
        password: {
          error: true,
          message: 'password must contain at least one number',
        },
      })
    }
    if (userToCreate.confirmPassword !== userToCreate.password) {
      return setErrors({
        ...errors,
        confirmPassword: {
          error: true,
          message: 'passwords must match',
        },
      })
    }
    if (!userToCreate.partnerId && userToCreate.type !== 'Admin') {
      return setErrors({
        ...errors,
        partnerId: {
          error: true,
          message: 'Partner is required for non Beam users',
        },
      })
    }
    if (!/.+@[^@]+\.[^@]{2,}$/.test(userToCreate.email)) {
      return setErrors({
        ...errors,
        email: {
          error: true,
          message: 'Enter a valid email address',
        },
      })
    }

    setShowModal(true)
    dispatch(createUser({ user: userToCreate }))
  }

  const generatePassword = () => {
    const upper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const nums = '0123456789'
    const lower = 'abcdefghijklmnopqrstuvwxyz'
    let numSequence = ''
    let i = 0
    while (i < 5) {
      numSequence += nums.charAt(Math.floor(Math.random() * nums.length))
      i++
    }
    const pw =
      upper.charAt(Math.floor(Math.random() * upper.length)) +
      lower.charAt(Math.floor(Math.random() * lower.length)) +
      '#' +
      numSequence

    setNewUser({ ...newUser, password: pw, confirmPassword: pw })
  }

  const isCreatingUser = loadingStates?.user?.loading
  const apiError = loadingStates?.user?.error

  if (['Super', 'Admin'].indexOf(user.type) == -1) return <PageNotFound />

  return (
    <div>
      <h1>Create a User</h1>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={5}>
          <TextField
            required
            label="First Name"
            name="firstName"
            onChange={handleInput}
            error={errors.firstName.error}
            helperText={errors.firstName.message}
            value={newUser.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            required
            label="Last Name"
            name="lastName"
            onChange={handleInput}
            error={errors.lastName.error}
            helperText={errors.lastName.message}
            value={newUser.lastName}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <FormControl required>
            <InputLabel id="userType">User Type</InputLabel>
            <Select
              labelId="userType-label"
              id="userTypeSelect"
              value={newUser.type}
              defaultValue={newUser.type}
              name="type"
              onChange={handleInput}>
              {[
                { value: 'Admin', label: 'Admin (Beam)' },
                { value: 'Executive', label: 'Executive (Partner)' },
                { value: 'Engineering', label: 'Engineer (Partner)' },
              ].map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5}>
          <FormControl>
            <InputLabel id="partnerId">Partner</InputLabel>
            <Select
              style={{ minWidth: '120px' }}
              labelId="partnerId-label"
              id="partnerIdSelect"
              value={newUser.partnerId}
              defaultValue={newUser.partnerId}
              name="partnerId"
              onChange={handleInput}
              error={errors.partnerId.error}>
              {partners
                ?.sort((a, b) => {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
                  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
                  return 0
                })
                .map(partner => (
                  <MenuItem key={partner.id} value={partner.id}>
                    {partner.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            required
            label="Email"
            name="email"
            onChange={handleInput}
            error={errors.email.error}
            helperText={errors.email.message}
            value={newUser.email}
          />
        </Grid>
        <Grid container item xs={12} sm={5} direction="column" justifyContent="space-between">
          <Grid item style={{ padding: '12px 0px' }}>
            <TextField
              required
              label="Password"
              name="password"
              onChange={handleInput}
              error={errors.password.error}
              helperText={errors.password.message}
              value={newUser.password}
            />
            <Grid item style={{ padding: '12px 0px' }}>
              <TextField
                required
                label="Confirm Password"
                name="confirmPassword"
                onChange={handleInput}
                error={errors.confirmPassword.error}
                helperText={errors.confirmPassword.message}
                value={newUser.confirmPassword}
              />
            </Grid>
            <Grid item style={{ padding: '12px 0px' }}>
              <BeamButton
                handler={generatePassword}
                text="GENERATE PASSWORD"
                style={{ width: '200px', height: '35px' }}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5}>
          <FormControlLabel
            control={
              <Checkbox
                checked={newUser.useTwoFactor}
                onChange={handleInput}
                name="useTwoFactor"
                // primary="true"
                style={{ color: BEAM_COLORS.orange }}
              />
            }
            label="Use two factor auth"
          />
        </Grid>
        <Grid item xs={12}>
          <BeamButton handler={handleSubmit} text="Submit" />
        </Grid>
      </Grid>
      <BeamModal
        title={isCreatingUser ? 'Submitting User...' : apiError ? 'Error' : 'User Created'}
        titleColor={isCreatingUser || apiError ? '#000' : '#4caf50'}
        open={showModal}
        handleClose={() => setShowModal(false)}>
        <div>
          {apiError ? (
            processApiError(apiError)
          ) : isCreatingUser ? (
            <BeamProgressBar />
          ) : (
            <span>&#127881;</span>
          )}
        </div>
      </BeamModal>
    </div>
  )
}
