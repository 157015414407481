/**
 * Was receiving a Rollup error in playwright when attempting to use the auto generated ReactComponent
 * so the svg was manually converted into JSX to allow tests to pass
 */
export const ProgressBar = (props: any) => {
  return (
    <svg {...props} height="11" width="100%" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <rect y="2.5" width="100%" height="6" rx="3" fill="#1F1815" fillOpacity="0.1" />
        <rect y="2.5" width="54%" height="6" rx="3" fill="#454A47" />
      </g>
      <defs>
        <clipPath id="clip0_9036_32363">
          <rect y="2.5" width="311" height="6" rx="3" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
