import { Grid } from '@material-ui/core'
import { useCallback, useEffect, useState } from 'react'

import formStyles from '../../style/root/beam-form-fields.module.css'
import BeamDropdown from '../root/BeamDropdown'
import BeamForm from '../root/BeamForm'
import { NonprofitOption, StoreInfo } from './CreateCentralBackendPartner'

const CreatePartnerStore = ({
  storeInformation,
  getNonprofitList,
  handleStoreInput,
  onClose,
  isDefaultStore,
}: {
  storeInformation: StoreInfo
  getNonprofitList: () => NonprofitOption[]
  handleStoreInput: (name: string, value: number) => void
  onClose: () => void
  isDefaultStore: boolean
}) => {
  const [nonprofitArray, setNonprofitArray] = useState<Array<NonprofitOption>>([])

  const handleInput = useCallback(
    (name: string, value: any) => {
      handleStoreInput(name, value)
    },
    [handleStoreInput]
  )

  useEffect(() => {
    const storeNonprofitArray = storeInformation.nonprofitIds.concat(nonprofitArray)
    const nonprofitList = getNonprofitList()

    if (storeNonprofitArray.length > nonprofitList.length) {
      const nonprofitSet = new Set()
      nonprofitList.forEach(nonprofitOption => {
        return nonprofitSet.add(nonprofitOption.value)
      })

      let index = storeInformation.nonprofitIds.findIndex((n: any) => !nonprofitSet.has(n.value))

      if (index !== -1) {
        const tempSelected = storeInformation.nonprofitIds.slice()
        tempSelected.splice(index, 1)
        handleInput('nonprofitIds', tempSelected)
      } else {
        index = nonprofitArray.findIndex(nonprofitOption => {
          return !nonprofitSet.has(nonprofitOption.value)
        })
        const tempNonprofitArray = nonprofitArray.slice()
        tempNonprofitArray.splice(index, 1)
        setNonprofitArray(tempNonprofitArray)
      }
    }

    if (storeNonprofitArray.length < nonprofitList.length) {
      if (storeNonprofitArray.length === 0) {
        setNonprofitArray([...nonprofitList])
      } else {
        const storeNonprofitSet = new Set()

        storeNonprofitArray.forEach(nonprofitOption => {
          return storeNonprofitSet.add(nonprofitOption.value)
        })

        nonprofitList.forEach(nonprofitOption => {
          if (!storeNonprofitSet.has(nonprofitOption.value)) {
            setNonprofitArray([...nonprofitArray, nonprofitOption])
          }
        })
      }
    }
  }, [getNonprofitList, handleInput, nonprofitArray, storeInformation.nonprofitIds])

  const handleNonprofitSelection = (value: number) => {
    const updatedNonprofitArray = nonprofitArray.slice()
    const nonprofitIndex = updatedNonprofitArray.findIndex(nonprofitOption => {
      return nonprofitOption.value === +value
    })
    const mappedNonprofit = updatedNonprofitArray.splice(nonprofitIndex, 1)

    setNonprofitArray(updatedNonprofitArray)
    handleInput('nonprofitIds', [
      ...storeInformation.nonprofitIds,
      {
        value: mappedNonprofit[0].value,
        display: mappedNonprofit[0].display,
      },
    ])
  }

  const handleNonprofitRemoval = (value: number) => {
    const updatedNonprofitArray = storeInformation.nonprofitIds.slice()
    const nonprofitIndex = updatedNonprofitArray.findIndex((nonprofit: any) => {
      return nonprofit.value === +value
    })
    const mappedNonprofit = updatedNonprofitArray.splice(nonprofitIndex, 1)

    if (typeof mappedNonprofit[0] === 'number') {
      return
    }
    handleInput('nonprofitIds', updatedNonprofitArray)
    setNonprofitArray([
      ...nonprofitArray,
      { value: mappedNonprofit[0].value, display: mappedNonprofit[0].display },
    ])
  }

  const formFields = [
    {
      name: 'name',
      label: 'Name',
      type: 'text',
      value: storeInformation.name || '',
      detailText: isDefaultStore
        ? '* if not using regionalization store name should be the same as partner name'
        : '',
    },
    {
      name: 'displayName',
      label: 'Display Name',
      type: 'text',
      value: storeInformation.displayName || '',
    },
    // {
    //   name: 'zipcodes',
    //   label: 'Zipcodes',
    //   type: 'text',
    //   value: storeInformation.zipcodes,
    // },
    {
      name: 'type',
      label: 'Type',
      type: 'text',
      value: storeInformation.type || '',
      detailText: '* 1 = Physical Store | 2 = Region-Specific Store | 3 = Online Store',
      errorText: 'type must be 1,2, or 3',
      inputError: storeInformation?.type
        ? !(
            +storeInformation.type === 1 ||
            +storeInformation.type === 2 ||
            +storeInformation.type === 3
          )
        : false,
    },
  ]

  return (
    <div>
      <div style={{ display: 'flex' }}>
        {!isDefaultStore && (
          <div>
            <button
              onClick={() => onClose()}
              style={{
                borderRadius: '10px',
                backgroundColor: '#e4e4e4',
                cursor: 'pointer',
                borderColor: 'transparent',
              }}>
              x
            </button>
          </div>
        )}
        <h3>{storeInformation.name || 'New Store'} </h3>
      </div>
      <BeamForm fields={formFields} globalChangeHandler={handleInput} />
      <div className={formStyles['beam-form-field-container']}>
        <Grid
          container
          spacing={1}
          style={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Grid item xs={5} sm={3}>
            <div>Nonprofits:</div>
          </Grid>
          <Grid item xs={7} sm={9}>
            <BeamDropdown
              useMultipleSelection={true}
              multipleSelectedValues={storeInformation.nonprofitIds}
              options={nonprofitArray}
              placeholderDisplay={'Nonprofits'}
              hideClearSelection={true}
              changeHandler={(_, value) => handleNonprofitSelection(value as number)}
              handleMultiClose={(_, value) => handleNonprofitRemoval(value as number)}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default CreatePartnerStore
