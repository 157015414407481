import axios, { AxiosError } from 'axios'
import { useEffect, useState } from 'react'

import { API_BASE_PATH_PORTAL } from '../../../api/auth'
import { BeamButton } from '../../../stories/BeamButton'
import { BeamModal } from '../../../stories/BeamModal'
import { BeamTextarea } from '../../../stories/BeamTextarea'
import { BeamTextfield } from '../../../stories/BeamTextfield'
import { BeamInputChangeEvent } from '../../../stories/BeamTextfield/BeamTextfield'
import { axiosRequest } from '../../../utils/axiosRequest'
import { CustomError } from '../../../utils/types/CustomError'
import $$ from './nonprofit-page.module.css'

interface TRequestNewNonprofitData {
  cause: string
  nationalOrLocal: string
  swaps: string
  duration: string
  specificNonprofit: string
  additionalInfo: string
}

const initialEmailData = {
  cause: '',
  nationalOrLocal: '',
  swaps: '',
  duration: '',
  specificNonprofit: '',
  additionalInfo: '',
}

const sendRequestNewNonprofitEmail = async (
  requestData: TRequestNewNonprofitData,
  partnerId: number
) => {
  await axiosRequest(
    'post',
    `${API_BASE_PATH_PORTAL}/v2/support/request-new-nonprofit`,
    {
      cause: requestData.cause === '' ? undefined : requestData.cause,
      nationalOrLocal: requestData.nationalOrLocal === '' ? undefined : requestData.nationalOrLocal,
      swaps: requestData.swaps === '' ? undefined : requestData.swaps,
      duration: requestData.duration === '' ? undefined : requestData.duration,
      specificNonprofit:
        requestData.specificNonprofit === '' ? undefined : requestData.specificNonprofit,
      additionalInfo: requestData.additionalInfo === '' ? undefined : requestData.additionalInfo,
    },
    { partnerId }
  )
}

export const RequestNewNonprofitCauseModal = ({
  partnerId,
  openToast,
  setMessage,
}: {
  partnerId: number
  openToast: () => void
  setMessage: (e: CustomError | null) => void
}) => {
  const [open, setOpen] = useState(false)
  const [emailData, setEmailData] = useState<TRequestNewNonprofitData>(initialEmailData)

  const captureValue: BeamInputChangeEvent = e => {
    const target = e.target
    if (target?.name) {
      const newEmailData = {
        ...emailData,
        [target.name]: target.value,
      }

      setEmailData(newEmailData)
    }
  }

  const submitData = async () => {
    try {
      await sendRequestNewNonprofitEmail(emailData, partnerId)
      setEmailData(initialEmailData)
      setMessage(null)
      openToast()
    } catch (error: any) {
      let customError: CustomError | null = null

      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error
        if (axiosError.response && axiosError.response.status === 400 && axiosError.response.data) {
          if (Array.isArray(axiosError.response.data)) {
            // If response data is an array
            const errorMessages = axiosError.response.data
              .map((errorItem: any) => errorItem.message)
              .join(', ')
            customError = {
              code: '400',
              detail: errorMessages,
            }
          } else if (typeof axiosError.response.data === 'object') {
            // If response data is an object
            customError = {
              code: '400',
              detail: axiosError.response.data.message || 'An error occurred',
            }
          } else {
            // If response data is not an array or object
            customError = {
              code: '400',
              detail: 'An unknown error occurred. Please try again later',
            }
          }
        }
      }

      setMessage(customError)
      openToast()
    }
  }

  useEffect(() => {
    setOpen(true)
  }, [])

  return (
    <div>
      <BeamModal
        label="Request Support for a New Cause or Nonprofit"
        open={open}
        body={
          <>
            <div className={$$.modalDescription}>
              Want to work with a new nonprofit? Please send us a request so we can add them to your
              list of nonprofit partners.
            </div>
            <BeamTextfield
              name="cause"
              label="What’s the cause you’d like to support?"
              onChange={captureValue}
              value={emailData.cause}
            />
            <BeamTextfield
              name="nationalOrLocal"
              label="Would you like national or local nonprofit options?"
              onChange={captureValue}
              value={emailData.nationalOrLocal}
            />
            <BeamTextfield
              name="swaps"
              label="I would prefer that Beam swaps out the following nonprofit(s). If you do not have a preference, we will swap out your least supported nonprofit(s)."
              onChange={captureValue}
              value={emailData.swaps}
            />
            <BeamTextfield
              name="duration"
              label="How long would you like to support this cause/nonprofit?"
              onChange={captureValue}
              value={emailData.duration}
            />
            <BeamTextfield
              name="specificNonprofit"
              label="Add the specific nonprofit here if you know it. (Optional)"
              onChange={captureValue}
              value={emailData.specificNonprofit}
            />
            <BeamTextarea
              name="additionalInfo"
              label="Additional Information (Optional)"
              placeholder="Message here"
              onChange={captureValue as any}
              value={emailData.additionalInfo}
            />
          </>
        }
        footer={
          <>
            <BeamButton
              label="Send Request"
              variant="elevated"
              className={$$.button}
              slot={'footer'}
              onClick={async () => await submitData()}
            />
          </>
        }
      />
    </div>
  )
}
