import { getV2ApiUrl } from '../../../api/auth'
import { axiosRequest } from '../../../utils/axiosRequest'

export interface CustomerPaymentDataFromStripe {
  customerId: string
  paymentMethod: {
    type: string
    last4: string
    country: string | null
    postalCode: string | null
  }
}

export async function fetchPaymentInfo() {
  const data = await axiosRequest('get', `${getV2ApiUrl()}/invoices/paymentInfo`)
  return data?.data as CustomerPaymentDataFromStripe
}
