import { IconComponentProps } from './IconComponentProps'

export const MoneyBagIcon = ({ fill = '--beam-color--sky-800' }: IconComponentProps) => {
  const color = `var(${fill})`

  return (
    <svg
      style={{ width: '20px' }}
      viewBox="0 0 35 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3981 1H22.3981C23.1937 1 23.9568 1.31607 24.5194 1.87868C25.082 2.44129 25.3981 3.20435 25.3981 4.00001C25.3981 5.85652 24.6606 7.637 23.3478 8.94976C22.0351 10.2625 20.2546 11 18.3981 11H16.3981C14.5416 11 12.7611 10.2625 11.4483 8.94976C10.1356 7.637 9.39807 5.85652 9.39807 4.00001C9.39807 3.20435 9.71414 2.44129 10.2768 1.87868C10.8394 1.31607 11.6024 1 12.3981 1Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.40002 29V27C1.40002 22.7566 3.08574 18.6869 6.08632 15.6863C9.08691 12.6857 13.1566 11 17.4 11C21.6435 11 25.7132 12.6857 28.7138 15.6863C31.7144 18.6869 33.4001 22.7566 33.4001 27V29C33.4001 31.1218 32.5572 33.1566 31.0569 34.6569C29.5566 36.1572 27.5218 37 25.4001 37H9.40004C7.2783 37 5.24347 36.1572 3.74317 34.6569C2.24288 33.1566 1.40002 31.1218 1.40002 29Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
