import { format } from 'date-fns'

export function formatDate(date: string, pattern: string) {
  const formattedDate = new Date(date)
  if (isNaN(formattedDate.getTime())) {
    return date
  }

  formattedDate.setDate(formattedDate.getDate())
  return format(formattedDate, pattern)
}
