interface CalculateSixMontSalesLiftProps {
  incrementalFirstSubscriptionsAttributedToBeam: number
  monthsActive: number
  maxMonthCount: number
  incrementalLtv: number
}
export function calculateSixMonthSalesLift({
  incrementalFirstSubscriptionsAttributedToBeam,
  monthsActive,
  maxMonthCount,
  incrementalLtv,
}: CalculateSixMontSalesLiftProps) {
  return (
    incrementalFirstSubscriptionsAttributedToBeam * (monthsActive / maxMonthCount) * incrementalLtv
  )
}
