import cx from 'classnames'
import { useContext, useEffect, useState } from 'react'

import { API_BASE_PATH_PORTAL } from '../../../api/auth'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { BeamButton } from '../../../stories/BeamButton'
import { BeamDropdown } from '../../../stories/BeamDropdown'
import { BEAM_DROPDOWN_OPTION } from '../../../stories/BeamDropdown/BeamDropdown'
import {
  BeamMultiSelectDropdownEventTarget,
  BeamMultiSelectDropdownOnChangeHandler,
} from '../../../stories/BeamDropdown/BeamDropdown.types'
import { BeamModal } from '../../../stories/BeamModal'
import { BeamTextfield } from '../../../stories/BeamTextfield'
import { BeamInputChangeEvent } from '../../../stories/BeamTextfield/BeamTextfield'
import { axiosRequest } from '../../../utils/axiosRequest'
import { formatDate } from '../../../utils/helpers/formatDate'
import {
  OverviewNonprofitImpactObject,
  OverviewPartnerImpactResponse,
  TUser,
} from '../../../utils/types'
import { CustomError } from '../../../utils/types/CustomError'
import { createAutoBoost, updateAutoBoost } from './AutoBoostANonprofitModal.api'
import { TCreateAutoBoostNonprofitData } from './AutoBoostANonprofitModal.types'
import { CampaignPreview } from './CampaignPreview'
import { ModalContext } from './CampaignsPage/CampaignPage'
import $$ from './nonprofit-page.module.css'
import { PromoModal } from './PromoModalsComponent/PromoModalsComponent'
import { UpgradeWidgetCTA } from './UpgradeWidgetCTA'

const MAX_CHARACTERS_CAMPAIGN_NAME = 23

const getInitialBoostData = (promo: TCreateAutoBoostNonprofitData | null | undefined) => {
  return {
    promoId: promo?.promoId || null,
    name: promo?.name || '',
    promoText: promo?.promoText || '',
    availableToWholeChain: promo?.availableToWholeChain || false,
    boostAllNonprofits: promo?.boostAllNonprofits || false,
    sdkId: promo?.sdkId || null,
    boostedStoreNonprofitIds: promo?.boostedStoreNonprofitIds || [],
    startTime: promo?.startTime || '',
    endTime: promo?.endTime || '',
    multiplier: promo?.multiplier || 0,
    color: promo?.color || '',
  }
}

const initialStartDate = () => {
  const today = new Date()
  today.setHours(0, 0)

  return today.toISOString().slice(0, 11) + '00:00'
}

const initialEndDate = () => {
  const today = new Date()
  today.setHours(0, 0)

  return today.toISOString().slice(0, 11) + '23:59'
}

const boostAmountDropdownOptions: BEAM_DROPDOWN_OPTION[] = [
  { label: '2x', value: '2' },
  { label: '3x', value: '3' },
  { label: '4x', value: '4' },
  { label: '5x', value: '5' },
  { label: '10x', value: '10' },
]

function getActiveNonprofitDropdownOptions(
  nonprofits: OverviewNonprofitImpactObject[]
): BEAM_DROPDOWN_OPTION[] {
  const activeNonprofits: BEAM_DROPDOWN_OPTION[] = nonprofits
    .filter(nonprofit => nonprofit.active)
    .map(nonprofit => ({
      label: nonprofit.name,
      value: `${nonprofit.storeNonprofit}`,
    }))

  return activeNonprofits
}

function getTomorrowDate() {
  const today = new Date()
  today.setHours(23, 59)

  const isoDate = today.toISOString().slice(0, 19)
  return isoDate
}

function calculateMaxDate(startDate: string) {
  const startDateObject = new Date(startDate)
  const endDateObject = new Date(startDateObject)
  endDateObject.setDate(startDateObject.getDate() + 31)

  const year = endDateObject.getFullYear()
  const month = (endDateObject.getMonth() + 1).toString().padStart(2, '0')
  const day = endDateObject.getDate().toString().padStart(2, '0')
  const hours = endDateObject.getHours().toString().padStart(2, '0')
  const minutes = endDateObject.getMinutes().toString().padStart(2, '0')
  const seconds = endDateObject.getSeconds().toString().padStart(2, '0')

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
}

export async function fetchNonprofitImpacts(
  partnerId: number,
  callback: (nonprofits: OverviewNonprofitImpactObject[]) => void
) {
  try {
    const res = await axiosRequest('get', `${API_BASE_PATH_PORTAL}/partners/impact/${partnerId}`)
    const impactData = res?.data as OverviewPartnerImpactResponse
    callback(impactData?.nonprofits)
  } catch (error: any) {
    console.error(error)
  }
}

export const AutoBoostANonprofitModal = ({
  partnerId,
  user,
  openToast,
  onCloseHandler,
  setMessage,
  openNonprofitModalAction = () => {
    return
  },
  promo,
}: {
  partnerId: number
  user: TUser
  openToast: () => void
  onCloseHandler: () => void
  setMessage: (e: CustomError | null) => void
  openNonprofitModalAction?: () => void
  promo?: TCreateAutoBoostNonprofitData | null
}) => {
  const { setModal } = useContext(ModalContext)

  const featureFlags = useFeatureFlags()
  const canUseNewPromoUI = featureFlags['can-use-new-promo-ui']
  const [open, setOpen] = useState(false)
  const [autoBoostData, setAutoBoostData] = useState<TCreateAutoBoostNonprofitData>(() =>
    getInitialBoostData(promo)
  )
  const [nonprofits, setNonprofits] = useState<OverviewNonprofitImpactObject[]>([])

  // fetches nonprofit data. TODO: When multi-store promos are supported, nonprofits data should be passed from the nonprofits page response.
  useEffect(() => {
    if (!user.partnerId) return

    fetchNonprofitImpacts(user.partnerId, nonprofitsResponse => {
      setNonprofits(nonprofitsResponse)
    })
  }, [user.partnerId])

  useEffect(() => {
    setOpen(true)
  }, [])

  useEffect(() => {
    setAutoBoostData(getInitialBoostData(promo))
  }, [promo])

  const captureAutoBoostModalValue = (e: any) => {
    const target =
      typeof e.target?.value === 'string'
        ? (e.target as HTMLInputElement)
        : (e.target as BeamMultiSelectDropdownEventTarget)

    if (target?.name) {
      let inputValue = target.value

      if (target.name === 'name') {
        inputValue = inputValue.slice(0, 50)
      }

      const newAutoBoostData = {
        ...autoBoostData,
        [target.name]: inputValue,
      }
      setAutoBoostData(newAutoBoostData)
    }
  }

  const handleColorChange = (val: string) => {
    let formattedVal = val
    if (val.length >= 1 && val[0] !== '#') {
      formattedVal = '#' + val
    }
    setAutoBoostData({
      ...autoBoostData,
      color: formattedVal,
    })
  }

  const submitAutoBoostData = async () => {
    try {
      // promo text is no longer used in new promo UI
      const promoText = canUseNewPromoUI
        ? undefined
        : generatePromoText(activeNonprofitDropdownOptions)

      if (autoBoostData.promoId) {
        await updateAutoBoost({ requestData: autoBoostData, partnerId, user, promoText })
      } else {
        await createAutoBoost({ requestData: autoBoostData, partnerId, user, promoText })
      }
      setAutoBoostData(getInitialBoostData)
      setOpen(false)
      setMessage(null)
      setModal(PromoModal.NoModal)
      openToast()
      onCloseHandler()
    } catch (error: any) {
      let customError: CustomError | null = null
      if (error?.message && error?.code) {
        customError = {
          code: error.code,
          detail: error.message,
        }
      } else {
        customError = {
          code: 'UnknownError',
          detail: 'An error occurred while processing your request. Please try again later.',
        }
      }
      setMessage(customError)
      openToast()
    }
  }

  const areAllInputsFilled = () => {
    const { boostedStoreNonprofitIds, endTime, multiplier, name, startTime, color } = autoBoostData
    // color only available in new promo UI. skip validation on old form
    const isValidColorHex = /^#[0-9A-F]{6}$/i.test(color)
    const isValidColor = !canUseNewPromoUI || (color.length > 1 && isValidColorHex)

    return (
      boostedStoreNonprofitIds.length > 0 &&
      multiplier !== 0 &&
      endTime.trim() !== '' &&
      name.trim() !== '' &&
      name.trim().length <= MAX_CHARACTERS_CAMPAIGN_NAME &&
      startTime.trim() !== '' &&
      isValidColor
    )
  }

  const generatePromoText = (nonprofitIdToNameMap: BEAM_DROPDOWN_OPTION[]) => {
    const { boostedStoreNonprofitIds, multiplier, endTime, name } = autoBoostData

    const areAllNonprofitsSelected = (
      boostedStoreNonprofitIds: number[],
      nonprofitIdToNameMap: BEAM_DROPDOWN_OPTION[]
    ): boolean => {
      return boostedStoreNonprofitIds.length === nonprofitIdToNameMap.length
    }

    const concatNonprofitsNames = (boostedStoreNonprofitIds: number[]): string => {
      const nonprofitNames = boostedStoreNonprofitIds
        .map(id => {
          const nonprofitObject = nonprofitIdToNameMap.find(item => item.value === String(id))
          return nonprofitObject ? nonprofitObject.label.trim() : null
        })
        .filter(nonprofitName => nonprofitName !== null)
        .join(', ')

      return nonprofitNames
    }

    const nonprofitsText =
      boostedStoreNonprofitIds.length > 0
        ? areAllNonprofitsSelected(boostedStoreNonprofitIds, nonprofitIdToNameMap)
          ? 'all donations'
          : `donations to ${concatNonprofitsNames(boostedStoreNonprofitIds)}`
        : 'donations to [Nonprofits]'

    const campaignNameText = name ? `${name}` : '[Campaign Name]'
    const multiplierText = multiplier ? `${multiplier}x` : '[multiplier]'
    const endDateText = endTime ? `${formatDate(endTime, 'MM/dd')}` : '[end date]'

    return `For ${campaignNameText}, we're boosting ${nonprofitsText} by ${multiplierText} until ${endDateText}`
  }

  useEffect(() => {
    setOpen(true)
  }, [])

  const activeNonprofitDropdownOptions: BEAM_DROPDOWN_OPTION[] =
    getActiveNonprofitDropdownOptions(nonprofits)

  return (
    <BeamModal
      label="Boost a Nonprofit"
      open={open}
      onCloseCallback={() => onCloseHandler()}
      body={
        <>
          <div className={$$.modalDescription}>
            Select existing active nonprofit(s) for an automated boost. If you need to swap or add a
            new nonprofit,{' '}
            <button
              className={$$.linkToBoostACause}
              onClick={() => {
                setOpen(false)
                openNonprofitModalAction()
              }}>
              request a new nonprofit.
            </button>
          </div>
          <BeamDropdown
            options={activeNonprofitDropdownOptions}
            label={`Active Nonprofit(s)`}
            placeholder="Select Nonprofit to Boost"
            multiple
            name={'boostedStoreNonprofitIds'}
            value={autoBoostData.boostedStoreNonprofitIds?.map(storeNonprofitId =>
              String(storeNonprofitId)
            )}
            onChange={captureAutoBoostModalValue as BeamMultiSelectDropdownOnChangeHandler}
            hoist={true}
            className={$$.modalDropDown}
          />
          <BeamDropdown
            options={boostAmountDropdownOptions}
            placeholder="Select a Boost Amount"
            label={`Boost Amount`}
            name={'multiplier'}
            value={String(autoBoostData.multiplier)}
            onChange={captureAutoBoostModalValue}
            hoist={true}
            className={$$.modalDropDown}
          />
          <div className={cx($$.modalTextBoxContainer, 'grid grid-cols-9')}>
            <div className={cx($$.inputLabel, 'col-span-9')}>Boost Time Frame</div>

            <div className={cx($$.modalSubtext, 'col-span-9')}>Boosts can last up to 31 days</div>

            <BeamTextfield
              type="datetime-local"
              placeholder="start Date"
              name={`startTime`}
              className={cx($$.dateRange, 'col-span-4')}
              onChange={captureAutoBoostModalValue}
              value={autoBoostData.startTime ? autoBoostData.startTime : initialStartDate()}
              includeTime={true}
              min={getTomorrowDate()}
            />
            <div className={cx($$.toLabel, 'flex items-center justify-center')}>to</div>
            <BeamTextfield
              type="datetime-local"
              placeholder="end Date"
              name={`endTime`}
              className={cx($$.dateRange, 'col-span-4')}
              onChange={captureAutoBoostModalValue}
              value={autoBoostData.endTime ? autoBoostData.endTime : initialEndDate()}
              includeTime={true}
              min={autoBoostData.startTime ? autoBoostData.startTime : getTomorrowDate()}
              max={calculateMaxDate(autoBoostData.startTime)}
              disabled={!autoBoostData.startTime}
            />
          </div>
          {canUseNewPromoUI ? (
            <PreviewNew
              handleColorChange={handleColorChange}
              campaignName={autoBoostData.name}
              campaignColor={autoBoostData.color}
              multiplier={autoBoostData.multiplier}
              captureAutoBoostModalValue={captureAutoBoostModalValue}
            />
          ) : (
            <PreviewOld
              promoText={generatePromoText(activeNonprofitDropdownOptions)}
              captureAutoBoostModalValue={captureAutoBoostModalValue}
              campaignName={autoBoostData.name}
            />
          )}
        </>
      }
      footer={
        <BeamButton
          label="Confirm Boost"
          role="submit"
          variant="basic"
          className={$$.button}
          slot={'footer'}
          disabled={!areAllInputsFilled()}
          onClick={async () => await submitAutoBoostData()}
        />
      }
    />
  )
}

const PreviewNew = ({
  campaignColor,
  campaignName,
  multiplier,
  captureAutoBoostModalValue,
  handleColorChange,
}: {
  campaignColor: string
  campaignName: string
  multiplier: number
  captureAutoBoostModalValue: BeamInputChangeEvent
  handleColorChange: (val: string) => void
}) => {
  const campaignNameInputOverLimit = campaignName.length > MAX_CHARACTERS_CAMPAIGN_NAME
  const overLimitClassName = campaignNameInputOverLimit ? $$.overLimit : ''
  return (
    <>
      <BeamTextfield
        name="name"
        label="Campaign Name"
        placeholder="e.g. “Breast Cancer Awareness”, “This Week Only!”"
        onChange={captureAutoBoostModalValue}
        value={campaignName}
        className={$$.modalTextBoxContainer}
      />
      <div className={cx($$.campaignNameCharacterCount, overLimitClassName)}>
        {campaignName.length}/{MAX_CHARACTERS_CAMPAIGN_NAME} character maximum
      </div>
      <div className={cx($$.modalTextBoxContainer)}>
        <div className={$$.inputLabel}>Campaign Color</div>
        <div className={cx($$.modalSubtext, 'pt-2')}>
          Tip: Use eye catching WCAG accessible high contrast color
        </div>
        <BeamTextfield
          name="color"
          onChange={e => handleColorChange(e.target.value)}
          value={campaignColor}
          placeholder="Hex Code #"
          className="pt-2"
        />
      </div>

      <div className={cx($$.inputLabel, $$.modalTextBoxContainer)}>Campaign Preview</div>
      <CampaignPreview color={campaignColor} multiplier={multiplier} campaignName={campaignName} />
    </>
  )
}

const PreviewOld = ({
  promoText,
  campaignName,
  captureAutoBoostModalValue,
}: {
  promoText: string
  campaignName: string
  captureAutoBoostModalValue: BeamInputChangeEvent
}) => {
  return (
    <>
      <BeamTextfield
        name="name"
        label="Campaign Name"
        placeholder="ie. Breast Cancer Awareness Month"
        onChange={captureAutoBoostModalValue}
        value={campaignName}
        className={$$.modalTextBoxContainer}
      />
      <div className={$$.modalPromoPreview}>
        <b className={$$.promoTextTitle}>
          Copy that will appear in the Beam integration at checkout:
        </b>
        <div className={$$.promoText}>{promoText}</div>
        <UpgradeWidgetCTA />
      </div>
    </>
  )
}
