import { ComponentType, Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Redirect, Route } from 'react-router-dom'

import { REDESIGN_ROOT_PATH } from '../../helpers'
import { useBeamSelector } from '../../hooks'
import { TUser } from '../../utils/types'
import { LoadingPage } from './LoadingPage'

interface PrivateRouteProps {
  component: ComponentType<any>
  exact?: boolean
  location?: any
  path: string
}

/**
 * A wrapper around base react component that checks whether a user is logged in before passing them along to their component
 */
export const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {
  const user = useBeamSelector(({ user }) => user) as TUser
  const accessToken = useBeamSelector(({ accessToken }) => accessToken) as string

  return (
    <Route
      {...rest}
      render={props => {
        if (!accessToken || !user?.type) {
          return (
            <Redirect
              to={{
                pathname: `${REDESIGN_ROOT_PATH}/login`,
                search: props.location.search,
                state: { from: props.location, urlParams: props.location.search },
              }}
            />
          )
        }

        // For users who try to access any other page besides the new redesign...
        if (
          user?.partner?.name &&
          !props.location.pathname.match(new RegExp(`${REDESIGN_ROOT_PATH}/`))
        ) {
          return (
            <Redirect
              to={{
                pathname: `${REDESIGN_ROOT_PATH}/overview`,
                search: props.location.search,
                state: { from: props.location, urlParams: props.location.search },
              }}
            />
          )
        }

        // For users who are not allowed to see the redesign, but tries to anyway...
        if (
          !user?.partner?.name &&
          props.location.pathname.match(new RegExp(`${REDESIGN_ROOT_PATH}/`))
        ) {
          return (
            <Redirect
              to={{
                pathname: `/`,
                search: props.location.search,
                state: { from: props.location, urlParams: props.location.search },
              }}
            />
          )
        }

        return (
          <ErrorBoundary fallback={<div />}>
            <Suspense fallback={<LoadingPage />}>
              <Component {...props} />
            </Suspense>
          </ErrorBoundary>
        )
      }}
    />
  )
}
