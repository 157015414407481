import { BEAM_DROPDOWN_OPTION } from '../../../stories/BeamDropdown/BeamDropdown'

export enum INVOICE_STATUS {
  paid = 'Paid',
  past_due = 'Past Due',
  outstanding = 'Outstanding',
  partially_paid = 'Part Paid',
  payment_failed = 'Failed',
}

export const invoiceStatusDropdownOptions = () => {
  const options: BEAM_DROPDOWN_OPTION[] = []

  Object.values(INVOICE_STATUS).map((value: string) => {
    options.push({ label: value, value: value })
  })

  return options
}

export const invoiceInvoicerDropdownOptions = [
  { label: 'Beam', value: 'beam' },
  { label: 'Nonprofit', value: 'nonprofit' },
]
