export const EditPencilDisabled = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00008 1.99999L10.0001 3.99999M6.66675 11.3333H12.0001M1.33341 8.66666L0.666748 11.3333L3.33341 10.6667L11.0574 2.94266C11.3074 2.69262 11.4478 2.35354 11.4478 1.99999C11.4478 1.64644 11.3074 1.30736 11.0574 1.05733L10.9427 0.942659C10.6927 0.692697 10.3536 0.552277 10.0001 0.552277C9.64653 0.552277 9.30745 0.692697 9.05741 0.942659L1.33341 8.66666Z"
      stroke="#979797"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
