import { REDESIGN_ROOT_PATH } from '../../../helpers'
import BeamButton from '../../root/BeamButton'

export const SalesforceOathErrorPage = () => {
  const hrefUrl = `${REDESIGN_ROOT_PATH}/salesforce/authorize`
  return (
    <div className="pt-6">
      <h2>
        An issue occurred while attempting to link with Salesforce account. If this issue persists,
        please contact a Beam admin.
      </h2>
      <BeamButton text="Try Again" href={hrefUrl} openInNewTab={false} />
    </div>
  )
}
