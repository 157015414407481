import { Dispatch, SetStateAction } from 'react'
import { useHistory } from 'react-router'

import { PaypalGivingFundStatusEnums } from '../PaypalGivingFundPage'
import { activateAutopay, updatePaymentMethod } from './PaymentsEmailsModal.api'
import { PaymentsSetupModalsEnums } from './PaymentsSetupModals'

interface AutopaySuccessfulProps {
  history: ReturnType<typeof useHistory>
  changeActiveModal: (newModal: PaymentsSetupModalsEnums | null) => void
  isPaymentMethodUpdate?: boolean
}

export const autopaySuccessful = async ({
  history,
  changeActiveModal,
  isPaymentMethodUpdate,
}: AutopaySuccessfulProps): Promise<{ success: boolean; skipped?: boolean }> => {
  const setupIntentParamName = 'setup_intent'
  const clientSecretParamName = 'setup_intent_client_secret'
  const redirectStatusParamName = 'redirect_status'
  const urlParams = new URLSearchParams(window.location.search)
  const setupIntentId = urlParams.get(setupIntentParamName)
  const setupIntentClientSecret = urlParams.get(clientSecretParamName)

  if (!setupIntentId || !setupIntentClientSecret) {
    return { success: false, skipped: true }
  }

  try {
    if (isPaymentMethodUpdate) {
      await updatePaymentMethod({ setupIntentId: setupIntentId || '' })
    } else {
      await activateAutopay({ setupIntentId: setupIntentId || '' })
    }

    changeActiveModal(PaymentsSetupModalsEnums.collectEmail)

    // Clear url params so modal doesn't keep showing up
    urlParams.delete(setupIntentParamName)
    urlParams.delete(clientSecretParamName)
    urlParams.delete(redirectStatusParamName)
    history.replace({
      search: urlParams.toString(),
    })

    return { success: true, skipped: false }
  } catch (error) {
    console.error({ module: 'autopaySuccessful', error })
    return { success: false, skipped: false }
  }
}

export const initAutopayLaunchLocalStorageKey = 'init_autopay_launch_modal_count'

export const onClickAutopayCTA = (
  autopay: boolean,
  ppgfStatus: PaypalGivingFundStatusEnums,
  setActiveModal: Dispatch<SetStateAction<PaymentsSetupModalsEnums | null>>
) => {
  if (autopay && ppgfStatus !== PaypalGivingFundStatusEnums.enrolled) {
    setActiveModal(PaymentsSetupModalsEnums.setupPpgfAutoPay)
  } else {
    setActiveModal(PaymentsSetupModalsEnums.setupBeamFeesAutoPayLanding)
  }
}
