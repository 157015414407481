import { API_BASE_PATH_PORTAL } from '../../../api/auth'
import { axiosRequest } from '../../../utils/axiosRequest'

export async function associateSalesforceAccountWithPartner({ code }: { code: string | null }) {
  if (code === null) {
    throw new Error('code param not found')
  }
  await axiosRequest('post', `${API_BASE_PATH_PORTAL}/salesforce-oms/submit-auth-code`, {
    code: code,
  })
}
