import { API_BASE_PATH_PORTAL } from '../../../api/auth'
import { axiosRequest } from '../../../utils/axiosRequest'
import { OverviewPartnerImpactResponse } from '../../../utils/types'
import {
  OptimalRoiWithSubscriptionsResponse,
  RoiMetricsResponsePayload,
} from './OverviewPage.types'

export const fetchPartner = async (partnerId: number) => {
  const [partnerData, impactData] = await Promise.all([
    axiosRequest('get', `${API_BASE_PATH_PORTAL}/partners/${partnerId}`),
    axiosRequest('get', `${API_BASE_PATH_PORTAL}/partners/impact/${partnerId}`),
  ])

  return { partner: partnerData?.data, impact: impactData?.data as OverviewPartnerImpactResponse }
}

export async function fetchOptimaRoi(
  partnerId: number,
  storeId?: number
): Promise<RoiMetricsResponsePayload> {
  const url = new URL(`${API_BASE_PATH_PORTAL}/v2/reports/roi/${partnerId}/optimalRoi`)
  if (storeId) {
    url.searchParams.append('storeId', storeId.toString())
  }

  const data = await axiosRequest('get', url.toString())
  return data?.data
}

export async function fetchOptimalRoiWithSubscriptions(
  partnerId: number,
  storeId?: number
): Promise<OptimalRoiWithSubscriptionsResponse> {
  const url = new URL(
    `${API_BASE_PATH_PORTAL}/v2/reports/roi/${partnerId}/optimalRoiWithSubscriptions`
  )
  if (storeId) {
    url.searchParams.append('storeId', storeId.toString())
  }

  const data = await axiosRequest('get', url.toString())
  return data?.data
}

export const fetchCumulativeImpact = async (chainId: number) => {
  const data = await axiosRequest(
    'get',
    `${API_BASE_PATH_PORTAL}/v2/impact/${chainId}/cumulativeImpact`
  )

  return data?.data
}

export async function holidayCumulativeImpactDataPartnerRequest(
  partnerId: number
): Promise<{ ok: boolean }> {
  try {
    const data = await axiosRequest(
      'post',
      `${API_BASE_PATH_PORTAL}/v2/requestImpact/partnerCumulativeImpactDataRequest`,
      {
        partnerId: partnerId,
      }
    )

    return data?.data
  } catch (e) {
    console.log(e)
    return { ok: false }
  }
}
