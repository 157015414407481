import { API_BASE_PATH_PORTAL } from '../../../api/auth'
import { axiosRequest } from '../../../utils/axiosRequest'

export interface UpdateInvoiceEmailAddressRequestData {
  invoiceEmail: string
  invoiceCcEmails: string[]
}

export async function updateInvoiceEmailAddress(
  partnerId: number,
  requestData: UpdateInvoiceEmailAddressRequestData
) {
  const data = await axiosRequest(
    'put',
    `${API_BASE_PATH_PORTAL}/v2/invoices/${partnerId}/invoiceEmailAddress`,
    {
      invoiceEmail: requestData.invoiceEmail,
      invoiceCcEmails: requestData.invoiceCcEmails,
    }
  )

  return data?.data
}

export async function activateAutopay({ setupIntentId }: { setupIntentId: string }) {
  const reqBody = { setupIntentId }

  const data = await axiosRequest(
    'POST',
    `${API_BASE_PATH_PORTAL}/v2/invoices/activateAutopay`,
    reqBody
  )

  return data?.data
}

export async function updatePaymentMethod({ setupIntentId }: { setupIntentId: string }) {
  const data = await axiosRequest(
    'PUT',
    `${API_BASE_PATH_PORTAL}/v2/invoices/updatePaymentMethod`,
    { setupIntentId }
  )

  return data?.data
}
