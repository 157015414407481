import cx from 'classnames'
import { useState } from 'react'

import { API_BASE_PATH_PORTAL } from '../../../api/auth'
import { useBeamSelector } from '../../../hooks'
import { BeamButton } from '../../../stories/BeamButton'
import { BeamModal } from '../../../stories/BeamModal'
import { axiosRequest } from '../../../utils/axiosRequest'
import { TUser } from '../../../utils/types'
import { PageNotFound } from '../../root/PageNotFound'
import $$ from './paypal-giving-fund.module.css'

const getApprovalLink = async () => {
  const data = await axiosRequest(
    'post',
    `${API_BASE_PATH_PORTAL}/invoices/paypal/billing-agreement/token`
  )

  return data.data
}

export const PaypalGivingFundPage = () => {
  const user = useBeamSelector(({ user }) => user) as TUser
  const [approvalLink, setApprovalLink] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [showModal, setShowModal] = useState<boolean>(false)

  if (user?.type !== 'Executive') return <PageNotFound />

  const navigateToPaypal = async () => {
    setShowModal(true)

    try {
      const generatedLink = await getApprovalLink()
      setApprovalLink(generatedLink)
      window.location.href = generatedLink
    } catch (error: any) {
      setError(error.message)
    }
  }

  return (
    // This is redundant to what's in Root.tsx, but otherwise the buttons would be super wide.
    <div className="max-w-5xl">
      <h1 className="beam--heading--1">Let’s get you set up</h1>
      <p className="mb-8 beam--paragraph--small">
        You can now automate donations to participating nonprofit partners via Beam&apos;s partner,
        PayPal Giving Fund!
      </p>
      <p className="beam--paragraph--small">
        <strong>
          Please note: Automatic payments will only be processed for nonprofits that are registered
          with PayPal Giving Fund
        </strong>
      </p>
      <p className="mb-8 beam--paragraph--small">
        Each quarter, we&apos;ll automatically trigger donation to participating nonprofit partners.
        You&apos;ll receive an invoice for instructions on how to donate to nonprofits not yet
        registered with PayPal Giving Fund.
      </p>
      <p className="beam--paragraph--small">There are just three steps to get set up:</p>
      <ol className={$$.list}>
        <li>Create a PayPal account (if you don&apos;t already have one)</li>
        <li>
          Add your preferred payment method for making donations (bank account or credit card)
        </li>
        <li>Accept PayPal&apos;s agreement to authorize donation fulfillment</li>
      </ol>
      <BeamButton
        label="Set up my connection to PayPal Giving Fund"
        variant="elevated"
        onClick={navigateToPaypal}
        className={cx($$.button, 'mt-4 mb-12')}
      />
      <p className="beam--paragraph--small">
        Donations will be made to PayPal Giving Fund, an IRS-registered 501(c)(3) nonprofit
        organization. PPGF receives the donation and distributes the appropriate amount to each of
        your partner nonprofits. Making donations through PayPal Giving Fund also enables us to
        manage applicable compliance for you. Your team will receive donation receipts from PayPal
        Giving Fund confirming donations have been successfully processed. Beam does not have access
        to your payment information.
      </p>
      <BeamModal
        open={showModal}
        label={error ? 'Error' : 'Setting you up'}
        body={
          <div>
            {!approvalLink && 'Getting Link'}
            {!error && approvalLink && (
              <div>
                If you are not redirected, please follow <a href={approvalLink}>this link</a> to
                complete your set up with PayPal
              </div>
            )}
            <p>{error}</p>
          </div>
        }
      />
    </div>
  )
}
