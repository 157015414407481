import { useContext } from 'react'

import { removePaymentMethod } from '../../../api/autopayApi'
import { BeamButton } from '../../../stories/BeamButton'
import { BeamModal } from '../../../stories/BeamModal'
import { PaymentsSetupModalsContext, PaymentsSetupModalsEnums } from './PaymentsSetupModals'
interface DisconnectAutopayModalProps {
  open: boolean
  afterCloseHandler: (closedModal: PaymentsSetupModalsEnums) => void
  profilePageToastHandler?: (errorMessage?: string) => void
}

/**
 * Displayed when user gets redirected after successfully adding a Stripe payment method
 */
export const DisconnectAutopayModal = ({
  open,
  afterCloseHandler,
  profilePageToastHandler,
}: DisconnectAutopayModalProps) => {
  const context = useContext(PaymentsSetupModalsContext)

  function handleDisconnectAutopay() {
    removePaymentMethod()
      .then(() => {
        // show success toast & close the modal
        if (profilePageToastHandler) profilePageToastHandler()

        context.changeActiveModal(null)
      })
      .catch(error => {
        // show failure toast
        console.log('Error --> ', error)
        if (profilePageToastHandler) profilePageToastHandler(error.message)
      })
  }

  return (
    <BeamModal
      label=""
      open={open}
      onCloseCallback={() => afterCloseHandler(PaymentsSetupModalsEnums.disconnectAutopay)}
      body={
        <div className="flex flex-col items-center text-center">
          <h2 className="m-0 text-2xl font-primary text-cherry-800">
            Are you sure you want to disconnect Autopay?
          </h2>
          <p className="mt-6 text-base leading-8">
            If you disconnect Autopay, your invoices will no longer be paid automatically. You will
            be able to pay Beam fees from the Invoices page in Partner Portal or via the invoice you
            receive over email.
          </p>
          <BeamButton
            label="No, I Want to Keep Autopay"
            variant="elevated"
            onClick={() => context.changeActiveModal(PaymentsSetupModalsEnums.viewPaymentDetails)}
            className="w-full mt-6"
          />

          <BeamButton
            label="Yes, I Want to Disconnect Autopay"
            variant="text"
            onClick={handleDisconnectAutopay}
            className="mt-8 text-cherry-800"
          />
        </div>
      }
    />
  )
}
