import { isPositiveNumber } from '../../../../utils/helpers/isPositiveNumber'
import { DataWikiObject, RoiMetricsObject } from '../OverviewPage.types'

// ==== One time purchase helpers ====

export function shouldShowCCRMetrics(calculatedData: RoiMetricsObject): boolean {
  // always show ccr metrics for brands using estimated CCR
  if (calculatedData.isEstimatedCCRIncluded && isPositiveNumber(calculatedData.pctCcrLift))
    return true

  return (
    isPositiveNumber(calculatedData.beamCCR) &&
    isPositiveNumber(calculatedData.pctCcrLift) &&
    !!calculatedData.reportPeriodId
  )
}

export function shouldWeDisplayAOV(calculatedData: RoiMetricsObject): boolean {
  return (
    isPositiveNumber(calculatedData.nonSubscriptionAverageOrderValueLift) &&
    isPositiveNumber(calculatedData.beamNonSubscriptionAovSalesLift)
  )
}

export function shouldWeDisplayAOF(calculatedData: RoiMetricsObject): boolean {
  return (
    isPositiveNumber(calculatedData.nonSubscriptionAverageOrderFrequencyLift) &&
    isPositiveNumber(calculatedData.beamNonSubscriptionOrderFrequencySalesLift)
  )
}

export function getVisibleOneTimePurchaseMetricsCount(data: RoiMetricsObject): number {
  return [shouldWeDisplayAOF(data), shouldWeDisplayAOV(data), shouldShowCCRMetrics(data)].filter(
    entry => entry
  ).length
}

export function shouldDisplayAnyOneTimePurchaseMetrics(data: RoiMetricsObject): boolean {
  return getVisibleOneTimePurchaseMetricsCount(data) > 0
}

export function displayDateRange(calculatedData: RoiMetricsObject | null): string {
  // remove "M123" or "Q123" prefixes
  return calculatedData?.roiPeriodDate?.replace(/[Q|M][0-9]./, '').trim() || 'N/A'
}

// ==== Subscriptions helpers ====

export function shouldWeDisplaySubscriptionAOV(data: RoiMetricsObject): boolean {
  return (
    isPositiveNumber(data.subscriptionAverageOrderValueLift) &&
    isPositiveNumber(data.beamSubscriptionAovSalesLift)
  )
}

export function shouldWeDisplaySubscriptionAOF(data: RoiMetricsObject): boolean {
  return (
    isPositiveNumber(data.subscriptionAverageOrderFrequencyLift) &&
    isPositiveNumber(data.beamSubscriptionOrderFrequencySalesLift)
  )
}

export function shouldWeDisplaySubscriptionSignupLift(data: RoiMetricsObject): boolean {
  return (
    isPositiveNumber(data.subscriptionSignUpLift) &&
    isPositiveNumber(data.subscriberSignUpSalesLift)
  )
}

export function getVisibleSubscriptionMetricsCount(data: RoiMetricsObject): number {
  return [
    shouldWeDisplaySubscriptionAOF(data),
    shouldWeDisplaySubscriptionAOV(data),
    shouldWeDisplaySubscriptionSignupLift(data),
  ].filter(entry => entry).length
}

export function shouldDisplayAnySubscriptionMetrics(data: RoiMetricsObject): boolean {
  return getVisibleSubscriptionMetricsCount(data) > 0
}

export function getHasBrandSpecificConversionPercent(dataWikiData: DataWikiObject | null) {
  return !!dataWikiData?.estimatedBeamConversionPercent
}
