import { IconComponentProps } from './IconComponentProps'

export const InfoIconComponent = ({ fill = '--beam-color--sky-800' }: IconComponentProps) => {
  return (
    <svg width="15" height="14" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5 18C12.84 18 13.1252 17.8848 13.3556 17.6544C13.5852 17.4248 13.7 17.14 13.7 16.8V11.97C13.7 11.63 13.5852 11.35 13.3556 11.13C13.1252 10.91 12.84 10.8 12.5 10.8C12.16 10.8 11.8752 10.9148 11.6456 11.1444C11.4152 11.3748 11.3 11.66 11.3 12V16.83C11.3 17.17 11.4152 17.45 11.6456 17.67C11.8752 17.89 12.16 18 12.5 18ZM12.5 8.4C12.84 8.4 13.1252 8.2848 13.3556 8.0544C13.5852 7.8248 13.7 7.54 13.7 7.2C13.7 6.86 13.5852 6.5748 13.3556 6.3444C13.1252 6.1148 12.84 6 12.5 6C12.16 6 11.8752 6.1148 11.6456 6.3444C11.4152 6.5748 11.3 6.86 11.3 7.2C11.3 7.54 11.4152 7.8248 11.6456 8.0544C11.8752 8.2848 12.16 8.4 12.5 8.4ZM12.5 24C10.84 24 9.28 23.6848 7.82 23.0544C6.36 22.4248 5.09 21.57 4.01 20.49C2.93 19.41 2.0752 18.14 1.4456 16.68C0.8152 15.22 0.5 13.66 0.5 12C0.5 10.34 0.8152 8.78 1.4456 7.32C2.0752 5.86 2.93 4.59 4.01 3.51C5.09 2.43 6.36 1.5748 7.82 0.9444C9.28 0.3148 10.84 0 12.5 0C14.16 0 15.72 0.3148 17.18 0.9444C18.64 1.5748 19.91 2.43 20.99 3.51C22.07 4.59 22.9248 5.86 23.5544 7.32C24.1848 8.78 24.5 10.34 24.5 12C24.5 13.66 24.1848 15.22 23.5544 16.68C22.9248 18.14 22.07 19.41 20.99 20.49C19.91 21.57 18.64 22.4248 17.18 23.0544C15.72 23.6848 14.16 24 12.5 24ZM12.5 21.6C15.16 21.6 17.4252 20.6652 19.2956 18.7956C21.1652 16.9252 22.1 14.66 22.1 12C22.1 9.34 21.1652 7.0748 19.2956 5.2044C17.4252 3.3348 15.16 2.4 12.5 2.4C9.84 2.4 7.5752 3.3348 5.7056 5.2044C3.8352 7.0748 2.9 9.34 2.9 12C2.9 14.66 3.8352 16.9252 5.7056 18.7956C7.5752 20.6652 9.84 21.6 12.5 21.6Z"
        fill={`var(${fill})`}
      />
    </svg>
  )
}
