import { isNil } from 'lodash'
import { Link } from 'react-router-dom'

import { REDESIGN_ROOT_PATH } from '../../../helpers'
import { BeamDataPointBlock } from '../../../stories/BeamDataPointBlock'
import { numberFormat } from '../../../utils/helpers/stringFormatter'
import { TitleWithTooltip } from '../../root/TitleWithTooltip'
import { ReactComponent as GraphIcon } from './assets/graphIcon.svg'
import { ReactComponent as UpwardTrendIcon } from './assets/upwardTrendIcon.svg'
import { SupplementaryRoiMetrics } from './OverviewPage.types'

export const CumulativeSocialImpactSection = ({
  data,
  loading = true,
}: {
  data?: SupplementaryRoiMetrics
  loading: boolean
}) => {
  if (!data) return <></>

  const { beamTransactions, redemptionsInLastSevenDays } = data

  return (
    <div className="grid grid-cols-1 my-6 desktop:grid-cols-2">
      <div className="my-auto">
        <h2 className="beam--heading--2">All Time Social Impact</h2>
      </div>
      <div className="desktop:col-span-2">
        <BeamDataPointBlock
          title={
            <TitleWithTooltip
              title={'Donations Redeemed'}
              tooltipContent={'The number of donations that have been made since launch'}
            />
          }
          datum={
            beamTransactions && +beamTransactions > 0
              ? numberFormat(+beamTransactions)
              : 'Not enough data collected yet'
          }
          description={
            !isNil(redemptionsInLastSevenDays) &&
            +redemptionsInLastSevenDays > 0 && (
              <span className="iconOverrideOverviewPage">
                <UpwardTrendIcon />+{+redemptionsInLastSevenDays} the last 7 days
              </span>
            )
          }
          icon={<GraphIcon />}
          footer={<Link to={`${REDESIGN_ROOT_PATH}/reports`}>View Report</Link>}
          backgroundColor={'--beam-color--coral-50'}
          loading={loading}
        />
      </div>
    </div>
  )
}
