import cx from 'classnames'

import { Cause1 } from './assets/Cause1'
import { Cause2 } from './assets/Cause2'
import { Cause3 } from './assets/Cause3'
import { Cause4 } from './assets/Cause4'
import { Notification } from './assets/Notification'
import { ProgressBar } from './assets/ProgressBar'
import $$ from './campaign-preview.module.css'

export const CampaignPreview = ({
  campaignName,
  campaignNameCharacterLimit = 23,
  color,
  multiplier,
}: {
  campaignName: string
  campaignNameCharacterLimit?: number
  color: string
  multiplier: number
}) => {
  const campaignColor = color ? color : 'var(--beam-color--charcoal-600)'
  return (
    <div className="select-none">
      <div>
        <span
          className={cx($$.promoPillbox, 'inline-flex overflow-hidden')}
          style={{ backgroundColor: campaignColor }}>
          Donations are {multiplier ? multiplier : '_'}x {'for '}
          {campaignName ? campaignName.slice(0, campaignNameCharacterLimit) : '＿'.repeat(10)}
        </span>
        <span className={cx($$.widgetTitle, 'float-left')}>Choose your impact</span>
      </div>
      <div className={cx($$.widgetDescription, 'float-left')}>
        <b>At no extra cost,</b> select a nonprofit and 1% of your purchase will be donated for you.
      </div>
      <div className={cx($$.nonprofitButtonContainer, 'flex')}>
        <div className="bg-sky-50">
          <Cause1 />
          <Notification style={{ fill: campaignColor }} className={$$.nonprofitNotification} />
        </div>
        <div className="bg-charcoal-50">
          <Cause2 />
        </div>
        <div className="bg-charcoal-50">
          <Cause3 />
        </div>
        <div className="bg-charcoal-50">
          <Cause4 />
        </div>
      </div>
      <div className={$$.impactPreview}>
        <div className={cx($$.impactPreviewHeader, 'flex justify-between')}>
          <span className="font-bold">
            {multiplier ? `${multiplier}X BOOSTING DONATIONS` : 'FOOD INSECURITY'}
          </span>
          <span>POWERED BY BEAM</span>
        </div>
        <div className={$$.impactPreviewBody}>
          Fund 20 meals for food insecure families in need via LA Food Bank
        </div>
        <div className={cx($$.impactProgressBar, 'flex items-center')}>
          <ProgressBar />
          <span className="flex-shrink-0 pl-2 text-xs">54% funded</span>
        </div>
      </div>
    </div>
  )
}
