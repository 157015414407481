export enum WidgetNameEnum {
  'community-impact' = 'community-impact',
  'cumulative-impact' = 'cumulative-impact',
  'impact-overview' = 'impact-overview',
  'redeem-transaction' = 'redeem-transaction',
  'select-nonprofit' = 'select-nonprofit',
  'product-details-page' = 'product-details-page',
}

export type WidgetName = keyof typeof WidgetNameEnum
