import { createColumnHelper } from '@tanstack/react-table'
import cx from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { getV2ApiUrl } from '../../../api/auth'
import { REDESIGN_ROOT_PATH } from '../../../helpers'
import { useBeamSelector } from '../../../hooks'
import { BeamLoadingIndicator } from '../../../stories/BeamLoadingIndicator'
import { BeamMiniCTA } from '../../../stories/BeamMiniCTA'
import { BeamSticker } from '../../../stories/BeamSticker'
import { BEAM_STICKER_VARIANT } from '../../../stories/BeamSticker/BeamSticker'
import { BeamTable } from '../../../stories/BeamTable'
import { BeamToast } from '../../../stories/BeamToast'
import { BeamTooltip } from '../../../stories/BeamTooltip'
import { axiosRequest } from '../../../utils/axiosRequest'
import { CampaignPageBody, TSite, TUser } from '../../../utils/types'
import { CustomError } from '../../../utils/types/CustomError'
import { BeamSEO } from '../../root/BeamSEO'
import { PageNotFound } from '../../root/PageNotFound'
import { AutoBoostANonprofitModal } from './AutoBoostANonprofitModal'
import { BeamNonprofitPartnersPageNavigation } from './BeamNonprofitPartnersPageNavigation'
import { fetchCampaignData } from './CampaignsPage/CampaignPage.api'
import $$ from './nonprofit-page.module.css'
import {
  AggregatedStoreNonprofitInfo,
  ChainNonprofitsInfoResponse,
  getNonprofitsBySiteFilter,
  NonprofitInfoObject,
} from './NonprofitPartnersPage.helpers'
import { RequestNewNonprofitCauseModal } from './RequestNewNonprofitCauseModal'

async function fetchNonprofits(
  chainId: number,
  setLoading: (loading: boolean) => void,
  callback: (nonprofits: ChainNonprofitsInfoResponse) => void
) {
  try {
    const res = await axiosRequest('get', `${getV2ApiUrl()}/chains/${chainId}/nonprofits/list`)
    callback(res.data)
    setLoading(false)
  } catch (error: any) {
    console.log({ loading: false, error, message: 'Failed to retrieve invoices' })
  }
}

export const NonprofitPartnersPage = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [nonprofits, setNonprofits] = useState<NonprofitInfoObject[]>([])
  const [storeNonprofitsInfo, setStoreNonprofitsInfo] = useState<AggregatedStoreNonprofitInfo[]>([])
  const [modal, setModal] = useState(<></>)
  const [openToast, setOpenToast] = useState<boolean>(false)
  const [campaignData, setCampaignData] = useState<CampaignPageBody | null>(null)
  const [customErrorMessage, setCustomErrorMessage] = useState<CustomError | null>(null)
  const user: TUser | undefined = useBeamSelector(({ user }) => user)
  const siteFilter: TSite | undefined = useBeamSelector(({ site }) => site)

  const filteredNonprofits = useMemo(() => {
    return getNonprofitsBySiteFilter(nonprofits, storeNonprofitsInfo, siteFilter)
  }, [nonprofits, siteFilter, storeNonprofitsInfo])

  useEffect(() => {
    if (!user?.chainId) return

    fetchNonprofits(
      user.chainId,
      setLoading,
      function callback(response: ChainNonprofitsInfoResponse) {
        setNonprofits(response.nonprofits)
        setStoreNonprofitsInfo(response.storeNonprofits)
      }
    )
  }, [user?.chainId])

  useEffect(() => {
    ;(async () => {
      if (!user?.chainId) return

      try {
        const data = await fetchCampaignData(user.chainId)
        setCampaignData(data)
      } catch (error: any) {
        console.log(error.message)
      }
    })()
  }, [user?.chainId])

  if (user?.type !== 'Executive') return <PageNotFound />

  type Columns = {
    personalPhoto: string
    associatedStores: NonprofitInfoObject['associatedStores']
    name: string
    cause: string
    percentSupport: number
    active: string
  }

  const columnHelper = createColumnHelper<Columns>()

  const columns = [
    columnHelper.accessor('personalPhoto', {
      header: 'Nonprofit Image',
      cell: row => {
        return (
          <div
            className={$$.nonprofitImage}
            style={{ backgroundImage: `url("${row.getValue()}")` }}
          />
        )
      },
    }),
    columnHelper.accessor('name', {
      header: 'Nonprofit',
    }),
    columnHelper.accessor('cause', {
      header: 'Cause',
    }),
    columnHelper.accessor('active', {
      header: 'Status',
      cell: row => {
        const status = row.getValue()
          ? { label: 'Live', variant: BEAM_STICKER_VARIANT.positive }
          : { label: 'Not Live', variant: BEAM_STICKER_VARIANT.negative }

        return (
          <>
            <BeamSticker
              label={status.label}
              icon={<span>&#x25cf;</span>}
              variant={status.variant}
            />
          </>
        )
      },
    }),
  ]

  if (!siteFilter?.storeId) {
    const siteColumnIndex = 1
    const siteColumn = columnHelper.accessor('associatedStores', {
      header: 'Site',
      cell: row => {
        const stores = row.getValue()

        if (stores.length === 0) {
          return ''
        }

        const joinedStoreNames = stores.map(store => store.name.trim()).join(', ')

        return (
          <BeamTooltip content={joinedStoreNames}>
            <span>{joinedStoreNames}</span>
          </BeamTooltip>
        )
      },
    })

    columns.splice(siteColumnIndex, 0, siteColumn as any)
  }

  let bodyComponent = <BeamLoadingIndicator />

  if (!loading) {
    bodyComponent = (
      <BeamTable
        columns={columns}
        data={filteredNonprofits}
        enableSorting={true}
        className={$$.nonprofitPartnersTable}
        noDataMessage={`There are no partners to display`}
      />
    )
  }

  return (
    <>
      <BeamSEO title={`My Nonprofit Partners`} />
      <main className={'beam--page-content--no-gutter'}>
        <div className={'bg-charcoal-50 flex justify-between items-center px-5'}>
          <BeamNonprofitPartnersPageNavigation promos={campaignData} site={siteFilter} />
        </div>

        <section className={'relative mx-5 my-5'}>
          <div
            className={`grid grid-cols-1 ${
              !siteFilter?.storeId ? 'desktop:grid-cols-2' : 'desktop:grid-cols-1'
            }`}>
            <div>
              <h1 className="beam--heading--1">My Nonprofit Partners</h1>
              <p className={$$.nonprofitBoostDescription}>
                Here is a list of your active and inactive nonprofit partners. If you have a
                scheduled boost that you want to edit, reschedule or cancel, please contact your
                Customer Success Lead.
              </p>
            </div>
            {!siteFilter?.storeId && (
              <div className={$$.boostACause}>
                {loading ? (
                  <BeamLoadingIndicator />
                ) : (
                  <BeamMiniCTA
                    title={`Want to boost a nonprofit?`}
                    description={`Set up a Boosted Impact Campaign to lift sales with a 2-5% margin impact vs the 15-25% typical of flash sales`}
                    buttonLabel={`Boost a Nonprofit`}
                    onClick={() => {
                      setModal(
                        <AutoBoostANonprofitModal
                          partnerId={user.partnerId}
                          onCloseHandler={() => setModal(<></>)}
                          user={user}
                          openToast={() => setOpenToast(true)}
                          setMessage={(e: CustomError | null) => {
                            setCustomErrorMessage(e)
                          }}
                          openNonprofitModalAction={() =>
                            setModal(
                              <RequestNewNonprofitCauseModal
                                partnerId={user.partnerId}
                                openToast={() => setOpenToast(true)}
                                setMessage={(e: CustomError | null) => {
                                  setCustomErrorMessage(e)
                                }}
                              />
                            )
                          }
                        />
                      )
                    }}
                  />
                )}
              </div>
            )}
          </div>

          <div className="grid grid-cols-1 desktop:grid-cols-2">
            <div>
              <h2 className="beam--heading--2">List of Nonprofits</h2>
            </div>
            <div className="flex items-center place-content-end">
              <a
                className={$$.link}
                onClick={() =>
                  setModal(
                    <RequestNewNonprofitCauseModal
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      partnerId={user.partnerId}
                      openToast={() => setOpenToast(true)}
                      setMessage={(e: CustomError | null) => {
                        setCustomErrorMessage(e)
                      }}
                    />
                  )
                }>
                Request a New Cause or Nonprofit
              </a>
            </div>
          </div>
          <div className="grid grid-cols-1">
            <div>{bodyComponent}</div>
          </div>
          <div className={cx($$.description, 'pt-6')}>
            Need to change the status of one of these nonprofits?{' '}
            <Link to={`${REDESIGN_ROOT_PATH}/contact-support`} className={$$.link}>
              Contact Us
            </Link>
          </div>
        </section>

        {modal}

        <BeamToast
          onClose={() => setOpenToast(false)}
          open={openToast}
          closable={true}
          text={
            customErrorMessage
              ? customErrorMessageHelper(customErrorMessage)
              : 'Boost set up! Check your inbox for a confirmation email'
          }
          variant={customErrorMessage ? 'error' : 'success'}
          icon={customErrorMessage ? null : <span>&#127881;</span>}
          duration={10000}
        />
      </main>
    </>
  )
}

export const errorMessageHelper = (res: string) => {
  if (res?.includes('401')) {
    return 'You are unauthorized to send this email. Please try logging back in or reach out to us using another method. '
  }
  if (res?.includes('400')) {
    return 'Invalid input. Please fill out each required field'
  }
  return 'Seems like something went wrong. Please try reaching out to us using another method or try again later.'
}

export const customErrorMessageHelper = (error: CustomError | null) => {
  if (error && error.detail) {
    return error.detail
  }
  return 'An unknown error occurred. Please try again later.'
}
