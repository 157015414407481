import { BeamModal } from '../../../stories/BeamModal'
import { PaymentsSetupModalsEnums } from './PaymentsSetupModals'
interface PaymentDetailsSuccessModalProps {
  open: boolean
  afterCloseHandler: (closedModal: PaymentsSetupModalsEnums) => void
}

/**
 * Displayed when user gets redirected after successfully adding a Stripe payment method
 */
export const PaymentDetailsSuccessModal = ({
  open,
  afterCloseHandler,
}: PaymentDetailsSuccessModalProps) => {
  return (
    <BeamModal
      label="Success"
      open={open}
      onCloseCallback={() => afterCloseHandler(PaymentsSetupModalsEnums.paymentDetailsSuccess)}
      body={
        <div>
          <p>Auto pay was successfully configured.</p>
        </div>
      }
    />
  )
}
