export const UpDownIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#99FFF5" />
      <g clipPath="url(#clip0_907_10597)">
        <path
          d="M10.3958 12.5239C10.3958 12.6717 10.5156 12.7914 10.6634 12.7914C10.8112 12.7914 10.9309 12.6717 10.9309 12.5239V7.28383L13.1494 9.50235C13.2018 9.55474 13.2703 9.58056 13.3388 9.58056C13.4072 9.58056 13.4757 9.55436 13.5281 9.50235C13.6325 9.39796 13.6325 9.22845 13.5281 9.12406L10.8527 6.44866C10.7483 6.34426 10.5788 6.34426 10.4744 6.44866L7.799 9.12406C7.6946 9.22845 7.6946 9.39796 7.799 9.50235C7.9034 9.60675 8.0729 9.60675 8.1773 9.50235L10.3962 7.28383V12.5239H10.3958Z"
          fill="#00357A"
          stroke="#00357A"
          strokeWidth="0.75"
        />
        <path
          d="M16.2035 14.4747C16.0991 14.3703 15.9296 14.3703 15.8252 14.4747L13.6067 16.6936V11.4536C13.6067 11.3058 13.4869 11.186 13.3391 11.186C13.1913 11.186 13.0716 11.3058 13.0716 11.4536V16.6936L10.8531 14.4751C10.7487 14.3707 10.5792 14.3707 10.4748 14.4751C10.3704 14.5795 10.3704 14.749 10.4748 14.8534L13.1502 17.5288C13.2003 17.5789 13.2684 17.607 13.3395 17.607C13.4106 17.607 13.4783 17.5789 13.5289 17.5288L16.2042 14.8534C16.3079 14.7486 16.3079 14.5791 16.2035 14.4747Z"
          fill="#00357A"
          stroke="#00357A"
          strokeWidth="0.75"
        />
      </g>
      <defs>
        <clipPath id="clip0_907_10597">
          <rect width="12" height="11.9775" fill="white" transform="translate(6 6)" />
        </clipPath>
      </defs>
    </svg>
  )
}
