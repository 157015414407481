import { isNull } from 'lodash'

import { OverviewNonprofitImpactObject } from '../../../utils/types'
import { TStateWithLoading } from './OverviewPage.types'

export const progressBarData = (nonprofit: OverviewNonprofitImpactObject) => {
  const { total_donated, target_donation_amount } = nonprofit.impact
  const targetDonationAmount = isNull(target_donation_amount) ? 0 : target_donation_amount
  const completionRatio = total_donated / targetDonationAmount
  const timesCompleted = Math.floor(completionRatio)
  const percentCompleted =
    (total_donated - timesCompleted * targetDonationAmount) / targetDonationAmount

  return { completionRatio, timesCompleted, percentCompleted }
}

export const overviewTestIds = {
  blendedRoiMetric: 'blended-roi-metric-container',
  beamRoiMetric: 'beam-roi-metric-container',
  salesLiftRoiMetric: 'sales-lift-metric-container',
  roiDetailsView: 'roi-details-view-container',
  roiCcrLiftBlock: 'roi-ccr-lift-block-container',
}

export function isDataAvailable(dataObject: TStateWithLoading): boolean {
  const dataAlreadyLoaded = !!dataObject.data
  const dataIsLoading = dataObject.loading
  const dataHadError = !!dataObject.error
  return dataAlreadyLoaded || dataIsLoading || dataHadError
}
