import { axiosRequest } from '../utils/axiosRequest'
import { API_BASE_PATH_PORTAL } from './auth'

export const fetchStripeData = async (chainId: number) => {
  const data = await axiosRequest(
    'get',
    `${API_BASE_PATH_PORTAL}/v2/invoices/${chainId}/stripeData`
  )

  return data?.data
}
