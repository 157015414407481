import { EditPencilDisabled } from './assets/edit_pencil_disabled'
import { EditPencilEnabled } from './assets/edit_pencil_enabled'
import { TrashCan } from './assets/trash_delete'
interface IconProps {
  variant?: 'party' | 'link' | 'pencil' | 'trashcan'
  disabled?: boolean
}

export const BeamIcon = ({ variant = 'party', disabled = false }: IconProps) => {
  let variantIcon // needs to be the Unicode not HTML
  switch (variant) {
    case 'party':
      variantIcon = '🎉'
      break
    case 'link':
      variantIcon = '🔗'
      break
    case 'pencil':
      variantIcon = disabled ? <EditPencilDisabled /> : <EditPencilEnabled />
      break
    case 'trashcan':
      variantIcon = <TrashCan />
      break
  }
  return <span data-testid={`${variant}-icon`}>{variantIcon}</span>
}
