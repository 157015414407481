import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, Stripe, StripeElementsOptions } from '@stripe/stripe-js'
import { ReactNode, useEffect, useState } from 'react'

import { getV2ApiUrl, inDevEnvironment, inStagingEnvironment } from '../../api/auth'
import { BeamLoadingIndicator } from '../../stories/BeamLoadingIndicator'
import { axiosRequest } from '../../utils/axiosRequest'

async function createSetupIntent() {
  const { data } = await axiosRequest('POST', `${getV2ApiUrl()}/invoices/createSetupIntent`)
  return data?.clientSecret
}

const STRIPE_PUBLISHABLE_KEY =
  inDevEnvironment || inStagingEnvironment
    ? 'pk_test_51MjRfUHs3CDJl4ehfkT7B2AwvLXUqyse82Y1W5hRlncbko4meWvUnuzdrNSORikdFIoGZdqfFpLvc61PScf6YwXk00pZVCrM5y'
    : 'pk_live_51MjRfUHs3CDJl4eh5BCxqs79Z4VqQZLdnKZso8MRDrQi2YIWa7QXQJ1mXP4RD7ZqdPMcuIPYKBUUWbohLaDqI63900yoX97Gap'

export const StripeProvider = ({ children }: { children: ReactNode }) => {
  const [stripeObject, setStripeObject] = useState<Stripe | null>(null)
  const [clientSecret, setClientSecret] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  // store the stripePromise
  useEffect(() => {
    if (!stripeObject) {
      loadStripe(STRIPE_PUBLISHABLE_KEY)
        .then(stripe => {
          setStripeObject(stripe)
        })
        .catch(error => {
          console.error(error.message)
          setError(error.message)
          setStripeObject(null)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // create a setup intent on render(required)
  useEffect(() => {
    setLoading(true)

    createSetupIntent()
      .then(clientSecret => {
        setClientSecret(clientSecret)
        setLoading(false)
      })
      .catch(error => {
        console.error(error.message, error)
        setError(error.message)
        setLoading(false)
      })
  }, [])

  if (loading) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <BeamLoadingIndicator />
      </div>
    )
  }

  if (error || !clientSecret) {
    return (
      <h2 className="text-center">{error ?? 'There was a problem loading the payment module'}</h2>
    )
  }

  if (!stripeObject) return null

  const elementOptions: StripeElementsOptions = {
    clientSecret,
    loader: 'always',
    fonts: [
      {
        src: `url(https://cdn01.beamimpact.com/fonts/mabry-bold.woff2) format('woff2')`,
        family: 'Mabry',
      },
    ],
    // Appearance API docs: https://stripe.com/docs/elements/appearance-api
    appearance: {
      theme: 'flat',
      variables: {
        fontFamily: 'Mabry',
        fontSizeBase: '12px',
        fontWeightNormal: '500px',
        borderRadius: '8px',
        colorBackground: 'white',
        colorTextPlaceholder: '#b5bab8', // --beam-color--charcoal-200
        colorText: '#00357a', // labels -> --beam-color--sky-800
      },
      rules: {
        '.Input': {
          border: '1px solid #b5bab8', // --beam-color--charcoal-200
          color: '#171918', // --beam-color--charcoal-800
        },
        '.Label': {
          fontSize: '16px',
          lineHeight: '32px',
          marginBottom: '8px',
        },
        '.TermsText': {
          color: '#000000',
          fontWeight: '400',
        },
        '.Tab': {
          border: '1px solid #b5bab8', // --beam-color--charcoal-200
        },
        '.Tab--selected': {
          border: 'none', // --beam-color--charcoal-200
        },
        '.Dropdown': {
          border: '1px solid #b5bab8', // --beam-color--charcoal-200
        },
        '.PickerItem': {
          backgroundColor: '#fafafa', // --beam-color--charcoal-200
        },
        '.PickerItem:hover': {
          backgroundColor: '#c2dcff', // --beam-color--charcoal-200
        },
      },
    },
  }

  return (
    <Elements stripe={stripeObject} options={elementOptions}>
      {children}
    </Elements>
  )
}
