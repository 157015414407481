/**
 * Was receiving a Rollup error in playwright when attempting to use the auto generated ReactComponent
 * so the svg was manually converted into JSX to allow tests to pass
 */
export const Cause3 = (props: any) => {
  return (
    <svg
      {...props}
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.1604 13.0013C15.1422 12.9819 13.3342 11.0612 11.4517 9.04018L13.1055 7.22576L13.1182 7.20992C13.1602 7.15651 14.1393 5.88047 13.5519 4.11855C13.0568 2.63309 12.3126 1.44068 12.1961 1.28301L11.6346 0.569295C11.6119 0.540562 11.5834 0.516618 11.5504 0.500778C11.5346 0.493226 11.5182 0.486411 11.5014 0.480702C9.85313 -0.0792228 8.34133 -0.0495689 7.36349 0.0740196C6.29761 0.208659 5.61943 0.479044 5.5907 0.489542C5.51758 0.516249 5.42549 0.608342 5.3566 0.708171L4.90461 1.32022C4.89761 1.3298 4.13563 2.46935 3.58566 4.11874C2.99829 5.88066 3.9776 7.15669 4.01941 7.21011L4.02991 7.22337L5.68593 9.04036C3.80336 11.0614 1.9954 12.9819 1.97716 13.0015L1.625 13.3755L5.10777 16L8.56861 12.203L12.0295 16L15.5122 13.3755L15.1604 13.0013ZM10.3296 3.8058C10.1869 4.04451 10.0351 4.2613 9.87744 4.45193C9.66968 4.70297 9.22966 5.19714 8.56917 5.92118C7.90849 5.19714 7.46847 4.70297 7.2609 4.45193C7.11042 4.27014 6.96528 4.06422 6.82843 3.83859C8.62645 3.66767 9.7533 3.73545 10.3296 3.8058Z"
        fill="#41312A"
      />
    </svg>
  )
}
