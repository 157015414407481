import { isBeamImpactEmail } from './isBeamImpactEmail'

export function shouldSendGtagEvents(userEmail: string) {
  const isProdEnv = window.location.hostname === 'partner-portal.beamimpact.com'

  if (isBeamImpactEmail(userEmail) && isProdEnv) {
    return false
  }
  if (window.location.hostname.includes('localhost')) {
    return false
  }

  return true
}
