import { useEffect, useState } from 'react'

import { REDESIGN_ROOT_PATH } from '../../../helpers'
import { useBeamSelector } from '../../../hooks'
import { BeamHorizontalNavigation } from '../../../stories/BeamHorizontalNavigation'
import { getCurrentPageTitle } from '../../../utils/getCurrentPageTitle'
import { PartnerUploadBody, TSite, TUser } from '../../../utils/types'
import { gtag } from '../../root/BeamGoogleAnalytics/googleAnalyticsHelpers'
import { partnerUploadsUrl } from '../../root/Root'
import { fetchPartnerUploads } from '../PartnerUploads/PartnerUploadsPage.api'

/**
 * Beam Horizontal Navigation component
 */
export const BeamReportsPageNavigation = () => {
  const user: TUser | undefined = useBeamSelector(({ user }) => user)
  const site: TSite | undefined = useBeamSelector(({ site }) => site)
  const [partnerUploads, setPartnerUploads] = useState<PartnerUploadBody[] | null>(null)
  const hasPartnerUploads = !!partnerUploads && partnerUploads?.length > 0
  const gtagMetadata = {
    chainId: user?.chainId || '',
    userId: user?.id || '',
    site: site?.name || '',
    pageWhereEventTriggered: getCurrentPageTitle(),
  }

  // pre-fetches partnerUploads to conditionally show the Custom Uploads tab
  useEffect(() => {
    if (!user?.chainId || partnerUploads !== null) return

    fetchPartnerUploads(user.chainId)
      .then(partnerUploads => {
        setPartnerUploads(partnerUploads)
      })
      .catch(error => {
        console.error(error)
      })
  }, [partnerUploads, user?.chainId])

  const reportsPageLinks = [
    {
      label: 'General Reports',
      link: `${REDESIGN_ROOT_PATH}/reports`,
      onClick: () => {
        gtag(`General Reports tab clicked`, gtagMetadata)
      },
    },
  ]

  if (hasPartnerUploads && !site?.storeId) {
    reportsPageLinks.push({
      label: 'Custom Reports',
      link: partnerUploadsUrl,
      onClick: () => {
        gtag(`Custom Reports tab clicked`, gtagMetadata)
      },
    })
  }

  return <BeamHorizontalNavigation links={reportsPageLinks} />
}
