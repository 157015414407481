/**
 * Was receiving a Rollup error in playwright when attempting to use the auto generated ReactComponent
 * so the svg was manually converted into JSX to allow tests to pass
 */
export const Cause2 = (props: any) => {
  return (
    <svg
      {...props}
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_9036_38168)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.41499 12.179V15.36C7.41499 15.7135 7.70153 16 8.05499 16C8.40845 16 8.69499 15.7135 8.69499 15.36V12.179L8.695 12.16C8.695 12.16 8.40846 11.776 8.055 11.776C7.70154 11.776 7.415 12.16 7.415 12.16L7.41499 12.179ZM3.93574 3.56074L3.28061 3.75931C1.59775 4.26937 0.375 5.83356 0.375 7.68C0.375 9.94216 2.20884 11.776 4.471 11.776C5.52088 11.776 6.47584 11.3827 7.20135 10.7333L7.41499 10.5421V8.97503C7.41475 8.96492 7.41475 8.95484 7.41499 8.94479V8.14128L4.01261 7.00716C3.67729 6.89538 3.49607 6.53294 3.60784 6.19761C3.71961 5.86229 4.08206 5.68107 4.41738 5.79284L7.41499 6.79205V5.12C7.41499 4.76654 7.70153 4.48 8.05499 4.48C8.40845 4.48 8.69499 4.76654 8.69499 5.12V7.66481C8.69523 7.67485 8.69523 7.68492 8.69499 7.69501V8.07204L11.6926 7.07284C12.0279 6.96107 12.3904 7.14229 12.5021 7.47761C12.6139 7.81294 12.4327 8.17538 12.0974 8.28716L8.69499 9.42128V10.5421L8.90865 10.7333C9.63416 11.3827 10.5891 11.776 11.639 11.776C13.9012 11.776 15.735 9.94216 15.735 7.68C15.735 5.83356 14.5123 4.26937 12.8294 3.75931L12.1743 3.56074L11.9757 2.90561C11.4656 1.22275 9.90144 0 8.055 0C6.20856 0 4.64437 1.22275 4.13431 2.90561L3.93574 3.56074Z"
          fill="#41312A"
        />
      </g>
      <defs>
        <clipPath id="clip0_9036_38168">
          <rect width="16" height="16" fill="white" transform="translate(0.375)" />
        </clipPath>
      </defs>
    </svg>
  )
}
