export const BEAM_COLORS = {
  blue: '#92c1cc',
  orange: '#f7961d',
  yellow: '#fcd55f',
  green: '#b8cfa3',
  darkYellow: '#ffc000',
  darkOrange: '#F78332',
  yellowOrangeGradient: 'linear-gradient(270deg, #f7961d, #fcd55f )',
  darkGray: '#808191',
  lightGray: '#e4e4e4',
  red: '#FC5F6E',
  orangeYellowGradient: 'linear-gradient(270deg, #FBAB7E, #F7CE68)',
  darkTeal: '#00a0b5',
  darkGreen: '#42CA7C',
}

export const INVOICE_STATUS_STYLING = {
  Paid: { color: '#42CA7C', fontWeight: 700, border: '1px solid #42CA7C' },
  Outstanding: {
    color: BEAM_COLORS.darkYellow,
    border: `1px solid ${BEAM_COLORS.darkYellow}`,
    fontWeight: 700,
  },
  'Past Due': {
    color: BEAM_COLORS.darkOrange,
    fontWeight: 700,
    border: `1px solid ${BEAM_COLORS.darkOrange}`,
  },
  Draft: { border: '1px solid #bab7ab', fontWeight: 700, color: '#bab7ab' },
  Scheduled: { border: '1px solid #00a0b5', color: '#00a0b5', fontWeight: 700 },
}

// eslint-disable-next-line no-undef
export const IN_KIND_PARTNERS = new Set([8, 64, 800])

// eslint-disable-next-line no-undef
export const conversionRates = { 8: 0.12 }

export const CHAINS_WITH_FILTER_BY_STORE = {
  800: true,
  64: true,
}

export const CHART_COLORS = [
  '#F5CCE8', // Overflow color - Pink Lace
  '#786F52', // Overflow color - Gold Fusion
  '#FEB95F', // Overflow color - Yellow Orange
  '#9000B3', // Overflow color - Violet
  '#88A09E', // Overflow color - Morning Blue
  '#595457', // Overflow color - Dark Liver
  '#9900FF', // Overflow color - Vivid Violet
  '#EDF4ED', // Overflow color - Mint Cream
  '#FF83F7', // Overflow color - Magenta
  '#00E2FF', // Overflow color - Light Teal
  '#FC5F6E', // Overflow color - red
  '#FF7AB6', // Overflow color - Pink
  '#B9D8F1', // Beam Blue 3
  '#FDF5B0', // Beam Yellow 3
  '#BDF2D4', // Beam Green 3
  '#FCCDAD', // Beam Orange 3
  '#72ACDD', // Beam Blue 2
  '#FBEA60', // Beam Yellow 2
  '#7BDFA6', // Beam Green 2
  '#F79957', // Beam Orange 2
  '#92C1CC', // Beam Blue
  '#FCD55F', // Beam Yellow
  '#B8CFA3', // Beam Green
  '#F7961D', // Beam Orange
  '#EFBDEB', // Pink Lavender
  '#314CB6', // Violet blue
  '#6461A0', // Dark blue grey
  '#9AE19D', // Granny Smith Apple
  '#537A5A', // Amazon
  '#587B7F', // Steal Teal
]

export const BEAM_TABLE_HEADER_WIDTH = 350
