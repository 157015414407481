import '../../../style/partner/invoices.css'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useBeamSelector } from '../../../hooks'
import { markInvoicePaid as updateInvoiceThunk } from '../../../redux/thunks/invoiceThunks'
import { BeamButton as BeamButtonV2 } from '../../../stories/BeamButton'
import { dateFileFormat, debounce, dollarFormat } from '../../../utils/root'
import BeamButton from '../../root/BeamButton'
import BeamDropdown from '../../root/BeamDropdown'
import { BeamFuzzySearch } from '../../root/BeamFuzzySearch'
import BeamTable from '../../root/BeamTable'
import { BEAM_COLORS, INVOICE_STATUS_STYLING } from '../../root/constants'
import { MarkLineItemPaidConfirmationModal } from './MarkLineItemPaidConfirmationModal'

const TABLE_PADDING_VERTICAL = '49px'

const TABLE_PADDING_HORIZONTAL = '32px'
const TABLE_MARGIN_TOP = '30px'
const TABLE_BODY_WIDTH = '100.2%'

const TABLE_HEADERS = [
  {
    field: 'partner',
    headerName: 'Partner',
    dataType: 'string',
  },
  { field: 'payTo', headerName: 'Pay To', dataType: 'string' },
  { field: 'date', headerName: 'Issue Date', dataType: 'number' },
  { field: 'status', headerName: 'Status', dataType: 'string' },
  { field: 'amount', headerName: 'Amount', dataType: 'number' },
]

// FIXME: Remove all `any` once we have typedefs
const AdminInvoiceTable = () => {
  const [selectedStatus, setSelectedStatus] = useState('all')
  const statusOptions = [
    { display: 'All Invoices', value: 'all', invoiceStatus: null },
    {
      display: 'Open Invoices',
      value: 'open',
      invoiceStatus: ['Outstanding', 'Past Due', 'Draft'],
    },
    { display: 'Paid', value: 'paid', invoiceStatus: ['Paid'] },
    { display: 'Outstanding', value: 'outstanding', invoiceStatus: ['Outstanding'] },
    { display: 'Past Due', value: 'past due', invoiceStatus: ['Past Due'] },
    { display: 'Draft', value: 'draft', invoiceStatus: ['Draft'] },
    { display: 'Scheduled', value: 'scheduled', invoiceStatus: ['Scheduled'] },
  ]
  const [selectedPartner, setSelectedPartner] = useState('')

  const today = new Date().getTime()

  const loadingStates = useBeamSelector(({ loadingStates }) => loadingStates) as any
  const invoices = useBeamSelector(({ invoices }) => invoices) as any
  const dispatch = useDispatch()

  const [selectedLineItemToMarkAsPaid, setSelectedLineItemToMarkAsPaid] =
    useState<Record<string, any> | null>(null)

  const markPaid = (invoiceId: number) => {
    dispatch(updateInvoiceThunk(invoiceId))
  }

  const getInvoiceStatus = (invoice: any) => {
    if (invoice.scheduled) return 'Scheduled'
    if (invoice.isPaid) return 'Paid'
    if (!invoice.paymentRequested) return 'Draft'

    const pastDueDate = new Date(invoice.dueDate)

    if (today > pastDueDate.getTime()) {
      return 'Past Due'
    }

    return 'Outstanding'
  }

  const invoiceAmount = (invoice: any) => {
    if (!invoice.lineItems) return 0
    return invoice.lineItems.reduce(
      (sum: number, item: any) => (item.payable ? sum + item.amount : sum),
      0
    )
  }

  const selectStatus = (status: string) => {
    setSelectedStatus(status)
  }

  const invoiceCategory = (invoice: any) => {
    return invoice.nonprofit ? 'Nonprofits' : 'Beam'
  }

  const filterByStatus = (selectedStatus: string, invoice: any) => {
    const invoiceStatus = getInvoiceStatus(invoice)
    const selectedStatusObj = statusOptions.filter(op => op.value === selectedStatus)[0]

    if (!selectedStatusObj) return false
    if (selectedStatusObj && !selectedStatusObj.invoiceStatus) return true

    return selectedStatusObj.invoiceStatus.indexOf(invoiceStatus) !== -1
  }

  const filterByPartner = (selectedPartner: any, invoice: any) => {
    if (!selectedPartner || !invoice.partner || !invoice.partner.name) return true

    const partnerSearchTerm = selectedPartner.toLocaleLowerCase()
    const partnerName = invoice.partner && invoice.partner.name.toLowerCase()

    return (
      partnerSearchTerm === partnerName ||
      partnerSearchTerm.indexOf(partnerName) > -1 ||
      partnerName.indexOf(partnerSearchTerm) > -1
    )
  }

  const displayRows = (invoices: any) => {
    return invoices
      .filter((invoice: any) => {
        if (!filterByStatus(selectedStatus, invoice)) return false
        if (!filterByPartner(selectedPartner, invoice)) return false
        return true
      })
      .map((invoice: any) => ({
        id: invoice.id,
        partner: invoice.partner && invoice.partner.name,
        payTo: invoiceCategory(invoice),
        date: {
          value: new Date(invoice.date).toLocaleDateString(),
          sortOn: new Date(invoice.date).getTime(),
        },
        status: {
          value: (
            <div
              className="invoice-status-cell"
              style={INVOICE_STATUS_STYLING[getInvoiceStatus(invoice)]}>
              {getInvoiceStatus(invoice)}
            </div>
          ),
          sortOn: getInvoiceStatus(invoice),
        },
        amount: {
          value: invoice.scheduled ? 'NA' : dollarFormat(invoiceAmount(invoice)),
          sortOn: invoiceAmount(invoice),
        },
        expandedRow: (
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle1">
                  <a target="_blank" rel="noreferrer" href={invoice.beamInvoiceLink}>
                    Invoice No. {invoice.id}
                  </a>
                </Typography>
                {invoice.nonprofit && (
                  <Typography
                    variant="subtitle1"
                    style={{ color: BEAM_COLORS.darkGray, marginBottom: '20px' }}>
                    {invoice.description}
                  </Typography>
                )}
              </div>
              <Typography>
                <a
                  href="https://docs.google.com/document/d/1WN8NfK_CVqtgmXgqOhIR6H8QVzN9wPFPou6AEgv_gV4/edit?usp=sharing"
                  target="_blank"
                  rel="noreferrer">
                  {!invoice.nonprofit && invoice.partner?.paymentStructure?.name}
                </a>
              </Typography>
            </div>
            <Typography variant="h6" gutterBottom component="div">
              {invoice.scheduled ? (
                <div style={{ margin: '20px 0px' }}>{invoice.description}</div>
              ) : (
                'Line Items'
              )}
            </Typography>
            {!invoice.scheduled && (
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Description</TableCell>
                    {invoice.id.indexOf('-N') > -1 && <TableCell>Status</TableCell>}
                    {invoice.id.indexOf('-N') > -1 && <TableCell>Error</TableCell>}
                    <TableCell>Amount</TableCell>
                    <TableCell>Mark Paid</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoice.lineItems
                    .sort((a: any, b: any) => {
                      const np1 = a.nonprofit
                      const np2 = b.nonprofit
                      if (!np1 || !np2) return 0
                      const npl1 = np1.toLowerCase()
                      const npl2 = np2.toLowerCase()
                      if (npl1 < npl2) return -1
                      if (npl1 > npl2) return 1
                      return 0
                    })
                    .map((item: any, i: number) => (
                      <TableRow key={`${invoice.id}-line-item-${i}`}>
                        <TableCell component="th" scope="row">
                          {new Date(item.date).toLocaleDateString()}
                        </TableCell>
                        <TableCell>
                          {item.nonprofit ? (
                            item.payable ? (
                              item.nonprofit
                            ) : (
                              <span style={{ color: '#FC5F6E' }}>{item.nonprofit}</span>
                            )
                          ) : (
                            item.description
                          )}
                        </TableCell>
                        {invoice.id.indexOf('-N') > -1 && (
                          <TableCell>
                            {item.isPaid ? (
                              <span style={{ color: BEAM_COLORS.green }}>
                                <CheckCircleOutlineIcon />
                              </span>
                            ) : item.error ? (
                              <span style={{ color: BEAM_COLORS.red }}>
                                <ErrorOutlineIcon />
                              </span>
                            ) : (
                              'Not Paid'
                            )}
                          </TableCell>
                        )}
                        {invoice.id.indexOf('-N') > -1 && (
                          <TableCell>{item.error || 'no error'}</TableCell>
                        )}
                        <TableCell>{dollarFormat(item.amount, 2)}</TableCell>
                        <TableCell>
                          <div className={'w-min'}>
                            <BeamButtonV2
                              label={'Mark Paid'}
                              variant={'mini'}
                              disabled={!!item.isPaid}
                              className={'min-w-[110px]'}
                              onClick={() => {
                                setSelectedLineItemToMarkAsPaid(item)
                              }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  <TableRow key={`${invoice.id}-total`}>
                    <TableCell>
                      <b>Total</b>
                    </TableCell>
                    <TableCell />
                    {invoice.id.indexOf('-N') > -1 && <TableCell />}
                    {invoice.id.indexOf('-N') > -1 && <TableCell />}
                    <TableCell>
                      <b>{dollarFormat(invoiceAmount(invoice), 2)}</b>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}
            {!invoice.scheduled && (
              <div
                style={{
                  margin: '1em 0em',
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-around',
                }}>
                <BeamButton
                  handler={() => markPaid(invoice.id)}
                  disabled={invoice.isPaid}
                  text={invoice.isPaid ? 'Paid' : 'Mark Paid'}
                  style={{
                    backgroundColor: BEAM_COLORS.orange,
                    height: '35px',
                    width: '110px',
                  }}
                />
              </div>
            )}
          </div>
        ),
      }))
  }

  const invoiceCsvRows = () => {
    const rows = []
    for (const invoice of invoices) {
      for (const lineItem of invoice.lineItems) {
        const row = {
          ...invoice,
          invoiceId: invoice.id,
          lineItemId: lineItem.id,
          ...lineItem,
        }
        delete row.lineItems
        delete row.id
        rows.push(row)
      }
    }
    return rows
  }

  const csvRows = useMemo(invoiceCsvRows, [invoices])
  const rows = displayRows(invoices)

  return (
    <>
      <MarkLineItemPaidConfirmationModal
        open={!!setSelectedLineItemToMarkAsPaid}
        lineItem={selectedLineItemToMarkAsPaid}
        unselectLineItem={() => setSelectedLineItemToMarkAsPaid(null)}
      />

      <div
        style={{
          boxSizing: 'border-box',
          width: TABLE_BODY_WIDTH,
          overflowX: 'auto',
        }}>
        <div style={{ display: 'flex' }}>
          <div
            onClick={() => selectStatus('open')}
            className="invoice-status-select"
            id={`${selectedStatus === 'open' ? 'selected-status' : ''}`}>
            Open Invoices
          </div>
          <div
            onClick={() => selectStatus('all')}
            className="invoice-status-select"
            id={`${selectedStatus === 'all' ? 'selected-status' : ''}`}>
            All Invoices
          </div>
          <div />
        </div>
        <div
          style={{
            minWidth: '991px',
            backgroundColor: '#FFF',
            borderRadius: '8px',
            boxShadow: '10px 5px 32px #e3e6ec',
            padding: `${TABLE_PADDING_VERTICAL} ${TABLE_PADDING_HORIZONTAL}`,
            marginTop: TABLE_MARGIN_TOP,
            boxSizing: 'border-box',
          }}>
          <div className="filter-container">
            <div className="table-label">
              {selectedStatus === 'all' ? 'All Invoices' : 'Open Invoices'}
            </div>

            <div className="invoice-filter-dropdowns">
              <BeamFuzzySearch
                handler={val => debounce(setSelectedPartner(val))}
                inputValue={selectedPartner}
                placeholder="Filter By Partner"
                style={{ width: '230px', marginRight: '20px' }}
              />
              <BeamDropdown
                changeHandler={(_, value) => setSelectedStatus(value as string)}
                name="selectedStatus"
                options={statusOptions}
                selectedValue={selectedStatus}
                width="230px"
              />
            </div>
          </div>
          <BeamTable
            headers={TABLE_HEADERS}
            rows={rows}
            loading={loadingStates.invoices && loadingStates.invoices.loading}
            defaultSortColumn="payTo"
            defaultSortAscending={true}
            emptyTableMessage="Nothing to display"
            expandable={true}
            pagination={true}
            exportable={true}
            exportData={{ rows: csvRows }}
            exportFileName={`Invoices_${dateFileFormat()}`}
          />
        </div>
      </div>
    </>
  )
}

export default AdminInvoiceTable
