import { createContext } from 'react'

import { PpgfPaymentsModal } from '../PpgfPaymentsModal/PpgfPaymentsModal'
import { DisconnectAutopayModal } from './DisconnectAutopayModal'
import { PaymentDetailsModal } from './PaymentDetailsModal'
import { PaymentDetailsSuccessModal } from './PaymentDetailsSuccessModal'
import { PaymentsEmailsModal } from './PaymentsEmailsModal'
import { PaymentsLandingModal } from './PaymentsLandingModal'

interface PaymentsSetupModalsContextProps {
  activeModal: PaymentsSetupModalsEnums | null
  changeActiveModal: (modal: PaymentsSetupModalsEnums | null) => void
}

export const PaymentsSetupModalsContext = createContext<PaymentsSetupModalsContextProps>({
  activeModal: null,
  changeActiveModal: () => null,
})

export enum PaymentsSetupModalsEnums {
  setupBeamFeesAutoPayLanding = 'setupBeamFeesAutoPayLanding',
  paymentDetails = 'paymentDetails',
  viewPaymentDetails = 'viewPaymentDetails',
  paymentDetailsSuccess = 'paymentDetailsSuccess',
  setupPpgfAutoPay = 'setupPpgfAutoPay',
  collectEmail = 'collectEmail',
  disconnectAutopay = 'disconnectAutopay',
}

interface PaymentsSetupModalsProps {
  activeModal: PaymentsSetupModalsEnums | null
  afterModalCloseHandler: (closedModal: PaymentsSetupModalsEnums | null) => void
  invoiceEmails: { invoiceEmail: string | null; invoiceCcEmails: string[] }
  isPaypalSetup: boolean
  partnerId: number
  profilePageToastHandler?: (errorMessage?: string) => void
}

export const PaymentsSetupModals = ({
  activeModal,
  afterModalCloseHandler,
  invoiceEmails,
  isPaypalSetup = false,
  partnerId,
  profilePageToastHandler,
}: PaymentsSetupModalsProps) => {
  return (
    <>
      <PaymentsLandingModal
        open={activeModal === PaymentsSetupModalsEnums.setupBeamFeesAutoPayLanding}
        afterCloseHandler={afterModalCloseHandler}
      />
      <PaymentDetailsModal
        open={[
          PaymentsSetupModalsEnums.paymentDetails,
          PaymentsSetupModalsEnums.viewPaymentDetails,
        ].includes(activeModal as PaymentsSetupModalsEnums)}
        afterCloseHandler={afterModalCloseHandler}
        readOnlyMode={activeModal === PaymentsSetupModalsEnums.viewPaymentDetails}
      />
      <PaymentDetailsSuccessModal
        open={activeModal === PaymentsSetupModalsEnums.paymentDetailsSuccess}
        afterCloseHandler={afterModalCloseHandler}
      />
      <PaymentsEmailsModal
        open={activeModal === PaymentsSetupModalsEnums.collectEmail}
        afterCloseHandler={afterModalCloseHandler}
        originalInvoiceEmails={invoiceEmails}
        isPaypalSetup={isPaypalSetup}
        partnerId={partnerId}
        profilePageToastHandler={profilePageToastHandler}
      />
      <PpgfPaymentsModal
        open={activeModal === PaymentsSetupModalsEnums.setupPpgfAutoPay}
        afterCloseHandler={afterModalCloseHandler}
      />
      <DisconnectAutopayModal
        open={activeModal === PaymentsSetupModalsEnums.disconnectAutopay}
        afterCloseHandler={afterModalCloseHandler}
        profilePageToastHandler={profilePageToastHandler}
      />
    </>
  )
}
