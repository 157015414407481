import cx from 'classnames'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { API_BASE_PATH_PORTAL } from '../../../api/auth'
import { REDESIGN_ROOT_PATH } from '../../../helpers'
import { BeamButton } from '../../../stories/BeamButton'
import { BeamLogo } from '../../../stories/BeamLogo'
import { BeamTextfield } from '../../../stories/BeamTextfield'
import { BeamInputChangeEvent } from '../../../stories/BeamTextfield/BeamTextfield'
import { axiosRequest } from '../../../utils/axiosRequest'
import { BeamSEO } from '../BeamSEO'
import $$ from './forgot-password-page.module.css'

function sendEmailAxios(email: string) {
  return axiosRequest('post', `${API_BASE_PATH_PORTAL}/users/forgot-password`, {
    email,
    isReskin: true,
  })
}

export const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('')
  const [submitted, setSubmitted] = useState(false)

  const captureValue: BeamInputChangeEvent = e => {
    const target = e.target as HTMLInputElement
    setEmail(target.value)
  }

  const sendEmail = async (event: any) => {
    event.preventDefault()

    setSubmitted(true)

    try {
      await sendEmailAxios(email)
    } catch (error: any) {
      // PPOR-3268 - Do not return any hints of an error from our system.
      // It can lead hackers into mining our system for valid email addresses
      // if we return a response specifying whether the email exists or not.
    }
  }

  return (
    <div className={cx($$.forgotPasswordPage, 'w-full h-full beam-bg--gradient-coral')}>
      <BeamSEO title={`Forgot Password`} />
      <div className="flex items-center justify-center h-screen min-h-full px-4 py-12 sm:px-6 lg:px-8">
        <div
          className={cx(
            $$.forgotPasswordForm,
            'flex flex-col items-center justify-center flex-grow w-full h-full max-w-2xl p-2 space-y-8 text-center desktop:p-28 desktop:h-auto'
          )}>
          <BeamLogo alt="Beam Impact Partner Portal - Forgot Password Form" />
          {submitted ? (
            <p>
              If your email exists, you should receive a password reset link in your inbox. Please
              check your email.
            </p>
          ) : (
            <form onSubmit={sendEmail} className="w-full space-y-6">
              <p>
                Enter your email address. If a user with that address exists, we&apos;ll send you a
                link to reset your password.
              </p>
              <div className="w-full">
                <BeamTextfield name="email" placeholder="Email" onChange={captureValue} autofocus />
              </div>
              <BeamButton label="Submit" variant="elevated" type="submit" />
            </form>
          )}
          <Link to={`${REDESIGN_ROOT_PATH}/login`}>Return to login</Link>
        </div>
      </div>
    </div>
  )
}
