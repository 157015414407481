import { API_BASE_PATH_PORTAL } from '../../../api/auth'
import { axiosRequest } from '../../../utils/axiosRequest'

export async function associateYotpoAccountWithPartner({
  code,
  appKey,
}: {
  code: string | null
  appKey: string | null
}) {
  if (!code || !appKey) {
    throw new Error('Missing required params: code or appKey')
  }

  await axiosRequest('post', `${API_BASE_PATH_PORTAL}/yotpo/submit-auth-code`, {
    code,
    appKey,
  })
}
