import { useState } from 'react'

import { API_BASE_PATH_PORTAL } from '../../api/auth'
import { useBeamSelector } from '../../hooks'
import modalStyles from '../../style/root/beam-modal.module.css'
import { axiosRequest } from '../../utils/axiosRequest'
import BeamButton from '../root/BeamButton'
import { BeamModal } from '../root/BeamModal'
import { BeamProgressBar } from '../root/BeamProgressBar'
import { BEAM_COLORS } from '../root/constants'
import { PageNotFound } from '../root/PageNotFound'
import { WelcomeNote } from '../root/WelcomeNote'

export const PayPalOnboarding = () => {
  const [gettingApprovalLink, setGettingApprovalLink] = useState(false)
  const [approvalLink, setApprovalLink] = useState('')
  const [approvalLinkError, setApprovalLinkError] = useState('')
  const [showModal, setShowModal] = useState(false)

  const user = useBeamSelector(({ user }) => user)

  const getApprovalLink = () => {
    if (gettingApprovalLink) return

    setShowModal(true)
    setGettingApprovalLink(true)

    axiosRequest('post', `${API_BASE_PATH_PORTAL}/invoices/paypal/billing-agreement/token`)
      .then(res => {
        setGettingApprovalLink(false)
        setApprovalLink(res.data)
        window.location.href = res.data
      })
      .catch(error => {
        setApprovalLinkError(error?.response?.data?.error?.details[0].name || JSON.stringify(error))
        setGettingApprovalLink(false)
      })
  }

  if (user && user.type !== 'Executive') return <PageNotFound />

  return (
    <div>
      <WelcomeNote firstName={user.firstName} lastName={user.lastName} />
      <h1>Let’s get you set up</h1>
      <div style={{ maxWidth: '900px' }}>
        <div>
          Welcome! Now you can make donations to all of your partner nonprofits with just two clicks
          in the Beam partner portal.
        </div>
        <br />
        <BeamButton
          text="Set up my connection to PayPal Giving Fund"
          handler={getApprovalLink}
          style={{
            marginTop: '20px',
            marginBottom: '20px',
            width: '350px',
            background: BEAM_COLORS.orangeYellowGradient,
          }}
        />
        <div style={{ fontSize: '0.9em' }}>
          <div>There are just three steps to get set up:</div>
          <ol>
            <li>Create a PayPal account (if you don’t already have one)</li>
            <li>
              Add your preferred payment method for making donations (bank account or credit card)
            </li>
            <li>Accept PayPal’s agreement to authorize donation fulfillment</li>
          </ol>
          <div>
            Donations will be made to PayPal Giving Fund, an IRS-registered 501(c)(3) nonprofit
            organization. PPGF receives the donation and distributes the appropriate amount to each
            of your partner nonprofits. Making donations through PayPal Giving Fund also enables us
            to manage applicable compliance for you. Beam does not have access to your payment
            information.
          </div>
        </div>
      </div>
      <BeamModal
        open={showModal}
        title={approvalLinkError ? 'Error' : 'Setting you up'}
        handleClose={() => setShowModal(false)}>
        <div className={modalStyles['modal-container']}>
          {gettingApprovalLink && <BeamProgressBar />}
          {!approvalLinkError && approvalLink && (
            <div>
              If you are not redirected, please follow{' '}
              <a href={approvalLink} target="_blank" rel="noreferrer">
                this link
              </a>{' '}
              to complete your set up with PayPal
            </div>
          )}
          <div style={{ color: BEAM_COLORS.red }}>{approvalLinkError}</div>
        </div>
      </BeamModal>
    </div>
  )
}
